import type { Currency } from "../types/Currency";
import { currencyMapping, ISOCurrency } from "../types/Currency";
import { isEmpty, isNil } from "lodash-es";

export const toIsoCurrency = (currency?: string): string | undefined => {
  if (isNil(currency) || isEmpty(currency)) {
    return undefined;
  }
  if (currency && currency in ISOCurrency) {
    return currency;
  }

  // Fallback
  return currencyMapping[currency as Currency];
};

export const toIsoCurrencySymbol = (currency?: string): string | undefined => {
  const isoCurrency = toIsoCurrency(currency);
  if (isNil(isoCurrency)) {
    return undefined;
  }
  return ISOCurrency[isoCurrency];
};
