import { useIsRestoring } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import pLimit from "p-limit";
import type { AxiosInstance } from "axios";
import { uniqBy } from "lodash-es";
import type { RxCollection } from "rxdb";
import { useInterval } from "usehooks-ts";
import { useMoreAppClient } from "../context/MoreAppContext";
import { API_URL } from "../constants";
import type { Folder, PublishedForm } from "../types/Folder";
import useAuth from "./useAuth";
import { prefetchDatasource } from "../utils/datasourceUtil";
import type { FormVersion, FormVersionDependency } from "../types/FormVersion";
import { stat, writeFileBlob } from "../utils/fileSystemUtil";
import type { DataSourceMeta } from "../types/Datasource";
import { getConnection } from "../utils/deviceUtil";
import logger from "../utils/logger";
import type { FormVersionEntry } from "../database/formVersionSchema";
import { useAsyncEffect } from "./useAsyncEffect";
import { minutes } from "../utils/timeUtil";
import { useAllFolders } from "./useFolders";
import useLocalSubmissions from "./useLocalSubmissions";
import useDataSourceCollection from "./useDataSourceCollection";
import useFormVersionCollection from "./useFormVersionCollection";
import { noopAsync } from "../utils/noop";
import { Capacitor } from "@capacitor/core";

type Dependency = {
  customerId: number;
} & FormVersionDependency;

const usePrefetchUser = (): void => {
  const client = useMoreAppClient();
  const isRestoring = useIsRestoring();
  const { authorization, customers } = useAuth();
  const { data: folders, isFetching: isFetchingFolders } = useAllFolders();
  const { result: submissions, isFetching } = useLocalSubmissions();
  const dataSourceColl = useDataSourceCollection();
  const formVersionColl = useFormVersionCollection();

  // Prefetching is pretty expensive, we'd like to cache everything, but limit the amount of runs this thing gets
  const [isCooldownPublished, setCooldownPublished] = useState(false);
  const [isCooldownDrafts, setCooldownDrafts] = useState(false);
  useInterval(() => setCooldownPublished(false), isCooldownPublished ? minutes(1) : null);
  useInterval(() => setCooldownDrafts(false), isCooldownDrafts ? minutes(1) : null);

  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  const usedFormVersions = useMemo(() => {
    if (!submissions || !isNative) {
      return [];
    }

    return uniqBy(
      submissions.map((submission) => ({
        customerId: submission.customerId,
        id: submission.formVersionId,
        formId: submission.formId,
      })),
      "id",
    );
  }, [submissions, isNative]);

  useAsyncEffect(
    async () => {
      const isDisabled =
        isCooldownPublished ||
        !isNative ||
        !client ||
        !customers ||
        !authorization?.username ||
        !authorization?.accessToken;
      const isInitializing =
        !dataSourceColl || !formVersionColl || !customers || !client || isFetchingFolders || isRestoring;
      if (isDisabled || isInitializing) {
        return;
      }

      const connection = await getConnection();
      if (connection.type !== "wifi") {
        return;
      }
      setCooldownPublished(true);

      const newFormVersions = await cacheFormVersions();
      // Only cache dependency for published Form Versions
      await cacheDependencies(authorization.username!, newFormVersions);
    },
    noopAsync,
    [isRestoring, isFetchingFolders, authorization?.accessToken, client, dataSourceColl, formVersionColl, customers],
  );

  useAsyncEffect(
    async () => {
      const isDisabled = isCooldownDrafts || !authorization?.accessToken || !isNative;
      const isInitializing = isCooldownDrafts || isFetching || !usedFormVersions || isRestoring;
      if (isInitializing || isDisabled) {
        return;
      }
      setCooldownDrafts(true);

      await cacheFormVersionsById(usedFormVersions);
    },
    noopAsync,
    [usedFormVersions, isFetching, isRestoring],
  );

  const cacheFormVersions = async (): Promise<FormVersionEntry[]> => {
    if (!folders || !formVersionColl) {
      return [];
    }
    const publishedForms = getPublishedForms(folders);
    const publishedFormVersions = publishedForms
      .filter((x) => x?.publishedVersion.formVersion)
      .map((x) => ({
        id: x.publishedVersion.formVersion,
        formId: x.id,
        customerId: x.customerId,
      }));
    return cacheFormVersionsById(publishedFormVersions);
  };

  const cacheDependencies = async (username: string, formVersions: FormVersionEntry[]): Promise<void> => {
    if (!dataSourceColl || !folders || !client) {
      return;
    }
    const deps = formVersions.flatMap((x) =>
      x.formVersion.dependencies
        .map((dep) => ({ ...dep, customerId: x.customerId }))
        .map((i): Dependency => ({ type: i.type, value: i.value, customerId: i.customerId })),
    );
    const uniqueDeps = uniqBy(deps, (i) => i.value);
    const customerDeps = uniqueDeps.reduce((record: Record<string, Dependency[]>, e) => {
      const { customerId } = e;
      record[customerId] = [...(record[customerId] || []), e];
      return record;
    }, {});
    const dataSources = await getChangedDataSources(customerDeps, dataSourceColl, client);
    const images = uniqueDeps.filter((i) => i.type === "RESOURCE");

    const limit = pLimit(3);
    await Promise.allSettled([...dataSources, ...images].map((dep) => limit(() => prefetchDependency(username, dep))));
    await Promise.allSettled(folders.map((folder) => fetchFolderImage(username, folder.customerId, folder)));
  };

  const cacheFormVersionsById = async (
    entries: Omit<FormVersionEntry, "formVersion">[],
  ): Promise<FormVersionEntry[]> => {
    const limit = pLimit(3);
    const results = await Promise.allSettled(
      entries.map(async (entry) =>
        limit(async () => {
          // Use count to avoid fetching the entire FormVersion, which can be huge
          const count = await formVersionColl?.count().where("id").eq(entry.id).exec();
          if (count === 0) {
            const formVersion = await fetchFormVersion(entry.customerId, entry.formId, entry.id);
            return { ...entry, formVersion };
          }
          return undefined;
        }),
      ),
    );
    return results
      .filter((value) => value.status === "fulfilled" && !!value?.value)
      .map((value) => value.status === "fulfilled" && value.value) as FormVersionEntry[];
  };

  const downloadCustomerResource = async (username: string, customerId: number, resourceId: string): Promise<void> => {
    const resourcePath = `${username}/${customerId}/customerResources/${resourceId}`;
    try {
      await stat({ path: resourcePath });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      const { data } = await client!.get(`${API_URL}/customers/${customerId}/resources/${resourceId}/download/direct`, {
        responseType: "blob",
      });
      await writeFileBlob({ path: resourcePath, blob: data });
      URL.revokeObjectURL(data);
    }
  };

  const prefetchDependency = async (username: string, dependency: Dependency): Promise<void> => {
    switch (dependency.type) {
      case "DATASOURCE":
        await prefetchDatasource(username, dependency.customerId, dependency.value, client!, dataSourceColl!);
        break;
      case "RESOURCE":
        await downloadCustomerResource(username, dependency.customerId, dependency.value);
        break;
      case "URL":
      case "SERVICE_ACCOUNT":
        break;
    }
  };

  const fetchFormVersion = async (customerId: number, formId: string, formVersionId: string): Promise<FormVersion> => {
    const response = await client!.get<FormVersion>(
      `/api/v1.0/forms/customer/${customerId}/forms/${formId}/versions/${formVersionId}`,
    );
    const formVersion = response.data;
    await formVersionColl?.upsert({
      id: formVersion.id,
      customerId,
      formId: formVersion.formId,
      formVersion,
    });
    return response.data;
  };

  const fetchFolderImage = async (username: string, customerId: number, folder: Folder): Promise<void> => {
    if (folder.meta.image) {
      downloadCustomerResource(username, customerId, folder.meta.image).catch(() =>
        logger.warn(`Couldn't download folder image ${folder.meta.image}`),
      );
    }
  };
};

const getPublishedForms = (folders: Folder[]): PublishedForm[] =>
  folders
    .flatMap((folder) => folder?.forms.map((form) => ({ ...form, customerId: folder.customerId })))
    .filter((form) => form.status === "ACTIVE")
    .filter((form) => form?.publishedVersion?.formVersion);

const getChangedDataSources = async (
  customerDependencies: Record<string, Dependency[]>,
  dataSourceCollection: RxCollection<DataSourceMeta>,
  client: AxiosInstance,
): Promise<Dependency[]> => {
  const currentDataSources = await dataSourceCollection.find().exec();
  const result = await Promise.all(
    Object.keys(customerDependencies).map(async (customerId) => {
      const dependencies = customerDependencies[customerId].filter((x) => x.type === "DATASOURCE");
      const datasourceIds = dependencies.map((x) => x.value);

      try {
        const { data } = await client.post(`${API_URL}/customers/${customerId}/datasources/versions`, {
          datasourceIds,
        });
        return dependencies.filter((i) => {
          const current = currentDataSources.find((j) => j.id === i.value);
          // Filter not yet existing datasources or datasources that have a different version
          return !current || current.version !== data[i.value];
        });
      } catch (e) {
        logger.warn("Not able to retrieve datasourceVersion", e);
      }

      return [];
    }),
  );
  return result.flatMap((x) => x);
};
export default usePrefetchUser;
