import type { FC } from "react";
import { colors } from "../storybook/colors";
import type { UploadStatus } from "../types/Field";

type UploadProgressBarProps = { percentage: number; status?: UploadStatus };

const baseClasses = "absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg";

const FieldUploadStatus: FC<UploadProgressBarProps> = ({ percentage, status }) => {
  if (status === "failed") {
    return <div className={`${baseClasses} bg-red-700`} />;
  }

  if (status === "uploading") {
    return (
      <div
        data-testid="upload-bar"
        className={baseClasses}
        style={{
          background: `linear-gradient(to right, ${colors.green["700"]} ${percentage}%, ${colors.gray["200"]} ${percentage}% 100%)`,
        }}
      />
    );
  }

  return null;
};

export default FieldUploadStatus;
