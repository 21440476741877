import { useTranslation } from "react-i18next";
import type { FC } from "react";
import { useMemo } from "react";
import uuidv4 from "../../../utils/uuid";
import type { Currency } from "../../../types/Currency";
import { asCurrency } from "../../../utils/datasourceUtil";

export type CataloguePriceListProps = {
  priceIncVat: number;
  priceExVat: number;
  vat: number;
  currency?: Currency;
  precision?: number;
  showVat?: boolean;
};

const CataloguePriceList: FC<CataloguePriceListProps> = ({
  priceIncVat,
  priceExVat,
  vat,
  currency,
  precision,
  showVat,
}) => {
  const { t } = useTranslation();

  return (
    <dl className="mt-1 flex flex-col gap-y-2 rounded border px-2 py-3">
      <PriceListItem term={t("TOTAL_PRICE")} definition={asCurrency(priceIncVat, currency, precision)} />
      {showVat && (
        <>
          <PriceListItem term={t("VAT")} definition={asCurrency(vat, currency, precision)} />
          <PriceListItem term={t("SUBTOTAL")} definition={asCurrency(priceExVat, currency, precision)} />
        </>
      )}
    </dl>
  );
};

type PriceListItemProps = {
  term: string;
  definition?: string;
};
const PriceListItem: FC<PriceListItemProps> = ({ term, definition }) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <div className="grid grid-cols-3 gap-x-2 text-xs font-medium text-gray-700">
      <dt id={id} className="truncate">
        {term}
      </dt>
      <dd aria-labelledby={id} className="col-span-2 truncate text-right">
        {definition}
      </dd>
    </div>
  );
};

export default CataloguePriceList;
