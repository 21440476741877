import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { MoreAppError } from "../context/MoreAppContext";
import { useMoreAppClient } from "../context/MoreAppContext";
import { seconds } from "../utils/timeUtil";
import useAuth from "./useAuth";

export type AuthenticationInfo = {
  permissions: GrantPermission[];
};

export type GrantPermission = {
  customerId: number;
  resourceType: "CUSTOMER" | "FORM" | "FOLDER" | "DEVELOPER" | "SYSTEM" | "NONE";
  permissions: string[];
  resourceId: string;
};

const useAuthenticationInfo = (): UseQueryResult<AuthenticationInfo, MoreAppError> => {
  const client = useMoreAppClient();
  const { authorization } = useAuth();
  return useQuery<AuthenticationInfo, MoreAppError>({
    queryKey: ["auth-info", authorization.userId],
    queryFn: async () => {
      const { data } = await client!.get<AuthenticationInfo>("/api/v1.0/authentication");
      return data;
    },
    enabled: !!authorization.userId,
    staleTime: seconds(5),
  });
};

export default useAuthenticationInfo;
