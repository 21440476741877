import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import type { StoreApi } from "zustand";
import { createStore, useStore } from "zustand";
import type { AbstractForm, FieldProperties, FormVersion, SubForm, WidgetProperties } from "../types/FormVersion";
import type { Field, RememberedField, WidgetResult } from "../types/Field";
import { fieldStateToField, fieldToFieldState, fieldToWidgetResult } from "../types/Field";
import type { FormEngineRunStats } from "../utils/FormEngine";
import { findFormVersions, FormEngine } from "../utils/FormEngine";
import { clone, cloneDeep, first, isEmpty, isNil } from "lodash-es";
import type { FieldState, UniqueFieldId } from "../types/SubmissionState";
import uuidv4 from "../utils/uuid";
import type { RxDatabase, RxDocumentData } from "rxdb";
import type { DBCollections } from "../utils/databaseUtil";
import { getFieldFromFormVersions } from "../utils/formUtil";
import type { SubformEntry } from "../components/widgets/WidgetSubform";
import type { Subscription } from "rxjs";
import { getRememberedFieldId } from "../utils/stringUtil";

export type FormState = {
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[];
  rememberedFields: RememberedField[];
  description: string;
};

export type SubmissionState = {
  formState: FormState;
  deviceId: string;
  submissionId: string;
  formId: string;
  formVersion: FormVersion;
  formVersions: AbstractForm[];
  fieldProperties: FieldProperties;
  formEngine: FormEngine;
  options: SubmissionStoreOptions;
  humanEdited: boolean;
  isSubmitting: boolean;
  userId: string | undefined;
  lastRunStats: FormEngineRunStats | undefined;
  focusedField?: FocusedField;
  db?: RxDatabase<DBCollections>;
};

export type FocusedField = {
  uniqueFieldId: UniqueFieldId;
  entryId?: string;
};

export type SubmissionStoreOptions = {
  readOnly: boolean;
  persist: boolean;
  validate: boolean;
};

export type ValidateFormOptions = { treatPendingUploadsAsInvalid: boolean };

export type SubmissionStateActions = {
  loadForm: (
    formVersion: FormVersion,
    submissionId: string,
    deviceId: string,
    formId: string,
    userId: string | undefined,
    username: string | undefined,
    options: SubmissionStoreOptions,
    initialFormState: FormState,
  ) => void;
  setDb: (db: RxDatabase<DBCollections>) => void;
  setOptions: (options: SubmissionStoreOptions) => void;
  setFormState: (formState: FormState) => void;
  setFocussedField: (focusedField?: FocusedField) => void;
  setHumanEdited: (humanEdited: boolean) => void;
  updateField: (field: FieldState<WidgetProperties, WidgetResult<unknown>>, options?: { persist: boolean }) => void;
  persistFields: (fields: FieldState<WidgetProperties, WidgetResult<unknown>>[]) => Promise<void>;
  deleteFields: (uniqueFieldIds: UniqueFieldId[]) => Promise<void>;
  persistDescription: (description: string) => Promise<void>;
  addEntry: (uniqueFieldId: UniqueFieldId, formVersion?: SubForm, meta?: Record<string, unknown>) => string; // entryId TODO: add specific type
  removeEntry: (entryId: string, uniqueFieldId: UniqueFieldId) => void;
  updateEntry: (entry: SubformEntry<unknown>, uniqueFieldId: UniqueFieldId) => void;
  getFirstVisibleInvalidFieldId: (entryId?: string) => UniqueFieldId | undefined;
  getFieldStates: (uniqueFieldIds: UniqueFieldId[]) => FieldState<WidgetProperties, WidgetResult<unknown>>[];
  hasPendingUploads: () => boolean;
  validateForm: (entryId?: string, options?: ValidateFormOptions) => void;
  rememberFields: (formVersion?: AbstractForm, entryId?: string) => void;
  getFormState: () => FormState; // Used only for testing
  listenForExternalFieldChanges: (db: RxDatabase<DBCollections>) => void;
  stopListeningForExternalFieldChanges: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

// TODO: consider if we want to initialize our Zustand Store with props directly, using https://docs.pmnd.rs/zustand/guides/initialize-state-with-props
//  until then, we pass "dummy" values in props below, to avoid having to add ugly !'s everywhere
const dummyFormVersion: FormVersion = {
  fields: [],
  settings: { interaction: "IMMEDIATE_UPLOAD", saveMode: "ALL", icon: "" },
  rules: [],
  fieldProperties: {},
  id: "",
  formId: "",
  dependencies: [],
  meta: {
    created: new Date(),
    createdBy: "",
    lastUpdated: new Date(),
    lastUpdatedBy: "",
    status: "DRAFT",
  },
  theme: {
    id: "5ee1f55f54e4e995cc284b74",
    name: "Default",
  },
};
let fieldChangesSubscription: Subscription | undefined;

const initialState: SubmissionState = {
  formState: { fields: [], description: "", rememberedFields: [] },
  deviceId: "",
  submissionId: "",
  formId: "",
  userId: "",
  formEngine: new FormEngine("", "", "", dummyFormVersion, "", { validate: true }),
  formVersion: dummyFormVersion,
  formVersions: [],
  fieldProperties: {},
  options: { readOnly: false, persist: true, validate: true },
  humanEdited: false,
  isSubmitting: false,
  focusedField: undefined,
  db: undefined,
  lastRunStats: undefined,
};

export const useSubmissionStore = <T = SubmissionState & SubmissionStateActions,>(
  selector: (state: SubmissionState & SubmissionStateActions) => T = (a) => a as T,
): T => {
  const store = useContext(SubmissionStoreContext);

  if (isNil(store)) {
    throw new Error("useSubmissionStore can only be used within SubmissionStoreProvider");
  }

  return useStore(store, selector);
};

const createSubmissionStore = (): StoreApi<SubmissionState & SubmissionStateActions> =>
  createStore<SubmissionState & SubmissionStateActions>()((set, get) => ({
    ...initialState,
    loadForm: (
      formVersion: FormVersion,
      submissionId: string,
      deviceId: string,
      formId: string,
      userId: string | undefined,
      username: string | undefined,
      options: SubmissionStoreOptions,
      initialFormState: FormState,
    ): void =>
      set(() => {
        const formEngine = new FormEngine(submissionId, formId, deviceId, formVersion, username!, {
          validate: options.validate,
        });
        const formVersions = findFormVersions(formVersion);
        const humanEdited = !isNil(initialFormState.fields.find((field) => field.value.meta.humanEdited));
        const { updatedState: formState, mutatedFields } = formEngine.run(initialFormState, [], {
          validateAll: humanEdited,
          treatPendingUploadsAsInvalid: false,
        });

        if (options.persist) {
          // The initial run can lead to automatic mutations (rules, calculations, etc.) which should be persisted.
          // And in rare cases, loading an existing draft with local (offline) updates, can result in mutations on initial run.
          get().persistFields(mutatedFields.upserted);
        }

        return {
          formState,
          deviceId,
          submissionId,
          formId,
          userId,
          formEngine,
          formVersion,
          formVersions,
          options,
          humanEdited,
          fieldProperties: formVersion.fieldProperties,
        };
      }),
    setDb: (db: RxDatabase<DBCollections>): void =>
      set((state) => {
        state.listenForExternalFieldChanges(db);
        return { db };
      }),
    setOptions: (options: SubmissionStoreOptions): void => set(() => ({ options })),
    setFormState: (formState: FormState): void => set(() => ({ formState })),
    setFocussedField: (focusedField?: FocusedField): void => set(() => ({ focusedField })),
    setHumanEdited: (humanEdited: boolean): void => set(() => ({ humanEdited })),
    addEntry: (
      uniqueFieldId: UniqueFieldId,
      formVersion?: AbstractForm,
      meta: Record<string, unknown> = {},
    ): string => {
      const entryId = uuidv4();
      const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
        { uniqueFieldId, type: "add_entry", entryId, formVersion, meta },
      ]);

      get().db?.unsyncedparentfields.upsert({ id: uniqueFieldId, submissionId: get().submissionId });
      get().setFormState(formState);
      get().persistFields(mutatedFields.upserted);
      get().setHumanEdited(true);

      return entryId;
    },
    removeEntry: (entryId: string, uniqueFieldId: UniqueFieldId): void => {
      const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
        { uniqueFieldId, type: "delete_entry", entryId },
      ]);

      get().setFormState(formState);
      get().persistFields(mutatedFields.upserted);
      get().deleteFields(mutatedFields.deleted);
    },
    updateEntry: (value: SubformEntry<unknown>, uniqueFieldId: UniqueFieldId): void => {
      const { updatedState: formState, mutatedFields } = get().formEngine.run(get().formState, [
        { uniqueFieldId, type: "update_entry", value },
      ]);

      get().setFormState(formState);
      get().persistFields(mutatedFields.upserted);
      get().setHumanEdited(true);
    },
    persistFields: async (fields: FieldState<WidgetProperties, WidgetResult<unknown>>[]): Promise<void> => {
      const { db, options, userId } = get();
      if (!options.persist || isEmpty(fields)) {
        return;
      }
      if (isNil(db)) {
        throw new Error("Database not found, can't persist fields");
      }
      await Promise.all(
        // use `incrementalUpsert` instead of `bulkUpsert` to guarantee order and avoid 409 Conflict errors
        // See: https://rxdb.info/rx-collection.html#incrementalupsert
        fields.map(fieldStateToField).map(async (field) => {
          await db.fields.incrementalUpsert({ ...field, updatedBy: userId });
        }),
      );
    },
    deleteFields: async (uniqueFieldIds: UniqueFieldId[]): Promise<void> => {
      const { db, options } = get();
      if (!options.persist || isEmpty(uniqueFieldIds)) {
        return;
      }
      if (isNil(db)) {
        throw new Error("Database not found, can't delete fields");
      }

      await db.fields.bulkRemove(uniqueFieldIds);
    },
    persistDescription: async (description: string): Promise<void> => {
      const { db, submissionId, options } = get();
      if (!options.persist) {
        return;
      }
      if (isNil(db)) {
        throw new Error("Database not found, can't update submission description");
      }
      const submissionRx = await db.submissions.findOne(submissionId).exec();
      if (isNil(submissionRx)) {
        throw new Error("Submission not found, can't update submission description");
      }
      if (submissionRx.description !== description) {
        await submissionRx.incrementalPatch({ description }); // TODO: make sure this doesn't cause re-renders in 'SubmissionPage'
      }
    },
    updateField: (fieldState, options: { persist: boolean } = { persist: true }): void =>
      set((state) => {
        const { updatedState, mutatedFields, stats } = state.formEngine.run(state.formState, [
          { uniqueFieldId: fieldState.uniqueFieldId, value: fieldState.value, type: "update" },
        ]);

        if (options.persist) {
          state.persistFields(mutatedFields.upserted);
          state.persistDescription(updatedState.description);
        }

        const humanEdited = state.humanEdited || fieldState.value.meta.humanEdited;
        return { formState: updatedState, humanEdited, lastRunStats: stats };
      }),
    getFirstVisibleInvalidFieldId: (entryId?: string): UniqueFieldId | undefined =>
      first(
        get()
          .formState.fields.filter((f) => f.visible && f.value.meta.entryId === entryId && !isNil(f.error))
          .map((x) => x.uniqueFieldId),
      ),
    getFieldStates: (uniqueFieldIds: UniqueFieldId[]): FieldState<WidgetProperties, WidgetResult<unknown>>[] =>
      get().formState.fields.filter((fieldState) => uniqueFieldIds.includes(fieldState.uniqueFieldId)),
    hasPendingUploads: (): boolean =>
      get().formState.fields.some((fieldState) => fieldState.visible && isFieldPendingUpload(fieldState)),
    validateForm: (entryId?: string, options: ValidateFormOptions = { treatPendingUploadsAsInvalid: false }): void => {
      const { updatedState, mutatedFields } = get().formEngine.run(get().formState, [], {
        validateAll: true,
        entryId,
        ...options,
      });
      get().setFormState(updatedState);
      get().persistFields(mutatedFields.upserted);
      get().persistDescription(updatedState.description);
    },
    rememberFields: (formVersion?: AbstractForm, entryId?: string): void =>
      set((state) => {
        const usedFormVersion = formVersion ?? state.formVersion;
        const rememberInputFormFields = usedFormVersion.fields.filter(
          (formField) => formField.properties.remember_input,
        );
        const filledFields = state.formState.fields.filter((f) => f.value.meta.entryId === entryId);
        const rememberedFields: RememberedField[] = filledFields
          .filter((fieldState) => rememberInputFormFields.find((formField) => formField.uid === fieldState.uid))
          .map((rememberedField) => ({
            id: getRememberedFieldId(rememberedField.uid, state.formId),
            formId: state.formId,
            data: clone(rememberedField.value.rawValue), // avoids writing "proxy" objects to storage
            updatedAt: rememberedField.value.updatedAt,
            dataName: rememberedField.value.meta.dataName,
            widget: rememberedField.widget,
            type: rememberedField.value.type,
          }));

        if (isEmpty(rememberedFields)) {
          return state;
        }

        // @ts-expect-error this is what it is called in the database
        state.db?.["remembered-fields"].bulkUpsert(rememberedFields);

        return { formState: { ...state.formState, rememberedFields } };
      }),
    getFormState: (): FormState => get().formState,
    stopListeningForExternalFieldChanges: (): void => {
      fieldChangesSubscription?.unsubscribe();
      fieldChangesSubscription = undefined;
    },
    listenForExternalFieldChanges: (db: RxDatabase<DBCollections>): void => {
      if (!isNil(fieldChangesSubscription)) {
        return; // we only want exactly one subscription
      }

      fieldChangesSubscription = db.fields.$.subscribe(async ({ documentData: field }) => {
        if (
          field.status === "final" ||
          field._deleted ||
          get().submissionId !== field.submissionId ||
          // You may have just duplicated a form, and the db.fields were just written before opening the submission.
          // This might immediately receive db.fields update events (the ones you just inserted) and you should ignore those!
          (field.deviceId === get().deviceId && !isFileWithUploadStatus(field))
        ) {
          return; // avoid running FormEngine for irrelevant updates (big performance win!)
        }

        // new field
        const existingFieldState = get().formState.fields.find((f) => f.uniqueFieldId === field.id);
        if (isNil(existingFieldState)) {
          const formField = getFieldFromFormVersions(get().formVersions, field.formFieldId);
          if (isNil(formField)) {
            return;
          }
          const newFieldState = fieldToFieldState(field, formField.properties);
          get().formState.fields.push(newFieldState);
          return;
        }

        // updated field
        const updateValue = cloneDeep(fieldToWidgetResult(field)); // avoid errors due to this being a proxy object
        const updatedFieldState = { ...existingFieldState, value: updateValue };
        get().updateField(updatedFieldState, { persist: false }); // only update state, to prevent ping-pong persists
        get().setHumanEdited(true);
      });
    },
    setIsSubmitting: (isSubmitting: boolean): void =>
      set(() => ({ isSubmitting, options: { ...get().options, readOnly: isSubmitting } })),
  }));

const isFileWithUploadStatus = (field: RxDocumentData<Field>): boolean =>
  field.type === "file" && !isNil(field.uploadStatus);

const isFieldPendingUpload = (fieldState: FieldState<WidgetProperties, WidgetResult<any>>): boolean =>
  !isNil(fieldState.value.meta.uploadStatus) && fieldState.value.meta.uploadStatus !== "uploaded";

const SubmissionStoreContext = createContext<ReturnType<typeof createSubmissionStore> | null>(null);

export const SubmissionStoreProvider: FC<PropsWithChildren<object>> = ({ children }): JSX.Element => {
  const [submissionStore] = useState(createSubmissionStore);
  const stopListeningForExternalFieldChanges = useStore(
    submissionStore,
    (store) => store.stopListeningForExternalFieldChanges,
  );

  useEffect(
    () => (): void => {
      stopListeningForExternalFieldChanges();
    },
    [],
  );

  return <SubmissionStoreContext.Provider value={submissionStore}>{children}</SubmissionStoreContext.Provider>;
};
