import { useRxData } from "rxdb-hooks";
import type { RxDocument } from "rxdb";
import type { RememberedSearchQuery } from "../types/Field";
import useRememberedQueryCollection from "./useRememberedQueryCollection";

type UserLocalRememberedSearchQueryResult = {
  rememberedQuery: RxDocument<RememberedSearchQuery>[];
  isFetchingRememberedQuery: boolean;
  upsertRememberedQuery: (query: string | null | undefined) => Promise<void>;
};

const useLocalRememberedSearchQuery = (formFieldId: string, formId: string): UserLocalRememberedSearchQueryResult => {
  const rxData = useRxData<RememberedSearchQuery>("remembered-search-query", (collection) =>
    collection.find().where("id").eq(formFieldId).where("formId").eq(formId),
  );

  const rememberedQueryCollection = useRememberedQueryCollection();

  const upsertRememberedQuery = async (query: string | null | undefined): Promise<void> => {
    await rememberedQueryCollection?.upsert({ id: formFieldId, formId, query: query ?? "" });
  };

  return {
    rememberedQuery: rxData.result,
    isFetchingRememberedQuery: rxData.isFetching,
    upsertRememberedQuery,
  };
};

export default useLocalRememberedSearchQuery;
