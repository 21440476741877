import type { FC } from "react";
import { useMemo } from "react";
import uuidv4 from "../../utils/uuid";
import type { SearchSubmissionPayload } from "./SearchSubmissionList";
import SearchSubmissionList from "./SearchSubmissionList";
import { nowToISO } from "../../utils/dateUtil";

type RecentSubmissionsProps = {
  customerId: number;
  formVersionId: string;
  formId: string;
  onClick: (submissionId: string) => void;
};

const RecentSubmissions: FC<RecentSubmissionsProps> = ({ formId, formVersionId, customerId, onClick }) => {
  const searchSubmissionPayload: SearchSubmissionPayload = useMemo(
    () => ({
      id: uuidv4(),
      customerId,
      formId,
      formVersionId,
      meta: {},
      createdAt: nowToISO(),
      updatedAt: nowToISO(),
      fields: [],
    }),
    [customerId, formId, formVersionId],
  );
  return <SearchSubmissionList searchSubmissionPayload={searchSubmissionPayload} onClick={onClick} onlyUser />;
};

export default RecentSubmissions;
