import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import type { ModalButton } from "../storybook/components/Modal/Modal";
import { Modal } from "../storybook/components/Modal/Modal";

type InsufficientPermissionsModalProps = {
  show: boolean;
  onClose: () => void;
};

const InsufficientPermissionsModal: FC<InsufficientPermissionsModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("INSUFFICIENT_PERMISSIONS_TITLE")}
      content={{
        kind: "message",
        message: t("INSUFFICIENT_PERMISSIONS_MESSAGE"),
      }}
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: t("CLOSE"),
          onClick: onClose,
        },
        ...(Capacitor.isNativePlatform()
          ? [
              {
                label: t("INSUFFICIENT_PERMISSIONS_SETTINGS_BUTTON"),
                variant: "primary",
                onClick: async () => {
                  onClose();
                  return NativeSettings.open({
                    optionAndroid: AndroidSettings.ApplicationDetails,
                    optionIOS: IOSSettings.App,
                  });
                },
              } as ModalButton,
            ]
          : []),
      ]}
    />
  );
};

export default InsufficientPermissionsModal;
