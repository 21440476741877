import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import type { RemoteSubmission } from "../types/Submission";
import type { RemoteField } from "../types/Field";

const useSubmission = (
  customerId: number | undefined,
  submissionId: string | undefined,
): UseQueryResult<
  RemoteSubmission & {
    fields: RemoteField[];
  },
  MoreAppError
> => {
  const client = useMoreAppClient();
  return useQuery<RemoteSubmission & { fields: RemoteField[] }, MoreAppError>({
    queryKey: ["submission", submissionId],
    queryFn: async () => {
      const { data } = await client!.get<RemoteSubmission & { fields: RemoteField[] }>(
        `/api/v2/customers/${customerId}/submissions/${submissionId}`,
      );
      return data;
    },
    enabled: !!customerId && !!submissionId,
    staleTime: Infinity,
  });
};

export default useSubmission;
