import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMoreAppClient } from "../context/MoreAppContext";
import { seconds } from "../utils/timeUtil";
import useAuth from "./useAuth";
import { useEffect } from "react";

export type Customer = {
  customerId: number;
  name: string;
};

const useCustomers = (): UseQueryResult<Customer[], Error> => {
  const { authorization, setCustomers } = useAuth();
  const client = useMoreAppClient();
  const query = useQuery<Customer[], Error, Customer[]>({
    queryKey: ["customers"],
    queryFn: async () => {
      const { data } = await client!.get("/api/v1.0/customers?filter=user");
      return data;
    },

    staleTime: seconds(15),
    enabled: !!authorization?.accessToken,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (query.isSuccess) {
      setCustomers(query.data);
    }
  }, [query.isSuccess]);

  return query;
};

export default useCustomers;
