import { useCallback, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LicensePlate from "license-plate";
import { isEmpty, isNil } from "lodash-es";
import type { Widget } from "../../types/Widget";
import DrawerListItem from "../DrawerListItem";
import type { WidgetResult } from "../../types/Field";
import { LICENSE_PLATE_BASE_URL } from "../../constants";
import { nowToISO, toHumanReadableDate } from "../../utils/dateUtil";
import useDrawer from "../../hooks/useDrawer";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import WidgetContainer from "../WidgetContainer";
import useSyncedState from "../../hooks/useSyncedState";

export type WidgetRDWProperties = {
  required: boolean;
  label_text: string;
};

export type RDWResult = {
  licensePlate: string;
  brand?: string;
  type?: string;
  expirationDate?: string;
  firstInternationalAdmission?: string;
  euroClassification?: string;
  verifiedAt?: string;
};

const WidgetRDW: Widget<WidgetRDWProperties, WidgetResult<RDWResult>> = ({ fieldState, setFieldState, readOnly }) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const [localStateObj, setLocalState] = useSyncedState(rawValue);
  const localState = localStateObj?.licensePlate.toUpperCase();
  const [warning, setWarning] = useState({ enabled: false, title: "", message: "" });
  const [drawerOpen, setDrawerOpen] = useDrawer(fieldState.uid);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getIconState = (): "loading" | "warning" | "default" => {
    if (isLoading) {
      return "loading";
    }
    if (warning.enabled) {
      return "warning";
    }
    return "default";
  };

  const verifyRDW = useCallback(
    async (licensePlate: string) => {
      setIsLoading(true);
      try {
        const { data: licenseData } = await axios.get<RDWResult>(
          `${LICENSE_PLATE_BASE_URL}/NL/${licensePlate.replaceAll("-", "")}`,
        );

        if (!licenseData) {
          setWarning({ enabled: true, title: t("ERROR"), message: t("VALIDATION_RDW_NOT_FOUND") });
          setIsLoading(false);
          setShowSuccess(false);
          return;
        }

        setWarning({ ...warning, enabled: false });
        setFieldState({ ...licenseData, licensePlate, verifiedAt: nowToISO() });
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 2000);
        setIsLoading(false);
      } catch (e) {
        const notFound = axios.isAxiosError(e) && e.response?.data.code === "not_found";
        const message = notFound ? t("VALIDATION_RDW_NOT_FOUND") : t("VERIFICATION_RDW_UNAVAILABLE");

        setWarning({ enabled: true, title: t("ERROR"), message });
        setShowSuccess(false);
        setIsLoading(false);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const reset = (): void => {
    setLocalState(undefined);
    setFieldState(undefined);
    setWarning({ ...warning, enabled: false });
    setIsLoading(false);
  };

  const updateValue = async (value: string): Promise<void> => {
    if (isEmpty(value)) {
      // Do the same as the 'clear' button when the user removes all typed input
      reset();
    } else {
      setLocalState({ ...localStateObj, licensePlate: value });
      const licensePlate = new LicensePlate(value.toUpperCase());
      if (licensePlate.valid()) {
        setFieldState({ licensePlate: licensePlate.pretty() });
        await verifyRDW(licensePlate.pretty());
      } else {
        setFieldState({ licensePlate: value });
      }
    }
  };

  const showInfoBtn = !warning.enabled && !isLoading && new LicensePlate(rawValue?.licensePlate ?? "").valid();

  const drawer = rawValue && (
    <Drawer
      open={drawerOpen}
      header={{
        kind: "simple",
        title: t("DATA"),
        button: {
          kind: "icon",
          icon: "XIcon",
          onClick: () => setDrawerOpen(false),
        },
      }}
      onClose={() => setDrawerOpen(false)}
    >
      <DrawerListItem key="licensePlate" label={t("LICENSE_PLATE")} value={rawValue.licensePlate ?? t("UNKNOWN")} />
      <DrawerListItem key="brand" label={t("BRAND")} value={rawValue.brand ?? t("UNKNOWN")} />
      <DrawerListItem key="type" label={t("VEHICLE_TYPE")} value={rawValue.type ?? t("UNKNOWN")} />
      <DrawerListItem
        key="expirationDate"
        label={t("APK_EXPIRATION_DATE")}
        value={rawValue.expirationDate ? toHumanReadableDate(rawValue.expirationDate) : t("UNKNOWN")}
      />
      <DrawerListItem
        key="firstInternationalAdmission"
        label={t("FIRST_INTERNATIONAL_ADMISSION")}
        value={
          rawValue.firstInternationalAdmission
            ? toHumanReadableDate(rawValue.firstInternationalAdmission)
            : t("UNKNOWN")
        }
      />
      <DrawerListItem
        key="euroClassification"
        label={t("EURO_CLASSIFICATION")}
        value={rawValue.euroClassification ?? t("UNKNOWN")}
      />
    </Drawer>
  );

  return (
    <WidgetContainer fieldState={fieldState} name="LICENSE_PLATE_FIELD">
      <TextInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        disabled={readOnly}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        placeholder={t("RDW_PLACEHOLDER_TEXT")}
        showClearBtn={!!localState && !readOnly}
        clearLabel={t("CLEAR")}
        onClear={reset}
        description={
          !isNil(rawValue?.brand) ? t("RDW_BRAND_AND_MODEL", { brand: rawValue.brand, model: rawValue.type }) : ""
        }
        onChange={(e) => updateValue(e.target.value)}
        leftIcon={{
          name: showSuccess ? "CheckIcon" : "CarIcon",
          className: `${showSuccess && "text-green-500"} transition`,
        }}
        iconState={getIconState()}
        warningTitle={warning.enabled && !fieldState.error ? warning.title : ""}
        warningMessage={warning.enabled && !fieldState.error ? warning.message : ""}
        rightBtn={
          showInfoBtn
            ? {
                "aria-label": t("RDW_VIEW_INFO"),
                icon: "InformationCircleIcon",
                size: "md",
                onClick: () => setDrawerOpen(true),
              }
            : undefined
        }
      />
      {drawer}
    </WidgetContainer>
  );
};

WidgetRDW.defaultValue = (_properties, defaultMeta): WidgetResult<RDWResult> => ({
  type: "object",
  meta: {
    widget: "rdw",
    ...defaultMeta,
  },
});

WidgetRDW.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val && !new LicensePlate(val.licensePlate).valid()) {
    return t("VALIDATION_RDW_INVALID");
  }

  return undefined;
};

export default WidgetRDW;
