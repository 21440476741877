import type { FC } from "react";
import { useEffect, useState } from "react";
import { Link, useOutlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router";
import classNames from "classnames";
import Content from "../../components/Content";
import usePush from "../../hooks/usePush";
import useAuth from "../../hooks/useAuth";
import { getToBeMigrated } from "../../database/migration/00_legacy-app-to-current/migrationUtil";
import type Migration from "../../types/Migration";
import type { NavigationPageOutletContext } from "../NavigationPage";
import Page from "../../components/Page";
import useDeviceOrientation from "../../hooks/useDeviceOrientation";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { noopAsync } from "../../utils/noop";
import { Text } from "../../storybook/components/Text/Text";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Icon } from "../../storybook/components/Icon/Icon";
import { Modal } from "../../storybook/components/Modal/Modal";
import useMigration from "../../hooks/useMigration";
import type { User } from "../../hooks/useUser";
import useUser from "../../hooks/useUser";
import InlineLoader from "../../components/InlineLoader";

const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout, username, customerName } = useAuth();
  const user = useUser();
  const { deregisterPush } = usePush();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [openMigrations, setOpenMigrations] = useState<Migration[]>([]);
  const { setShowWorkspaceHeader, setShowTabBar } = useOutletContext<NavigationPageOutletContext>();
  const outlet = useOutlet();
  const { landscapeIndent } = useDeviceOrientation(true);
  const { shouldMigrate } = useMigration();

  useEffect(() => {
    setShowTabBar(false);
    setShowWorkspaceHeader(false);
  }, [setShowTabBar, setShowWorkspaceHeader]);

  useAsyncEffect(
    async () => {
      if (shouldMigrate && username) {
        setOpenMigrations(await getToBeMigrated(username));
      }
    },
    noopAsync,
    [username],
  );

  const doLogout = async (): Promise<void> => {
    await deregisterPush();
    await logout();
  };

  const linkClasses = (marginSide: "top" | "bottom"): string =>
    classNames("block select-none outline-none focus-visible:ring", {
      "mt-3.5": marginSide === "top",
      "mb-3.5": marginSide === "bottom",
    });

  return (
    <div className="flex size-full lg:divide-x">
      <Page
        className={classNames("flex-1 flex-col overflow-y-auto pt-safe", outlet ? "hidden lg:flex" : "flex w-full")}
      >
        <div className="relative mb-5 mt-2 text-center">
          <div className="absolute left-2 top-0">
            <IconButton
              className="lg:hidden"
              variant="transparentMedium"
              iconType="outline"
              size="md"
              icon="ChevronLeftIcon"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <Icon name="UserCircleIcon" className="text-branding mx-auto mb-2 size-16" />
          <Text weight="semibold" color="dark" data-test-id="settings-username" className="mb-1">
            <InlineLoader obj={user} format={getFullName} opts={{ width: "w-1/4" }} />
          </Text>
          <Text size="sm" weight="medium" color="medium" data-test-id="settings-customer-name">
            {customerName}
          </Text>
        </div>
        <Content className={`text-sm font-semibold text-gray-700 ${landscapeIndent}`}>
          <hr className="border-t" />
          <Link to="/settings/profile" className={linkClasses("top")}>
            <div className="flex justify-between py-3">
              <div className="flex items-center">
                <Icon name="UserCircleIcon" type="outline" className="mr-7" />
                {t("SETTINGS_PROFILE")}
              </div>
              <Icon type="outline" name="ChevronRightIcon" />
            </div>
          </Link>
          <Link to="/settings/password" className={linkClasses("bottom")}>
            <div className="flex justify-between py-3">
              <div className="flex items-center">
                <Icon name="LockClosedIcon" type="outline" className="mr-7" />
                {t("SETTINGS_PASSWORD")}
              </div>
              <Icon type="outline" name="ChevronRightIcon" />
            </div>
          </Link>
          {openMigrations.length > 0 && (
            <Link to="/settings/migration" className={linkClasses("bottom")}>
              <div className="flex justify-between py-3">
                <div className="flex items-center">
                  <Icon name="ArchiveIcon" type="outline" className="mr-7" />
                  {t("SETTINGS_MIGRATION")}
                </div>
                <Icon type="outline" name="ChevronRightIcon" />
              </div>
            </Link>
          )}
          <hr className="border-t" />
          <Link to="/settings/language" className={linkClasses("top")}>
            <div className="flex justify-between py-3">
              <div className="flex items-center">
                <Icon name="TranslateIcon" type="outline" className="mr-7" />
                {t("SETTINGS_LANGUAGE")}
              </div>
              <Icon type="outline" name="ChevronRightIcon" />
            </div>
          </Link>
          <Link to="/settings/support" className={linkClasses("bottom")}>
            <div className="flex justify-between py-3">
              <div className="flex items-center">
                <Icon name="SupportIcon" type="outline" className="mr-7" />
                {t("SETTINGS_SUPPORT")}
              </div>
              <Icon type="outline" name="ChevronRightIcon" />
            </div>
          </Link>
          <hr className="border-t" />
          <button
            onClick={() => setShowLogoutModal(true)}
            className="mt-3.5 w-full select-none outline-none focus-visible:ring"
          >
            <div className="flex justify-between py-3">
              <div className="flex items-center">
                <Icon name="LogoutIcon" type="outline" className="mr-7" />
                <Text color="dark" weight="semibold" size="sm">
                  {t("SETTINGS_LOGOUT")}
                </Text>
              </div>
              <Icon type="outline" name="ChevronRightIcon" />
            </div>
          </button>
          <Modal
            title={t("LOGOUT_MODAL_TITLE")}
            content={{
              kind: "message",
              message: t("LOGOUT_MODAL_DESCRIPTION"),
            }}
            open={showLogoutModal}
            onClose={() => setShowLogoutModal(false)}
            buttons={[
              {
                label: t("CANCEL"),
                onClick: () => setShowLogoutModal(false),
              },
              {
                label: t("LOGOUT_BUTTON"),
                variant: "primary",
                onClick: async () => doLogout(),
              },
            ]}
          />
        </Content>
      </Page>
      {outlet && <div className="flex-1">{outlet}</div>}
    </div>
  );
};

const getFullName = (value: User): string => {
  if (!value.settings.firstName || !value.settings.lastName) {
    return value.username;
  }
  return `${value.settings.firstName} ${value.settings.lastName}`;
};

export default SettingsPage;
