import type { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { Text } from "../Text/Text";
import type { IconName } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";

export type BannerProps = {
  className?: string;
  label: string;
  icon?: IconName;
  type?: "error" | "warning" | "update" | "neutral";
};

export const Banner: FC<PropsWithChildren<BannerProps>> = ({ className, label, icon, type = "error", children }) => {
  const classes = classNames(
    "flex items-center justify-center gap-x-2 px-4 py-1 font-semibold",
    {
      "bg-red-600": type === "error",
      "bg-yellow-500": type === "warning",
      "bg-brand-600": type === "update",
      "bg-gray-200": type === "neutral",
    },
    className,
  );

  const iconClasses = classNames("size-4 shrink-0", type === "neutral" ? "text-gray-700" : "text-white");

  return (
    <div className={classes}>
      {icon && <Icon name={icon} type="solid" className={iconClasses} />}
      <Text color={type === "neutral" ? "dark" : "white"} size="sm" weight="normal" className="truncate">
        {label}
        {children}
      </Text>
    </div>
  );
};
