import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { WidgetResult } from "../../../types/Field";
import type { FileResult } from "../../../types/Widget";
import { Feedback } from "../../../storybook/components/Feedback/Feedback";
import type { WidgetProperties } from "../../../types/FormVersion";
import type { FieldState } from "../../../types/SubmissionState";

const FileFeedback: FC<{ fieldState: FieldState<WidgetProperties, WidgetResult<FileResult>> }> = ({ fieldState }) => {
  const { t } = useTranslation();

  if (fieldState.value.meta.uploadStatus === "failed") {
    return <Feedback className="mt-2" message={t("VALIDATION_UPLOAD_FAILED")} status="error" />;
  }

  if (!fieldState.error) {
    return null;
  }

  return <Feedback className="mt-2" status="error" message={fieldState.error} />;
};

export default FileFeedback;
