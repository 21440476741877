import { Trans, useTranslation } from "react-i18next";
import type { FC } from "react";
import classNames from "classnames";
import { Text } from "../../storybook/components/Text/Text";

const OfflineContent: FC<{ centered?: boolean; className?: string }> = ({ centered = false, className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <img className="mx-auto pb-8" src="assets/errorpages/offline.svg" alt={t("OFFLINE_ALT")} />
      <Text className={classNames("pb-5", { "text-center": centered })} weight="semibold">
        {t("OFFLINE_TITLE")}
      </Text>
      <Text className={classNames("pb-6", { "text-center": centered })}>
        {/* Needed instead of useTranslation in order to use breaklines  */}
        <Trans i18nKey="OFFLINE_DESCRIPTION" />
      </Text>
    </div>
  );
};

export default OfflineContent;
