import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { DATASOURCES_PATH } from "../utils/datasourceUtil";
import useAuth from "./useAuth";
import type { MoreAppError } from "../context/MoreAppContext";
import { readDir } from "../utils/fileSystemUtil";

export const useDatasourceSizeEstimate = (): UseQueryResult<{ total: number }, MoreAppError> => {
  const { customerId, username } = useAuth();
  const path = `${username}/${customerId}/${DATASOURCES_PATH}`;

  return useQuery({
    queryKey: ["dataSourceSize", customerId, username, path],
    queryFn: async () => {
      const fileNames = (await readDir({ path })).files.map((file) => file.name);
      const files = await Promise.all(fileNames.map((file) => readDir({ path: `${path}/${file}` })));

      return {
        total: files.reduce((acc, curr) => acc + curr.files[0].size, 0),
      };
    },
    gcTime: 0,
    enabled: !!customerId && !!username,
  });
};
