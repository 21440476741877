import type { RxJsonSchema } from "rxdb";
import type { Submission } from "../types/Submission";
import type { Field, FieldMeta, RememberedField, RememberedSearchQuery, UnsyncedParentField } from "../types/Field";

export const submissionSchema: RxJsonSchema<Submission> = {
  title: "Submission schema",
  description: "Result of a (partly) filled out form",
  version: 2,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    customerId: { type: "number", minimum: 1, maximum: 999_999_999, multipleOf: 1 },
    formId: { type: "string", maxLength: 36 },
    formVersionId: { type: "string", maxLength: 36 },
    task: { type: ["object", "null"] },
    description: { type: ["string", "null"] },
    status: { type: "string", maxLength: 5 },
    form: { type: "object" },
    meta: { type: "object" },
    createdAt: { type: "string" },
    updatedAt: { type: "string", maxLength: 13 },
    viewedAt: { type: ["string", "null"] },
    submittedAt: { type: ["string", "null"] },
    sendType: { type: "string" },
    type: { type: "string", maxLength: 5 },
  },
  required: ["id", "customerId", "formId", "formVersionId", "status", "updatedAt"],
  indexes: ["updatedAt", "customerId", "type", "status"],
};

export const fieldSchema: RxJsonSchema<Field> = {
  title: "Submission field schema",
  description: "Result of a (partly) filled out field",
  version: 4,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    submissionId: { type: "string", maxLength: 36 },
    data: { type: ["array", "object", "string", "boolean", "number", "null"] },
    deviceId: { type: ["string", "null"] },
    uploadStatus: { type: ["string", "null"] },
    widget: { type: "string" },
    type: { type: "string", maxLength: 36 },
    formFieldId: { type: "string" },
    dataName: { type: "string" },
    updatedAt: { type: "string", maxLength: 13 },
    updatedBy: { type: ["string", "null"] },
    status: { type: "string" },
    entryId: { type: ["string", "null"] },
    parentId: { type: ["string", "null"] },
    error: { type: ["string", "null"] },
    entries: { type: "array" },
    hidden: { type: "boolean" },
    compressed: { type: "boolean" },
    evaluatedRules: { type: ["array"] },
    order: { type: ["number"] },
    _deleted: { type: "boolean" },
  },
  required: ["id", "submissionId", "updatedAt"],
  indexes: ["submissionId", "updatedAt", "type"],
};

export const rememberedFieldSchema: RxJsonSchema<RememberedField> = {
  title: "Remembered field schema",
  description: "Result of a previously filled out field",
  version: 1,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    formId: { type: "string", maxLength: 36 },
    data: { type: ["array", "object", "string", "boolean", "number", "null"] },
    widget: { type: "string" },
    type: { type: "string" },
    dataName: { type: "string" },
    updatedAt: { type: "string", maxLength: 13 },
  },
  required: ["id", "formId"],
  indexes: ["formId"],
};

export const rememberedSearchQuerySchema: RxJsonSchema<RememberedSearchQuery> = {
  title: "Remembered search query schema",
  description: "Query of a previously filled out search",
  version: 0,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    formId: { type: "string", maxLength: 36 },
    query: { type: "string" },
  },
  required: ["id", "formId"],
  indexes: ["formId"],
};

// Legacy: remove later with DEV-6207
export const fieldMetaSchema: RxJsonSchema<FieldMeta> = {
  title: "Field meta schema",
  description: "Contains non-replicated metadata of fields",
  version: 0,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    submissionId: { type: "string", maxLength: 36 },
    remote: { type: "boolean" },
  },
  required: ["id"],
};

export const unsyncedParentFieldSchema: RxJsonSchema<UnsyncedParentField> = {
  title: "Unsynced parent field schema",
  description: "Contains unsynced (non-replicated) parent-field references",
  version: 0,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    submissionId: { type: "string", maxLength: 36 },
  },
  required: ["id", "submissionId"],
};
