import { produce } from "immer";
import { isEmpty, isNil, round } from "lodash-es";
import { deepEqual } from "fast-equals";
import type { UniqueFieldId } from "../types/SubmissionState";
import { evaluateRules } from "./ruleEvaluationUtil";
import type { AbstractForm, FormField, FormVersion } from "../types/FormVersion";
import { highestOrder } from "./formUtil";
import { getSanitizedTemplatedContent } from "./templateUtil";
import { getCalculationResult } from "./calculationUtil";
import { getTimeDifferenceResult, seconds } from "./timeUtil";
import type { RememberedField, WidgetResult } from "../types/Field";
import { fieldStateToField } from "../types/Field";
import { getPlaceholderDataNames, removeWidgetVersionNumber } from "./stringUtil";
import { getValueForType } from "./ruleUtil";
import logger from "./logger";
import { getNestedEntries } from "./submissionUtil";
import type { SubformEntry } from "../components/widgets/WidgetSubform";
import { nowToISO } from "./dateUtil";
import {
  createNewFieldStatesForParent,
  findField,
  getAllCalculationFieldStates,
  getEntryDescriptionPath,
  mapVisibleFieldStatesToDataName,
  mapFieldStatesToEntryId,
  validateParents,
  WIDGET_KEY_CALC,
  WIDGET_KEY_TIME_CALC,
  WIDGET_KEYS_CALC,
  determineNecessaryParentValidation,
  type BaseFieldState,
} from "./formEngineUtil";
import { JSONPath } from "jsonpath-plus";
import type { FormState } from "../context/SubmissionStoreContext";

export type FieldStateMutation =
  | FieldStateMutationUpdate
  | FieldStateMutationAddEntry
  | FieldStateMutationDeleteEntry
  | FieldStateMutationUpdateEntry;

type FieldStateMutationBase = {
  uniqueFieldId: UniqueFieldId;
};

type FieldStateMutationAddEntry = {
  type: "add_entry";
  entryId: string;
  formVersion?: AbstractForm;
  meta: Record<string, unknown>;
} & FieldStateMutationBase;

type FieldStateMutationDeleteEntry = {
  type: "delete_entry";
  entryId: string;
} & FieldStateMutationBase;

type FieldStateMutationUpdateEntry = {
  type: "update_entry";
  value: SubformEntry<unknown>;
} & FieldStateMutationBase;

type FieldStateMutationUpdate = {
  type: "update";
  value: WidgetResult<unknown>;
} & FieldStateMutationBase;

export type FormEngineRunStats = {
  total: number; // ms
  userMutations: number;
  autoMutations: {
    total: number;
    rules: number;
    calc: number;
    timeDiff: number;
    recursions: number;
  };
  descriptions: number;
  validations: number;
};

type FormEngineRunResult = {
  updatedState: FormState;
  mutatedFields: {
    upserted: BaseFieldState[];
    deleted: UniqueFieldId[];
  };
  stats: FormEngineRunStats;
};

export type FormEngineOptions = { validate: boolean };

export type RunOptions = {
  validateAll: boolean;
  treatPendingUploadsAsInvalid: boolean;
  entryId?: string;
};

export const MAX_RECURSION_DEPTH = 100;
export const RECURSION_DEPTH_SEND_ERROR = 10;

export const findFormVersions = (formVersion: AbstractForm): AbstractForm[] =>
  JSONPath({ path: "$..rules^", json: formVersion }); // Assumption: only FormVersions have a `rules` attribute. Could have used `triggers` or `integrations` too!

export class FormEngine {
  private readonly submissionId: string;

  private readonly formId: string;

  private readonly deviceId: string;

  private readonly formVersion: FormVersion;

  private readonly formFields: Map<string, FormField<any>>;

  private readonly username: string;

  private readonly options: FormEngineOptions;

  private readonly placeholderDataNames: string[];

  constructor(
    submissionId: string,
    formId: string,
    deviceId: string,
    formVersion: FormVersion, // For rules and looking up linked subforms
    username: string, // Used in rules...
    options: FormEngineOptions,
  ) {
    this.submissionId = submissionId;
    this.formId = formId;
    this.deviceId = deviceId;
    this.formVersion = formVersion;
    this.username = username;
    this.options = options;
    this.placeholderDataNames = getPlaceholderDataNames(this.formVersion.settings.itemHtml);
    this.formFields = findFormVersions(this.formVersion)
      .flatMap((form) => form.fields)
      .filter((field) => WIDGET_KEYS_CALC.includes(removeWidgetVersionNumber(field.widget)))
      .reduce((acc, field) => acc.set(field.uid, field), new Map<string, FormField<any>>());
  }

  run(
    formState: FormState,
    mutations: FieldStateMutation[],
    options: RunOptions = { validateAll: false, treatPendingUploadsAsInvalid: false },
  ): FormEngineRunResult {
    const stats: FormEngineRunStats = {
      total: 0,
      userMutations: 0,
      autoMutations: { total: 0, rules: 0, calc: 0, timeDiff: 0, recursions: 0 },
      descriptions: 0,
      validations: 0,
    };

    const mutatedFieldIds = new Set<UniqueFieldId>();
    const deletedFieldIds = new Set<UniqueFieldId>();
    const createdFieldIds = new Set<UniqueFieldId>();
    const mutatedEntryIds = new Set<string>();
    const skipValidation = new Set<UniqueFieldId>();
    const writeMutation = (uniqueFieldId: UniqueFieldId): void => {
      mutatedFieldIds.add(uniqueFieldId);
    };

    const isInitialRun = isEmpty(mutations);

    const updatedState = produce(formState, (draft) => {
      // 1) User mutations
      const startUserMutations = performance.now();
      mutations.forEach((mutation) => {
        switch (mutation.type) {
          case "update":
            this.#handleUpdate(mutation, draft.fields).forEach((uniqueFieldId) => writeMutation(uniqueFieldId));
            if (mutation.value.meta.entryId) {
              mutatedEntryIds.add(mutation.value.meta.entryId);
            }
            break;
          case "add_entry":
            this.#handleAddEntry(mutation, draft.fields, draft.rememberedFields).forEach((id, index) => {
              if (index === 0) {
                writeMutation(id); // parent field
              } else {
                createdFieldIds.add(id); // created (deeply) nested fields
              }
            });
            mutatedEntryIds.add(mutation.entryId);
            break;
          case "delete_entry":
            this.#handleDeleteEntry(mutation, draft.fields).forEach((id, index) => {
              if (index === 0) {
                writeMutation(id); // parent field
              } else {
                deletedFieldIds.add(id); // deleted (deeply) nested fields
              }
            });
            break;
          case "update_entry": {
            this.#handleUpdateEntry(mutation, draft.fields).forEach((uniqueFieldId) => writeMutation(uniqueFieldId));
            break;
          }
        }
      });
      const endUserMutations = performance.now();
      stats.userMutations = round(endUserMutations - startUserMutations, 1);

      // 2) Auto-mutations (rules, calculations, time difference)
      const evaluateAutoMutations = (depth = 0): void => {
        let hasChanges = false;

        // 2.1) rules
        const startRules = performance.now();
        evaluateRules(this.submissionId, draft.fields.map(fieldStateToField), this.formVersion, this.username).forEach(
          (evaluatedRule) => {
            const { value, hidden, ruleResults } = evaluatedRule;
            const field = findField(draft.fields, evaluatedRule.uniqueFieldId);
            if (isNil(field)) {
              logger.error("Can't find target field state for rule", null, {
                extra: { submissionId: this.submissionId, uniqueFieldId: evaluatedRule.uniqueFieldId },
              });
              return;
            }

            const valueForType = getValueForType(field.value.type, field.properties, value as object);
            const hasValueChange = !isNil(valueForType);
            field.value.rawValue = hasValueChange ? valueForType : field.value.rawValue; // only update if value has data
            field.visible = !hidden;
            field.value.meta.hidden = !!hidden;
            field.value.meta.evaluatedRules = ruleResults;
            writeMutation(field.uniqueFieldId);
            hasChanges = hasChanges || hasValueChange;

            // if we only change visibility, we don't want to run validation.
            // However, we should write the mutation so the visibility change is persisted
            if (!hasValueChange) {
              skipValidation.add(field.uniqueFieldId);
            }
          },
        );
        const endRules = performance.now();
        stats.autoMutations.rules = round(stats.autoMutations.rules + round(endRules - startRules, 1), 1);

        // 2.2) calculations
        const startCalc = performance.now();

        const calcWidgetFieldStates = getAllCalculationFieldStates(draft.fields);
        calcWidgetFieldStates
          .filter((field) => removeWidgetVersionNumber(field.widget) === WIDGET_KEY_CALC)
          .map((field) => ({
            field,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formField: this.formFields.get(field.uid)!,
          }))
          .map(({ field, formField }) => ({
            field,
            result: getCalculationResult(formField, draft.fields, field.value.meta.entryId),
          }))
          .forEach(({ field, result }) => {
            if (!deepEqual(result, field.value.rawValue)) {
              field.value.rawValue = result;
              writeMutation(field.uniqueFieldId);
              hasChanges = true;
            }
          });
        const endCalc = performance.now();
        stats.autoMutations.calc = round(stats.autoMutations.calc + round(endCalc - startCalc, 1), 1);

        // 2.3) time difference
        const startTimeDiff = performance.now();
        calcWidgetFieldStates
          .filter((field) => removeWidgetVersionNumber(field.widget) === WIDGET_KEY_TIME_CALC)
          .map((field) => ({
            field,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formField: this.formFields.get(field.uid)!,
          }))
          .map(({ field, formField }) => ({
            field,
            result: getTimeDifferenceResult(formField, draft.fields, field.value.meta.entryId),
          }))
          .forEach(({ field, result }) => {
            if (!deepEqual(result, field.value.rawValue)) {
              field.value.rawValue = result;
              writeMutation(field.uniqueFieldId);
              hasChanges = true;
            }
          });
        const endTimeDiff = performance.now();
        stats.autoMutations = {
          ...stats.autoMutations,
          timeDiff: round(stats.autoMutations.timeDiff + round(endTimeDiff - startTimeDiff, 1), 1),
          recursions: depth,
        };

        // Max depth limit to prevent endless loop. In the future, this should be prevented when publishing FormVersion
        if (hasChanges && depth < MAX_RECURSION_DEPTH) {
          if (depth === RECURSION_DEPTH_SEND_ERROR) {
            logger.error(`FormEngine run recursed ${RECURSION_DEPTH_SEND_ERROR} times`, null, {
              extra: { submissionId: this.submissionId },
            });
          }
          evaluateAutoMutations(depth + 1);
        }
      };

      evaluateAutoMutations();
      stats.autoMutations.total = round(
        stats.autoMutations.calc + stats.autoMutations.rules + stats.autoMutations.timeDiff,
        1,
      );

      // 3) Descriptions
      const startDescriptions = performance.now();

      // 3.1) Submission descriptions
      const rootFields = draft.fields.filter((field) => isNil(field.value.meta.entryId));
      const fieldsByDataName = mapVisibleFieldStatesToDataName(rootFields, this.placeholderDataNames);
      draft.description = getSanitizedTemplatedContent(this.formVersion.settings.itemHtml || "", fieldsByDataName);

      // 3.2) Entry descriptions
      const fieldsByEntryId = mapFieldStatesToEntryId(draft.fields);

      draft.fields
        .filter((field) => !isEmpty(field.value.entries))
        // Get parent fields with any mutated entries
        .filter(
          (parentField) => isInitialRun || parentField.value.entries?.some((entry) => mutatedEntryIds.has(entry.id)),
        )
        .forEach((parentField) => {
          const mutatedEntries = isInitialRun
            ? parentField.value.entries
            : parentField.value.entries?.filter((entry) => mutatedEntryIds.has(entry.id));
          mutatedEntries?.forEach((entry) => {
            const path = getEntryDescriptionPath(parentField, entry) ?? "";
            const placeholderDataNames = getPlaceholderDataNames(path);
            const subformFieldsByDataName = mapVisibleFieldStatesToDataName(
              fieldsByEntryId[entry.id] ?? [],
              placeholderDataNames,
            );

            const newDescription = `${entry.meta.order}. ${getSanitizedTemplatedContent(path, subformFieldsByDataName, true)}`;
            if (newDescription !== entry.meta.description) {
              entry.meta.description = newDescription;
              writeMutation(parentField.uniqueFieldId);
            }
          });
        });

      const endDescriptions = performance.now();
      stats.descriptions = round(endDescriptions - startDescriptions, 1);

      // 4) Validation
      const startValidations = performance.now();
      if (this.options.validate) {
        const undeletedFields = draft.fields.filter((field) => !field.deleted);
        const { parentsToValidate, updateErrorState } = determineNecessaryParentValidation(
          undeletedFields,
          options,
          mutatedFieldIds,
          skipValidation,
        );

        updateErrorState.forEach(({ field, error }) => {
          field.error = error;
          writeMutation(field.uniqueFieldId);
        });

        parentsToValidate.forEach((hash) => {
          const [parentId, entryId] = hash.split(":");
          validateParents(parentId as UniqueFieldId, entryId, undeletedFields, writeMutation);
        });
      }
      const endValidations = performance.now();
      stats.validations = round(endValidations - startValidations, 1);
    });

    stats.total = round(stats.userMutations + stats.autoMutations.total + stats.descriptions + stats.validations, 1);
    if (stats.total > seconds(1)) {
      logger.error("FormEngine run took over 1 second", null, {
        extra: { stats, submissionId: this.submissionId, formId: this.formId },
      });
    }

    const upsertedFieldIds = [...createdFieldIds, ...mutatedFieldIds];

    return {
      updatedState: {
        ...updatedState,
        fields: updatedState.fields.filter((f) => !f.deleted), // don't keep deleted fields in state, to avoid memory-leaks of deleted subform entries
      },
      mutatedFields: {
        upserted: updatedState.fields.filter((field) => upsertedFieldIds.includes(field.uniqueFieldId)),
        deleted: [...deletedFieldIds],
      },
      stats,
    };
  }

  #handleUpdate(mutation: FieldStateMutationUpdate, fields: BaseFieldState[]): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      logger.error("Can't find field state to update", null, {
        extra: { submissionId: this.submissionId, uniqueFieldId: mutation.uniqueFieldId },
      });
      return [];
    }
    field.deviceId = this.deviceId;
    field.value = mutation.value;
    if (field.value.meta.humanEdited) {
      field.value.updatedAt = nowToISO();
    }
    return [field.uniqueFieldId];
  }

  #handleAddEntry(
    mutation: FieldStateMutationAddEntry,
    fields: BaseFieldState[],
    rememberedFields: RememberedField[],
  ): UniqueFieldId[] {
    const { entryId, uniqueFieldId: parentId, formVersion, meta } = mutation;
    const field = findField(fields, parentId);
    if (isNil(field)) {
      logger.error("Can't add entry, field not found", null, {
        extra: { submissionId: this.submissionId },
      });
      return [];
    }

    const newFieldStates: BaseFieldState[] = createNewFieldStatesForParent(
      formVersion?.fields || [],
      rememberedFields,
      this.deviceId,
      this.submissionId,
      this.formId,
      entryId,
      parentId,
    );

    const order = highestOrder(field.value.entries) + 1;
    const newEntry: SubformEntry<unknown> = {
      id: entryId,
      submissionId: this.submissionId,
      meta: { ...meta, error: false, order, createdOn: nowToISO(), description: "" },
      deleted: false,
    };

    field.deviceId = this.deviceId;
    field.value.entries = [...(field.value.entries ?? []), newEntry];
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    fields.push(...newFieldStates);

    return [field.uniqueFieldId, ...newFieldStates.map((f) => f.uniqueFieldId)];
  }

  #handleDeleteEntry(mutation: FieldStateMutationDeleteEntry, fields: BaseFieldState[]): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      return [];
    }

    const { entryId } = mutation;
    const deletedFields: UniqueFieldId[] = [];

    field.deviceId = this.deviceId;
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    field.value.entries?.forEach((entry) => {
      if (entry.id === entryId) {
        entry.deleted = true;
      }
    });

    const entryIdsToDelete = getNestedEntries(fields, entryId);
    fields.forEach((f) => {
      if (!isNil(f.value.meta.entryId) && entryIdsToDelete.includes(f.value.meta.entryId)) {
        f.deleted = true;
        deletedFields.push(f.uniqueFieldId);
      }
    });

    return [field.uniqueFieldId, ...deletedFields];
  }

  #handleUpdateEntry(mutation: FieldStateMutationUpdateEntry, fields: BaseFieldState[]): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      return [];
    }

    const { value } = mutation;

    field.deviceId = this.deviceId;
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    field.value.entries?.forEach((entry) => {
      if (entry.id === value.id) {
        // we don't allow overwriting `submissionId` and `id`. And to update `delete`, use the `delete_entry` mutation
        entry.meta = value.meta;
      }
    });

    return [field.uniqueFieldId];
  }
}
