import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { MoreAppError } from "../context/MoreAppContext";

export const useStorageManagerEstimate = (): UseQueryResult<StorageEstimate | undefined, MoreAppError> =>
  useQuery({
    queryKey: ["storageEstimate"],
    queryFn: async () => {
      if (!(navigator.storage && typeof navigator.storage.estimate === "function")) {
        // This browser feature is not supported on all browser versions we support, see:
        // https://caniuse.com/mdn-api_navigator_storage
        return;
      } else {
        return navigator.storage.estimate();
      }
    },
  });
