import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import type { DataSourceEntry } from "../../../types/Datasource";
import CatalogueListItem from "./CatalogueListItem";
import type { Currency } from "../../../types/Currency";
import CatalogueItemContent from "./CatalogueItemContent";
import { asCurrency, getCatalogueItemTitle } from "../../../utils/datasourceUtil";
import useDrawer from "../../../hooks/useDrawer";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import useDatasourceSearch from "../../../hooks/useDatasourceSearch";
import useDatasourceToken from "../../../hooks/useDatasourceToken";
import useStateFormId from "../../../state/useStateFormId";
import useStateFormVersionId from "../../../state/useStateFormVersionId";
import EmptyContent from "../../EmptyContent";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isNil } from "lodash-es";

type SearchContentProps = {
  dataSourceId: string;
  query: string;
  entryFields: string[];
  subtitle?: string;
  showPrices: boolean;
  showVat: boolean;
  currency?: Currency;
  precision?: number;
  onAdd: (value: DataSourceEntry) => void;
  onInit: (value: boolean) => void;
  isExactQuery: boolean;
};

const OnlineCatalogueSearchContent: FC<SearchContentProps> = ({
  dataSourceId,
  query,
  entryFields,
  subtitle,
  currency,
  precision,
  showPrices,
  showVat,
  isExactQuery,
  onAdd,
  onInit,
}) => {
  const { t } = useTranslation();

  const virtuoso = useRef<VirtuosoHandle>(null);
  const formId = useStateFormId();
  const formVersionId = useStateFormVersionId();

  const { data: token, isLoading, isError } = useDatasourceToken(dataSourceId, formId, formVersionId, entryFields);

  const { data, isInitialLoading, error, fetchNextPage, hasNextPage } = useDatasourceSearch(
    dataSourceId,
    query,
    isExactQuery,
    [],
    token,
  );

  const [activeOpen, setActiveOpen] = useDrawer("catalogue-active");
  const [activeItem, setActiveItem] = useState<Record<string, string>>();

  useEffect(() => virtuoso.current?.scrollToIndex(0), [query, isExactQuery]);

  useEffect(() => onInit(!isLoading && !isError), [isLoading, isError]);

  if (isError) {
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_TITLE")}
        description={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_DESCRIPTION")}
      />
    );
  }

  if (isLoading || isInitialLoading) {
    return <Spinner className="mx-auto mt-4" />;
  }

  if (!isNil(error)) {
    if (error.response?.data.code === "index_not_ready") {
      return (
        <EmptyContent
          imgSrc="assets/errorpages/expired.svg"
          title={t("DATASOURCE_INDEX_NOT_READY_TITLE")}
          description={t("DATASOURCE_INDEX_NOT_READY_DESCRIPTION")}
        />
      );
    }
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_MALFUNCTION_TITLE")}
        description={t("DATASOURCE_MALFUNCTION_DESCRIPTION")}
      />
    );
  }

  return (
    <>
      <Virtuoso
        ref={virtuoso}
        data={data?.pages.flatMap((page) => page.results)}
        endReached={() => fetchNextPage()}
        increaseViewportBy={100}
        itemContent={(_index, hit) => (
          <CatalogueListItem
            key={hit.id}
            onClick={() => onAdd({ dataSourceId, id: hit.id, data: hit })}
            onClickInfo={(e) => {
              e.stopPropagation();
              setActiveItem(hit);
              setActiveOpen(true);
            }}
            name={hit.name}
            description={hit.description}
            thumbnail={hit.thumbnail ?? hit.photo}
            priceExVat={asCurrency(parseFloat(hit.priceExVat), currency, precision)}
            showPrices={showPrices}
          />
        )}
        components={{ Footer: () => <Spinner className={classNames("mx-auto my-2", { hidden: !hasNextPage })} /> }}
      />
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getCatalogueItemTitle(activeItem || {}, entryFields),
          subtitle,
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveOpen(false),
          },
        }}
        onClose={() => setActiveOpen(false)}
        contentPadding={false}
      >
        <CatalogueItemContent item={activeItem} showPrice={showPrices} showVat={showVat} />
      </Drawer>
    </>
  );
};

export default OnlineCatalogueSearchContent;
