import { useMoreAppClient } from "../context/MoreAppContext";
import useAuth from "./useAuth";
import useToasts from "./useToasts";

type UseImpersonationResult = {
  validateUsername: (username: string) => Promise<boolean>;
  attemptImpersonation: (username: string) => Promise<boolean>;
  setImpersonatedUser: (username: string | undefined) => void;
  clearImpersonation: () => void;
};

export const useImpersonation = (): UseImpersonationResult => {
  const { setImpersonatedUser } = useAuth();
  const { showToast } = useToasts();
  const client = useMoreAppClient();

  const validateUsername = async (username: string): Promise<boolean> => {
    try {
      await client?.get(`/api/v1.0/admin/users/${username}`);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  };

  const attemptImpersonation = async (username: string): Promise<boolean> => {
    const isValidUsername = await validateUsername(username);
    if (isValidUsername) {
      setImpersonatedUser(username);
      showToast({ message: `Impersonating ${username}` });
      return true;
    }
    showToast({ message: `Couldn't impersonate ${username} (wrong username?)` });
    return false;
  };

  const clearImpersonation = (): void => {
    setImpersonatedUser(undefined);
    showToast({ message: "Impersonation cleared" });
  };

  return {
    validateUsername,
    attemptImpersonation,
    setImpersonatedUser,
    clearImpersonation,
  };
};
