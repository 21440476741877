import type { Dispatch, FC, SetStateAction } from "react";
import React, { useState } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import classNames from "classnames";
import Container from "../components/Container";
import VersionUpdateToast from "../components/VersionUpdateToast";
import NavSideBar from "../components/NavSideBar";
import NavTabBar from "../components/NavTabBar";
import useAuth from "../hooks/useAuth";
import type { WorkspaceHeaderProps } from "../components/WorkspaceHeader";
import WorkspaceHeader from "../components/WorkspaceHeader";
import FormsPage from "./FormsPage";
import { SubmissionBadgeProvider } from "../context/SubmissionBadgeContext";
import useStatusBar from "../hooks/useStatusBar";

export type NavigationPageOutletContext = {
  setShowTabBar: Dispatch<SetStateAction<boolean>>;
  setShowWorkspaceHeader: Dispatch<SetStateAction<boolean>>;
  setHeaderProps: Dispatch<SetStateAction<WorkspaceHeaderProps>>;
};

const NavigationPage: FC = () => {
  const { hasCustomerAccess } = useAuth();
  const [showTabBar, setShowTabBar] = useState(false);
  const [showWorkspaceHeader, setShowWorkspaceHeader] = useState(false);
  const [headerProps, setHeaderProps] = useState<WorkspaceHeaderProps>({
    defaultTitle: "",
    actions: [],
  });
  const formsRoute = useRoutes([{ path: "folders/:id", element: <FormsPage /> }]);
  useStatusBar();

  return (
    <Container>
      <SubmissionBadgeProvider>
        <VersionUpdateToast />
        <div className="flex h-screen w-full overscroll-x-none lg:divide-x">
          <NavSideBar className="hidden flex-none lg:flex" />
          <main className="flex h-screen grow flex-col">
            <div className="grid grow auto-cols-fr grid-flow-col overflow-y-hidden lg:divide-x xl:border-r">
              <div className={classNames("flex-col overflow-y-auto", formsRoute ? "hidden lg:flex" : "flex")}>
                {showWorkspaceHeader && (
                  <div className="bg-white">
                    <WorkspaceHeader {...headerProps} />
                  </div>
                )}
                <div className="flex-1 overflow-y-auto">
                  <Outlet context={{ setShowTabBar, setHeaderProps, setShowWorkspaceHeader }} />
                </div>
              </div>
              {formsRoute}
            </div>
            {hasCustomerAccess && showTabBar && <NavTabBar className="w-full flex-none lg:hidden" />}
          </main>
        </div>
      </SubmissionBadgeProvider>
    </Container>
  );
};

export default NavigationPage;
