import type {
  DeleteFileOptions,
  GetUriOptions,
  ReaddirOptions,
  RmdirOptions,
  StatOptions,
  WriteFileOptions,
} from "@capacitor/filesystem";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import type {
  GetUriResult,
  ReadFileOptions,
  ReadFileResult,
  ReaddirResult,
  StatResult,
  WriteFileResult,
} from "@capacitor/filesystem/dist/esm/definitions";
import type { BlobWriteOptions } from "capacitor-blob-writer";
import write_blob from "capacitor-blob-writer";

export const deleteFile = async (options: DeleteFileOptions): Promise<void> => Filesystem.deleteFile(options);
export const readFile = async (options: ReadFileOptions): Promise<ReadFileResult> =>
  Filesystem.readFile({ directory: Directory.Data, encoding: Encoding.UTF8, ...options });

export const readFileAsText = async (options: ReadFileOptions): Promise<string> => {
  const { data } = await readFile(options);
  return data instanceof Blob ? readBlobAsText(data) : data;
};

export const writeFileBlob = async (options: BlobWriteOptions): Promise<string> =>
  write_blob({
    directory: Directory.Data,
    recursive: true,
    // fast_mode vastly improves read/write speeds on web. 'readFile' returns Blob instead of Base64-string. This option is ignored on native iOS and Android
    // However, storing blob urls does not work on Safari with private mode enabled
    // See https://github.com/WebKit/WebKit/blob/c1a5c61946b9f75678be873346223d40f774f814/Source/WebCore/Modules/indexeddb/IDBObjectStore.cpp (line 352)
    fast_mode: false,
    ...options,
  });

export const writeFile = async (options: WriteFileOptions): Promise<WriteFileResult> =>
  Filesystem.writeFile({
    directory: Directory.Data,
    recursive: true,
    encoding: Encoding.UTF8,
    ...options,
  });
export const readDir = async (options: ReaddirOptions): Promise<ReaddirResult> =>
  Filesystem.readdir({ directory: Directory.Data, ...options });
export const stat = async (options: StatOptions): Promise<StatResult> =>
  Filesystem.stat({ directory: Directory.Data, ...options });
export const rmDir = async (options: RmdirOptions): Promise<void> =>
  Filesystem.rmdir({ directory: Directory.Data, recursive: true, ...options });
export const getUri = async (options: GetUriOptions): Promise<GetUriResult> => Filesystem.getUri({ ...options });

const readBlobAsText = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event): any | null => resolve(event.target?.result as string);
    reader.onerror = reject;
    reader.readAsText(blob);
  });
