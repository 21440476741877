import type { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import WidgetHidden from "./widgets/WidgetHidden";
import type { FieldState } from "../types/SubmissionState";
import type { WidgetProperties } from "../types/FormVersion";
import type { WidgetResult } from "../types/Field";
import type { TranslationKeys } from "../i18n";

type WidgetContainerProps = {
  fieldState: FieldState<WidgetProperties, WidgetResult<unknown>>;
  name: TranslationKeys;
  className?: string;
};

const WidgetContainer: FC<PropsWithChildren<WidgetContainerProps>> = ({ name, fieldState, children, className }) => {
  const { t } = useTranslation();

  if (!fieldState.visible || fieldState.deleted) {
    return <WidgetHidden />;
  }

  const labelText = fieldState.properties.label_text;
  const articleLabel = `${labelText ? `${labelText} - ` : ""}${t(name)}`;

  return (
    <article
      aria-label={articleLabel}
      id={`field-${fieldState.uniqueFieldId}`}
      className={classNames("scroll-mt-14", className)}
    >
      {children}
    </article>
  );
};

export default WidgetContainer;
