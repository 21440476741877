import type { UseMutateAsyncFunction, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import type { RemoteSubmission } from "../types/Submission";
import type { RemoteField } from "../types/Field";

type SendSubmissionRequest = RemoteSubmission & { fields: RemoteField[] };

type UseSubmissionSendResult = UseMutationResult<any, MoreAppError, SendSubmissionRequest, unknown> & {
  sendSubmission: UseMutateAsyncFunction<any, MoreAppError, SendSubmissionRequest>;
};

const useSubmissionSend = (): UseSubmissionSendResult => {
  const client = useMoreAppClient();
  const mutation = useMutation<any, MoreAppError, SendSubmissionRequest>({
    mutationFn: async (request) => client?.post(`/api/v1.0/client/submissions`, request),
  });

  return { sendSubmission: mutation.mutateAsync, ...mutation };
};

export default useSubmissionSend;
