import { parseInt } from "lodash-es";
import type { CurrencyResult, Widget } from "../../types/Widget";
import type { WidgetResult } from "../../types/Field";
import type { WidgetPriceProperties } from "./WidgetPrice";
import WidgetPrice from "./WidgetPrice";
import { validateCurrency } from "../../utils/validationUtil";

export type WidgetDecimalProperties = {
  decimal_places: string;
} & WidgetPriceProperties;

const WidgetDecimal: Widget<WidgetDecimalProperties, WidgetResult<CurrencyResult>> = ({ ...props }) => (
  <WidgetPrice {...props} />
);

WidgetDecimal.defaultValue = (_properties, defaultMeta: any): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "decimal",
    ...defaultMeta,
  },
});

WidgetDecimal.validate = (val, properties, t): string | undefined => validateCurrency(properties, val, t);

WidgetDecimal.mapProperties = (input: WidgetDecimalProperties): WidgetPriceProperties => ({
  ...input,
  currency: input.currency !== "empty" ? input.currency : undefined,
  precision: parseInt(input.decimal_places),
});

export default WidgetDecimal;
