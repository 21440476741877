import { GraphQLClient } from "graphql-request";
import type { GraphQLFieldAggregateResponse, GraphQLSubmissionAggregateResponse } from "./GraphQLReplicator";
import { SYNC_URLS } from "./GraphQLReplicator";
import { QUERIES } from "../../utils/databaseUtil";
import type { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";
import { seconds } from "../../utils/timeUtil";
import logger from "../../utils/logger";
import type { Collection, SyncStatus } from "./SyncStatus";

export type SyncData = {
  syncStatus: SyncStatus;
  onSyncStatusUpdate: (syncStatus: SyncStatus) => void;
  collection: Collection;
};

export class syncStatusUtil {
  public syncStatus: SyncStatus;
  public onSyncStatusUpdate: (syncStatus: SyncStatus) => void;
  public collection: Collection;

  constructor(syncData: SyncData) {
    this.syncStatus = syncData.syncStatus;
    this.onSyncStatusUpdate = syncData.onSyncStatusUpdate;
    this.collection = syncData.collection;
  }

  async setPullTotals(headers: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    if (this.collection === "fields") {
      this.syncStatus.fields.pullState.countIsCalled = true;
      this.onSyncStatusUpdate(this.syncStatus);
      await this.syncStatus.setFieldsTotal(headers, seq);
      this.syncStatus.fields.pullState.isPulling = true;
    }
    if (this.collection === "submissions") {
      this.syncStatus.submissions.pullState.countIsCalled = true;
      this.onSyncStatusUpdate(this.syncStatus);
      await this.syncStatus.setSubmissionsTotal(headers, seq);
      this.syncStatus.submissions.pullState.isPulling = true;
    }
    this.onSyncStatusUpdate(this.syncStatus);
  }

  public setIsPushing(isPushing: boolean): void {
    if (this.collection === "fields") {
      this.syncStatus.fields.pushState.isPushing = isPushing;
    }
    if (this.collection === "submissions") {
      this.syncStatus.submissions.pushState.isPushing = isPushing;
    }
    this.onSyncStatusUpdate(this.syncStatus);
  }

  public addPullCount(count: number): void {
    if (this.collection === "fields") {
      this.syncStatus.addFieldCount(count);
    }
    if (this.collection === "submissions") {
      this.syncStatus.addSubmissionCount(count);
    }
    this.onSyncStatusUpdate(this.syncStatus);
  }

  public resetPullCounter(): void {
    this.syncStatus.resetPull(this.collection);
    this.onSyncStatusUpdate(this.syncStatus);
  }
}

export const totalSubmissionRequest = async (
  authHeader: GraphQLClientRequestHeaders,
  seq: number,
  timeout = seconds(10),
): Promise<GraphQLSubmissionAggregateResponse> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  setTimeout(() => {
    abortController.abort();
  }, timeout);

  const client = new GraphQLClient(SYNC_URLS.http, {
    headers: authHeader,
    signal,
  });

  try {
    return await client.request<GraphQLSubmissionAggregateResponse>(QUERIES.COUNT.submissions(seq === 0), {
      seq: seq ?? 0,
    });
  } catch (error) {
    logger.warn("Error fetching total submissions", error);
    return { app_submissions_aggregate: { aggregate: { count: 0 } } };
  }
};

export const totalFieldsRequest = async (
  authHeader: GraphQLClientRequestHeaders,
  seq: number,
  timeout = seconds(10),
): Promise<GraphQLFieldAggregateResponse> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  setTimeout(() => {
    abortController.abort();
  }, timeout);

  const client = new GraphQLClient(SYNC_URLS.http, {
    headers: authHeader,
    signal,
  });

  try {
    return await client.request<GraphQLFieldAggregateResponse>(QUERIES.COUNT.fields(seq === 0), {
      seq: seq ?? 0,
    });
  } catch (error) {
    logger.warn("Error fetching total fields", error);
    return { app_submission_fields_aggregate: { aggregate: { count: 0 } } };
  }
};
