import { Device } from "@capacitor/device";
import { useMutation } from "@tanstack/react-query";
import { Capacitor } from "@capacitor/core";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import type { MoreAppError } from "../context/MoreAppContext";
import { useMoreAppClient } from "../context/MoreAppContext";
import useToasts from "./useToasts";
import logger from "../utils/logger";

type RegisterPushToken = {
  deviceToken: string;
  platform: string;
  deviceUuid: string;
};

export type UsePushResult = {
  registerPush: () => Promise<void>;
  deregisterPush: () => Promise<void>;
};

const usePush = (): UsePushResult => {
  const client = useMoreAppClient();
  const { showToast } = useToasts();
  const { mutateAsync: storeToken } = useMutation<any, MoreAppError, RegisterPushToken>({
    mutationFn: async (data) => client!.post(`/api/v1.0/client/notifications/device`, data),
  });
  const { mutateAsync: removeToken } = useMutation<any, MoreAppError, string>({
    mutationFn: async (deviceUuid) => client!.delete(`/api/v1.0/client/notifications/device/${deviceUuid}`),
  });
  const getDeviceUuid = async (): Promise<string> => (await Device.getId()).identifier;

  const registerPush = async (): Promise<void> => {
    if (!Capacitor.isNativePlatform()) {
      return; // web not (yet) supported
    }
    let permStatus = await FirebaseMessaging.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await FirebaseMessaging.requestPermissions();
    }
    if (permStatus.receive !== "granted") {
      logger.log("User denied push permissions");
      return;
    }
    const { token } = await FirebaseMessaging.getToken();
    await sendTokenRemote(token);
    await addListeners();
  };

  const addListeners = async (): Promise<void> => {
    // Purge any remaining listeners
    await FirebaseMessaging.removeAllListeners();
    await FirebaseMessaging.addListener("tokenReceived", async (event) => {
      await sendTokenRemote(event.token);
    });

    await FirebaseMessaging.addListener("notificationReceived", (event) => {
      showToast({ message: event.notification.title ?? "", icon: "BellIcon" });
    });
  };

  const sendTokenRemote = async (token: string): Promise<void> => {
    const deviceUuid = await getDeviceUuid();
    await storeToken({ deviceToken: token, deviceUuid, platform: "FIREBASE" });
  };

  const deregisterPush = async (): Promise<void> => {
    if (!Capacitor.isNativePlatform()) {
      return; // web not (yet) supported
    }
    const deviceUuid = await getDeviceUuid();
    await removeToken(deviceUuid).catch((e) => logger.error("Could not remove push token", e));
    try {
      await FirebaseMessaging.removeAllListeners();
    } catch (e) {
      logger.error("Could not remove push listeners", e);
    }
  };

  return {
    registerPush,
    deregisterPush,
  };
};

export default usePush;
