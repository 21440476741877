import type { FC, JSX, MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import { useTranslation } from "react-i18next";
import type { FabricMode, FabricProps } from "../utils/drawingUtil";
import {
  changeColor,
  disableSelection,
  enableSelection,
  enforceBoundaries,
  initializeEvents,
  setBackgroundImage,
  setupArrowShape,
} from "../utils/drawingUtil";
import useDrawer from "../hooks/useDrawer";
import logger from "../utils/logger";
import { DropdownMenu } from "../storybook/components/DropdownMenu/DropdownMenu";
import { IconButton, type IconButtonProps } from "../storybook/components/IconButton/IconButton";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { TextInput } from "../storybook/components/TextInput/TextInput";
import type { TranslationKeys } from "../i18n";

type SketchbookProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  save: (value: fabric.Canvas | undefined) => void;
  clear: () => void;
  initialCanvas: unknown;
  backgroundUrl?: string;
  initialSize?: CanvasSize;
  onCloseFocusId?: string;
};

type CanvasSize = {
  height: number;
  width: number;
};

export const Sketchbook: FC<SketchbookProps> = ({
  open,
  setOpen,
  save,
  clear,
  initialCanvas,
  backgroundUrl,
  initialSize,
  onCloseFocusId,
}) => {
  const { t } = useTranslation();
  const [showTextModal, setShowTextModal] = useDrawer("addtext");
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const [addText, setAddText] = useState<string | undefined>(undefined);
  const [zoom, setZoom] = useState<number>(1);
  const [initialized, setInitialized] = useState<boolean>();
  const [mode, setMode] = useState<FabricMode>("draw");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fabricProps = useRef<FabricProps>({
    lastPosX: 0,
    lastPosY: 0,
    isDragging: false,
    isDown: false,
    activeMode: "draw",
    color: "black",
    fabricObject: undefined,
  });
  const sizeRef = useRef<CanvasSize>();

  useEffect(() => {
    if (editor && !initialized) {
      setInitialized(true);
      changeMode(mode);
      // @ts-expect-error
      if (!fabric.LineArrow) {
        // Load custom shapes here
        setupArrowShape();
      }
      initializeEvents(editor.canvas, fabricProps.current, setZoom);
      changeColor(editor.canvas, fabricProps.current, "red");
      setRefSize();
      if (initialCanvas) {
        // LoadFromJSON will error if the canvas context is deleted
        setIsLoading(true);
        editor.canvas?.loadFromJSON(initialCanvas, () => {
          setRefSize(initialSize);
          handleResize();
          setIsLoading(false);
        });
      } else if (backgroundUrl) {
        setIsLoading(true);
        setBackgroundImage(editor.canvas, backgroundUrl)
          .finally(() => setIsLoading(false))
          .catch((e) => logger.error("Could not set background image", e));
      }
    }
  }, [editor, initialized, initialCanvas]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleResize = (): void => {
    if (!sizeRef.current || !editor) {
      return;
    }
    const { height: originalHeight, width: originalWidth } = sizeRef.current;
    const newHeight = editor.canvas?.getHeight();
    const newWidth = editor.canvas?.getWidth();

    const scaleX = newWidth / originalWidth;
    const scaleY = newHeight / originalHeight;

    editor.canvas.forEachObject((object) => {
      object.scaleX! *= scaleX;
      object.scaleY! *= scaleY;
      object.left! *= scaleX;
      object.top! *= scaleY;

      object.setCoords();
    });

    setRefSize();
    if (backgroundUrl) {
      setBackgroundImage(editor.canvas, backgroundUrl).catch((e) => logger.error("Could not set background image", e));
    }
    try {
      editor.canvas.renderAll();
    } catch (e) {
      logger.warn("Context is probably already gone or not initialized yet", e);
    }
  };

  const colorItem = (color: string, translationLabel: TranslationKeys): { label: string; onClick: () => void } => ({
    label: t(translationLabel),
    onClick: () => changeColor(editor!.canvas, fabricProps.current, color),
  });

  const setRefSize = (size?: CanvasSize): void => {
    if (size) {
      sizeRef.current = size;
      return;
    }

    if (!editor || !editor.canvas.getHeight() || !editor.canvas.getWidth()) {
      return;
    }

    sizeRef.current = { height: editor.canvas.getHeight(), width: editor.canvas.getWidth() };
  };

  const colorPalette = (
    <DropdownMenu
      menuButton={() => (
        <IconButton
          aria-label={`${t("CHANGE_PEN_COLOR")}. ${t("CURRENT_PEN_COLOR", {
            color: fabricProps.current.color,
          })}`}
          icon="CircleIcon"
          style={{ color: fabricProps.current.color }}
        />
      )}
      items={[
        colorItem("black", "BLACK"),
        colorItem("red", "RED"),
        colorItem("blue", "BLUE"),
        colorItem("green", "GREEN"),
        colorItem("yellow", "YELLOW"),
      ]}
    />
  );
  const addTextToCanvas = (): void => {
    if (!addText || !editor) {
      return;
    }
    const text = new fabric.Text(addText, {
      fill: fabricProps.current.color,
      fontFamily: "Inter",
    });
    editor.canvas.add(text);
    text.viewportCenter();
    text.setCoords();
    editor.canvas.renderAll();
    closeText();
  };
  const closeText = (): void => {
    setShowTextModal(false);
    setAddText(undefined);
    changeMode("drag");
  };

  const changeMode = (fabricMode: FabricMode): void => {
    if (!editor || !fabricProps.current) {
      return;
    }
    enableSelection(editor.canvas);
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
    fabricProps.current.activeMode = fabricMode;
    setMode(fabricMode);
    switch (fabricMode) {
      case "drag":
        editor.canvas.isDrawingMode = false;
        editor.canvas.selection = true;
        break;
      case "line":
      case "arrow":
      case "navigate":
        editor.canvas.isDrawingMode = false;
        editor.canvas.selection = false;
        disableSelection(editor.canvas);
        break;
      case "draw":
      default:
        editor.canvas.isDrawingMode = true;
        break;
    }
  };

  const deleteObject = (objects?: unknown[]): void => {
    if (!objects) {
      return;
    }
    editor?.canvas?.remove(...(objects as fabric.Object[]));
  };

  const zoomIn = (): void => {
    if (!editor) {
      return;
    }
    changeMode("navigate");
    const newZoom = Math.min(editor.canvas.getZoom() + 0.5, 5);
    editor.canvas.setZoom(newZoom);
    setZoom(newZoom);
    enforceBoundaries(editor.canvas);
  };

  const zoomOut = (): void => {
    if (!editor) {
      return;
    }
    const newZoom = Math.max(editor.canvas.getZoom() - 0.5, 1);
    changeMode(newZoom === 1 ? "drag" : "navigate");
    editor.canvas.setZoom(newZoom);
    setZoom(newZoom);
    enforceBoundaries(editor.canvas);
  };

  const addTextDrawer = (
    <Drawer
      open={showTextModal}
      header={{
        kind: "simple",
        title: t("DRAWING"),
        button: { kind: "icon", icon: "XIcon", onClick: closeText },
      }}
      initialFocus={initialFocus}
      onClose={closeText}
      footer={{
        kind: "default",
        primaryButton: {
          label: t("ADD"),
          onClick: addTextToCanvas,
        },
      }}
    >
      <div className="flex flex-col space-y-4 py-4">
        <TextInput
          name="name"
          type="text"
          label="Text"
          value={addText}
          onChange={(val) => {
            setAddText(val.target.value);
          }}
        />
      </div>
    </Drawer>
  );

  const showColorPalette = ["draw", "line", "arrow"].includes(mode);

  const iconButton = (props: IconButtonProps): JSX.Element => (
    // Necessary to not consume events necessary for FabricJS
    <IconButton {...props} className={props.disabled ? "pointer-events-none" : ""} />
  );

  return (
    <Drawer
      onCloseFocusId={onCloseFocusId}
      open={open}
      header={{
        kind: "simple",
        title: t("DRAWING"),
        button: { kind: "icon", icon: "XIcon", onClick: () => setOpen(false) },
      }}
      onClose={() => setOpen(false)}
      className="bg-gray-100"
      footer={{
        kind: "default",
        primaryButton: {
          label: t("SAVE"),
          onClick: () => save(editor?.canvas),
        },
        secondaryButton: {
          label: t("CLEAR"),
          disabled: isLoading,
          className: isLoading ? "pointer-events-none" : "",
          onClick: clear,
        },
      }}
    >
      {addTextDrawer}
      <div className="flex size-full flex-col pt-2">
        <div className="flex w-full gap-x-2">
          {zoom > 1 &&
            iconButton({
              "aria-label": t("SWITCH_TO_NAVIGATION_MODE"),
              icon: "ArrowsExpandIcon",
              disabled: mode === "navigate",
              onClick: () => changeMode("navigate"),
            })}
          {iconButton({
            "aria-label": t("SWITCH_TO_DRAW_MODE"),
            icon: "PencilIcon",
            disabled: mode === "draw",
            onClick: () => changeMode("draw"),
          })}
          {iconButton({
            "aria-label": t("ADD_LINE"),
            icon: "MinusIcon",
            disabled: mode === "line",
            onClick: () => changeMode("line"),
          })}
          {iconButton({
            "aria-label": t("ADD_ARROW"),
            icon: "ArrowRightIcon",
            disabled: mode === "arrow",
            onClick: () => changeMode("arrow"),
          })}
          {iconButton({
            "aria-label": t("ADD_TEXT"),
            icon: "ChatIcon",
            onClick: () => setShowTextModal(true),
          })}
          {iconButton({
            "aria-label": t("SWITCH_TO_DRAG_MODE"),
            icon: "HandIcon",
            disabled: mode === "drag",
            onClick: () => changeMode("drag"),
          })}
          {showColorPalette && colorPalette}
          {selectedObjects && selectedObjects.length > 0 && mode === "drag" && (
            <IconButton
              aria-label={t("DELETE_SELECTED_OBJECT")}
              variant="destructive"
              icon="TrashIcon"
              onClick={() => deleteObject(selectedObjects)}
            />
          )}
        </div>
        <div className="aspect-h-1 aspect-w-1 my-auto w-full rounded-lg border-2 bg-white">
          <FabricJSCanvas
            onReady={(canvas) => {
              onReady(canvas);
              setInitialized(false);
            }}
          />
        </div>
        <div className="flex justify-end gap-x-2 pb-2">
          {iconButton({ "aria-label": t("ZOOM_OUT"), icon: "MinusIcon", disabled: zoom === 1, onClick: zoomOut })}
          {iconButton({ "aria-label": t("ZOOM_IN"), icon: "PlusIcon", disabled: zoom >= 5, onClick: zoomIn })}
        </div>
      </div>
    </Drawer>
  );
};
