import { type UseMutationResult, type MutationFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";

export type UserProfileUpdate = {
  firstName: string;
  lastName: string;
};

const useUserProfile = (): UseMutationResult<unknown, MoreAppError, UserProfileUpdate> => {
  const client = useMoreAppClient();
  const queryClient = useQueryClient();

  return useMutation<any, MoreAppError, UserProfileUpdate>({
    mutationFn: async (data): Promise<MutationFunction<any, UserProfileUpdate> | undefined> =>
      client!.patch(
        `/api/v1.0/users`,
        [
          {
            op: "replace",
            path: "/settings/firstName",
            value: data.firstName,
          },
          {
            op: "replace",
            path: "/settings/lastName",
            value: data.lastName,
          },
        ],
        {
          headers: { "Content-Type": "application/json-patch+json" },
        },
      ),
    onSuccess: async (): Promise<void> => queryClient.invalidateQueries({ queryKey: ["user"] }),
  });
};

export default useUserProfile;
