import type { CameraSource, ImageOptions, Photo } from "@capacitor/camera";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";
import useCamera from "./useCamera";
import uuidv4 from "../utils/uuid";
import type { UploadResult } from "./useFileHandler";
import useFileHandler from "./useFileHandler";
import { startForegroundService, stopForegroundService } from "../utils/foregroundServiceUtil";
import { getCurrentAppInfo } from "../utils/deviceUtil";
import { fileTypes, getExtensionUri, isExtensionAllowedMimeType } from "../utils/fileUtil";
import useStateSubmissionId from "../state/useStateSubmissionId";

export type CameraOptions = {
  source: CameraSource;
  options?: Partial<ImageOptions>;
};

type UsePhotoHandlerResult = {
  addPhoto: (camera: CameraOptions) => Promise<UploadResult & Photo>;
};

const usePhotoHandler = (): UsePhotoHandlerResult => {
  const { t } = useTranslation();
  const submissionId = useStateSubmissionId();
  const { getPhoto } = useCamera();
  const { storeFileUri } = useFileHandler();

  const addPhoto = async (camera: CameraOptions): Promise<UploadResult & Photo> => {
    try {
      await startService(t);
      const photo = await getPhoto(camera.source, camera.options);
      const id = uuidv4();

      const extension = await getExtensionUri(photo.webPath!);

      if (!extension || !isExtensionAllowedMimeType(extension, fileTypes.images)) {
        return { fileResult: { id, extension }, uploadStatus: "invalid_extension", format: "", saved: false };
      }
      const result = await storeFileUri(photo.webPath!, submissionId, id);
      return { ...result, ...photo };
    } catch (e) {
      return await Promise.reject(e);
    } finally {
      await stopForegroundService();
    }
  };

  return { addPhoto };
};

const startService = async (t: TFunction): Promise<void> => {
  const appInfo = await getCurrentAppInfo();
  // For the user, this state is considered to be in the background, as we're killing this notification the moment that the user re-enters the application.
  // So the translation should reflect that.
  await startForegroundService(appInfo.name ?? "Forms", t("APP_RUNNING_IN_BACKGROUND", { appName: appInfo.name }));
};

export default usePhotoHandler;
