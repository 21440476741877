import useDatabase from "../hooks/useDatabase";
import { CURRENT_COMMIT } from "../utils/versionUtil";
import useDeviceInfo from "../hooks/useDeviceInfo";
import useAuth from "../hooks/useAuth";
import useCurrentAppVersion from "../hooks/useCurrentAppVersion";
import { seconds } from "../utils/timeUtil";
import { useMoreAppClient } from "../context/MoreAppContext";
import logger from "../utils/logger";
import { isNil } from "lodash-es";
import { useMutation } from "@tanstack/react-query";
import useToasts from "../hooks/useToasts";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { FEATURE_FLAGS } from "../utils/posthogUtil";
import { t } from "i18next";

type UseSendDiagnosticsResult = {
  sendDiagnostics: () => void;
  isDiagnosticsSent: boolean;
  isDiagnosticsSending: boolean;
  isDiagnosticsSendEnabled: boolean | undefined;
};

export const useSendDiagnostics = (): UseSendDiagnosticsResult => {
  const { syncStatus } = useDatabase();
  const deviceInfo = useDeviceInfo();
  const { database: db } = useDatabase();
  const { username, customerId } = useAuth();
  const { currentVersion } = useCurrentAppVersion();
  const client = useMoreAppClient();
  const { showToast, hideToast } = useToasts();
  const isDiagnosticsSendEnabled = useFeatureFlagEnabled(FEATURE_FLAGS.SEND_DIAGNOSTICS);

  const mutation = useMutation({
    mutationFn: async () => {
      if (isNil(db)) {
        throw new Error("Database is not initialized");
      }

      const metaData = JSON.stringify({
        metaData: {
          appVersion: currentVersion?.format(),
          username,
          customerId,
          deviceInfo,
          commitHash: CURRENT_COMMIT,
        },
        syncStatus,
        fields: await db.fields.exportJSON(),
        submissions: await db.submissions.exportJSON(),
      });

      const blob = new Blob([metaData], { type: "application/json" });
      const data = new FormData();
      data.append("file", blob, "diagnostics.json");
      await client?.post("/api/v1.0/client/diagnostics", data, {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: seconds(90), // Overriding the default. File uploads can take longer on unstable connections
      });
    },
    onError: (error) => {
      logger.error("Failed to send diagnostics", error);
      showToast({
        message: t("SETTINGS_ACCOUNT_SEND_DIAGNOSTICS_ERROR"),
        icon: "XIcon",
        button: {
          label: t("RETRY"),
          onClick: () => {
            mutation.mutateAsync();
            hideToast();
          },
          type: "text",
        },
      });
    },
    onSuccess: () => {
      showToast({ message: t("SETTINGS_ACCOUNT_SEND_DIAGNOSTICS_DESCRIPTION"), icon: "CheckIcon" });
    },
  });

  return {
    sendDiagnostics: mutation.mutateAsync,
    isDiagnosticsSent: mutation.isSuccess,
    isDiagnosticsSending: mutation.isPending,
    isDiagnosticsSendEnabled,
  };
};
