import type { FC, MutableRefObject, PropsWithChildren } from "react";
import { Fragment } from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import type { DrawerHeaderProps } from "./DrawerHeader";
import DrawerHeader from "./DrawerHeader";
import type { DrawerFooterProps } from "./DrawerFooter";
import DrawerFooter from "./DrawerFooter";
import { useAfterLeaveFocus } from "../../../hooks/useAfterLeaveFocus";

export type DrawerProps = {
  className?: string;
  header: DrawerHeaderProps;
  footer?: DrawerFooterProps;
  open: boolean;
  initialFocus?: MutableRefObject<any>;
  onClose: () => void;
  autoHeight?: boolean;
  contentPadding?: boolean;
  onCloseFocusId?: string;
};

export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  className,
  header,
  footer,
  open,
  initialFocus,
  onClose,
  autoHeight,
  contentPadding = true,
  onCloseFocusId,
}) => {
  const { afterLeaveFocus } = useAfterLeaveFocus(onCloseFocusId);
  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => afterLeaveFocus()}>
      <Dialog
        as="div"
        onClose={onClose}
        initialFocus={initialFocus}
        aria-label={header.kind !== "freestyle" ? header.title : ""}
      >
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-50 bg-gray-500/75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <Dialog.Panel
              className={classNames(
                "pointer-events-none fixed z-50 pt-12",
                { "inset-x-0 bottom-0 max-h-screen": autoHeight },
                { "inset-0": !autoHeight },
              )}
            >
              <div
                className={classNames(
                  "pointer-events-auto mx-auto flex w-full max-w-lg flex-col divide-y divide-gray-200 rounded-t-2xl bg-white",
                  { "h-[calc(100vh-48px)] max-h-full": !autoHeight },
                  { "max-h-[calc(100vh-48px)]": autoHeight },
                )}
              >
                <DrawerHeader {...header} />
                <div
                  className={classNames(
                    "size-full max-h-screen max-w-lg overflow-y-auto",
                    { "px-6": contentPadding },
                    className,
                  )}
                >
                  {children}
                </div>
                {footer && <DrawerFooter className="border border-brand-500" {...footer} />}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
