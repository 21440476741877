export const PROD_ENV = process.env.VITE_ENV === "production";
export const ENV = `${process.env.VITE_ENV}`;

export const API_URL = `${process.env.VITE_MOREAPP_API_BASE_URL}/api/v1.0`;
export const API_V2_URL = `${process.env.VITE_MOREAPP_API_BASE_URL}/api/v2`;
export const LOG_COLLECTOR_URL = `${process.env.VITE_LOG_COLLECTOR_URL}`;

export const AUTH_APP_ID = "moreapp-app";
export const AUTH_REDIRECT_URL_ANDROID = `${process.env.VITE_AUTH_REDIRECT_URL_ANDROID}`;
export const AUTH_REDIRECT_URL_IOS = `${process.env.VITE_AUTH_REDIRECT_URL_IOS}`;
export const AUTH_BASE_URL = `${process.env.VITE_AUTH_BASE_URL}`;

export const LICENSE_PLATE_BASE_URL = `${process.env.VITE_LICENSEPLATE_BASE_URL}`;
export const LOCATION_BASE_URL = `${process.env.VITE_LOCATION_BASE_URL}`;
export const IMAGE_OPT_URL = `${process.env.VITE_IMAGE_OPT_URL}`;
export const SEARCH_URL = `${process.env.VITE_SEARCH_URL}`;

export const SENTRY_DSN = "https://c65662ec4bbb4c869f823343a71f7bdb@o434155.ingest.sentry.io/6009796";

export const POST_HOG_API_HOST = `${process.env.VITE_POST_HOG_API_HOST}`;
export const POST_HOG_UI_HOST = `${process.env.VITE_POST_HOG_UI_HOST}`;
export const POST_HOG_TOKEN = `${process.env.VITE_POST_HOG_TOKEN}`;
