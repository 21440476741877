import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { Capacitor } from "@capacitor/core";
import PageOutline from "./PageOutline";
import SentIcon from "../components/SentIcon";
import type { Theme } from "../storybook/themes";
import { Text } from "../storybook/components/Text/Text";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import branding from "../utils/brandingUtil";

type SentLandingPageProps = {
  theme: Theme;
};

const SentLandingPage: FC<SentLandingPageProps> = ({ theme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  const header = branding.isBranded() ? (
    <img className="h-8" src="/assets/logo-inverted.png" alt={t("LOGO_ALT")} />
  ) : (
    <a href="https://moreapp.com" rel="noreferrer" target="_blank">
      <img className="h-8" src="/assets/logo-inverted.svg" alt={t("LOGO_ALT")} />
    </a>
  );

  const navigateHome = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  const landingContent = (
    <div className="flex flex-col gap-y-6">
      <SentIcon className="mx-auto" theme={theme} />
      <Text className="text-center" size="lg" weight="semibold" color="dark">
        {t("SENT_LANDING_PAGE_TITLE")}
      </Text>
      <Text className="mx-6 text-center" color="medium">
        <Trans i18nKey="SENT_LANDING_PAGE_CONTENT" />
      </Text>
      {isNative ? (
        <div className="flex flex-col items-center">
          <IconAndTextButton
            icon="ArrowRightIcon"
            iconAlign="right"
            label={t("GOTO_HOME_PAGE_BUTTON")}
            onClick={navigateHome}
          />
        </div>
      ) : (
        <Text className="px-6 text-center" color="medium" size="base">
          <Trans i18nKey="SENT_LANDING_PAGE_CONTENT_WEB" />
        </Text>
      )}
    </div>
  );

  return <PageOutline header={header} content={landingContent} theme={theme} />;
};

export default SentLandingPage;
