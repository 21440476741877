import axios from "axios";

type UseCountryResult = { getCountryCode: () => Promise<any> };
const useCountry = (): UseCountryResult => ({ getCountryCode });

const getCountryCode = async (): Promise<any> => {
  try {
    const response = await axios.get("https://ip2c.org/s", { timeout: 10000 });
    return response.data.split(";")[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return navigator.language ? navigator.language.slice(-2).toUpperCase() : "UNKNOWN";
  }
};

export default useCountry;
