import type { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";
import { getAuthHeader } from "../../utils/authUtil";
import logger from "../../utils/logger";
import { generateRequestId } from "../../utils/hasuraUtil";

export const buildRequestHeaders = async (): Promise<GraphQLClientRequestHeaders> => {
  const auth = await getAuthHeader().catch((e) => {
    logger.error("Couldn't get auth header", e);
    throw e;
  });
  const requestId = await generateRequestId();

  return {
    Authorization: auth,
    "x-request-id": requestId,
  };
};
