import { useRxData } from "rxdb-hooks";
import { useMemo } from "react";
import { JSONPath } from "jsonpath-plus";
import type { Field } from "../types/Field";
import type { DataSourceEntry } from "../types/Datasource";

export type DataSourceImage = {
  name: string;
  url: string;
};

const useDatasourceImages = (submissionId: string): DataSourceImage[] => {
  const { result: fields } = useRxData<Field>(
    "fields",
    (fieldsCollection) =>
      fieldsCollection.find().where("submissionId").equals(submissionId).where("widget").equals("search"),
    { json: true },
  );

  return useMemo(() => {
    const fieldData = fields.map((f) => f.data) as DataSourceEntry[];
    return JSONPath({ path: "$..value^", json: fieldData })
      .filter((x: Record<string, unknown>) => x.type === "images")
      .flatMap((x: Record<string, unknown>) => x.value) as DataSourceImage[];
  }, [fields]);
};

export default useDatasourceImages;
