import type { CustomThemeName } from "../storybook/themes";

export type Folder = {
  id: string;
  customerId: number;
  meta: FolderMeta;
  status: "ACTIVE" | "HIDDEN" | "UNKNOWN";
  forms: Form[];
};

export type FolderMeta = {
  description: string;
  icon?: string;
  name: string;
  image: string;
};

export type FormMeta = {
  description: string;
  icon: string;
  iconColor: CustomThemeName;
  name: string;
  language: string;
  tags: string[];
};

export type FormPublishedVersion = {
  formVersion: string;
  publishedBy: string;
  publishedDate: Date;
};

export type Form = {
  id: string;
  meta: FormMeta;
  publishedVersion: FormPublishedVersion;
  status: "ACTIVE" | "HIDDEN" | "TRASH" | "UNKNOWN";
};

export type PublishedForm = Form & Pick<Folder, "customerId">;

export class FoldersNotFoundError extends Error {}
