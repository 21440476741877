import { Trans, useTranslation } from "react-i18next";
import type { FC } from "react";
import { Text } from "../../storybook/components/Text/Text";

const SubmissionNotFoundContent: FC<{ centered?: boolean }> = ({ centered = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <img className="mx-auto pb-8" src="assets/errorpages/not-exist.svg" alt={t("NOT_FOUND_ALT")} />
      <Text className={centered ? "pb-5 text-center" : "pb-5"} weight="semibold">
        {t("SUBMISSION_NOT_FOUND_TITLE")}
      </Text>
      <Text className={centered ? "pb-6 text-center" : "pb-6"}>
        {/* Needed instead of useTranslation in order to use breaklines  */}
        <Trans i18nKey="SUBMISSION_NOT_FOUND_DESCRIPTION" />
      </Text>
    </>
  );
};

export default SubmissionNotFoundContent;
