import { type OAuth2AuthenticateOptions, GenericOAuth2 } from "@capacitor-community/generic-oauth2";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import type { IdTokenClaims } from "oidc-client-ts";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import axios from "axios";
import { redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { API_URL, AUTH_REDIRECT_URL_ANDROID, AUTH_APP_ID, AUTH_BASE_URL, AUTH_REDIRECT_URL_IOS } from "../constants";
import type { Customer } from "./useCustomers";
import { parseJwt } from "../utils/stringUtil";
import logger from "../utils/logger";
import useToasts from "./useToasts";
import { getFormToken } from "./useFormTokenDetails";
import useDatabase from "./useDatabase";
import branding from "../utils/brandingUtil";
import posthog from "posthog-js";

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
};

export type TokenAuthentication = {
  customerId: number;
  formId: string;
};

export type Authorization = {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  customerId?: number;
  username?: string;
  userId?: string;
  type?: "oauth" | "token" | "bearerToken";
};

export class LoginFailedError extends Error {}

export type UseAuthResult = {
  loggingIn: boolean;
  isLoading: boolean;
  customerId?: number;
  customerName?: string;
  customers?: Customer[];
  setCustomers: (value?: Customer[]) => void;
  authorization: Authorization;
  setWorkspace: (customer: Customer) => void;
  lastLogin?: Date;
  refreshAccessToken: () => Promise<Authorization>;
  login: (onSuccess?: () => void) => Promise<void>;
  logout: () => Promise<void>;
  handleLoginCallback: () => Promise<void>;
  authorizeDirectUrl: (directUrlToken: string) => Promise<void>;
  deauthorizeDirectUrl: () => void;
  hasCustomerAccess: boolean;
  impersonatedUser?: string;
  setImpersonatedUser: (value?: string) => void;
  username?: string;
  authorizePreview: (customerId: number) => Promise<void>;
};

const useAuth = (): UseAuthResult => {
  const {
    isLoading,
    authorization,
    setAuthorization,
    customerId,
    setCustomerId,
    customerName,
    setCustomerName,
    customers,
    setCustomers,
    lastLogin,
    setLastLogin,
    directUrlAuth,
    setDirectUrlAuth,
    impersonatedUser,
    setImpersonatedUser,
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const loggingIn = useRef(false);
  const { destroy } = useDatabase();

  useEffect(() => {
    const isOAuthUser = authorization?.accessToken && authorization.type === "oauth";
    const isCustomerAvailable = !customerId || !customers?.find((customer) => customer.customerId === customerId);
    if (isOAuthUser && isCustomerAvailable) {
      enableFirstCustomerId().catch((e) => logger.error("Could not enable first customer", e));
    }
  }, [customers, authorization?.accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const storeToken = async (response: LoginResponse): Promise<Authorization> => {
    const jwt = parseJwt(response.access_token);
    const username = jwt.sub;
    const userId = jwt["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
    const auth: Authorization = {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      idToken: response.id_token,
      username,
      userId,
      type: "oauth",
    };
    setAuthorization(auth);
    return auth;
  };

  const setWorkspace = (customer: Customer): void => {
    setCustomerId(customer.customerId);
    setCustomerName(customer.name);
  };

  const enableFirstCustomerId = async (): Promise<void> => {
    if (customers && customers?.length > 0) {
      setWorkspace(customers[0]);
    }
  };

  const login = async (onSuccess?: () => void): Promise<void> => {
    if (!Capacitor.isNativePlatform()) {
      return userManager.signinRedirect();
    }
    try {
      loggingIn.current = true;
      let response = await GenericOAuth2.authenticate(oauth2Options);
      if (response.access_token_response) {
        response = response.access_token_response; // nesting is different on iOS
      }

      // Switch to oidc-client-ts from here, because it's a better library. Also, less duplication.
      const idTokenClaims = parseJwt(response.id_token) as IdTokenClaims;
      const newUser = new User({
        id_token: response.id_token,
        access_token: response.access_token,
        refresh_token: response.refresh_token,
        token_type: response.token_type,
        scope: response.scope,
        profile: idTokenClaims,
      });

      await userManager.storeUser(newUser);
      await onLoginSuccess(response, onSuccess);
    } catch (error: any) {
      if (error.message === "USER_CANCELLED") {
        return; // that's ok
      }
      alert(`${t("LOGIN_FAILED")}:\n${error.message}`);
      throw new LoginFailedError("Failed to login", { cause: error });
    } finally {
      loggingIn.current = false;
    }
  };

  const authorizeDirectUrl = async (directUrlToken: string): Promise<void> => {
    const data = await getFormToken(directUrlToken);
    setDirectUrlAuth({
      accessToken: directUrlToken,
      type: "token",
      customerId: data.customerId,
      username: "anonymous",
      userId: "anonymous",
    });
  };

  const authorizePreview = async (previewCustomerId: number): Promise<void> => {
    setDirectUrlAuth({
      type: "bearerToken",
      customerId: previewCustomerId,
      username: "anonymous",
      userId: "anonymous",
    });
  };

  const deauthorizeDirectUrl = useCallback(() => setDirectUrlAuth(undefined), [setDirectUrlAuth]);

  const handleLoginCallback = async (): Promise<void> => {
    try {
      const user = await userManager.signinCallback();
      await onLoginSuccess({
        access_token: user?.access_token!,
        refresh_token: user?.refresh_token!,
        id_token: user?.id_token!,
      });
    } catch (e) {
      await userManager.clearStaleState();
      redirect("/");
      throw new LoginFailedError("Failed to login", { cause: e });
    }
  };

  const onLoginSuccess = async (
    response: LoginResponse,
    onSuccess: () => void = (): Response => redirect("/folders"),
  ): Promise<void> => {
    const { accessToken } = await storeToken(response);
    const { data } = await axios.get<Customer[]>(`${API_URL}/customers?filter=user`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setCustomers(data);
    setLastLogin(new Date());
    onSuccess();
  };

  const storeRefreshToken = async (): Promise<Authorization> => {
    if (!authorization?.refreshToken) {
      throw Error(`No refresh token found`);
    }

    let response;
    const storedUser = await userManager.getUser();
    if (storedUser) {
      try {
        const user = await userManager.signinSilent();
        if (user) {
          response = {
            id_token: user.id_token!,
            access_token: user.access_token,
            refresh_token: user.refresh_token!,
          };
          return await storeToken(response);
        }
      } catch (e: any) {
        if (e && e.error === "invalid_grant") {
          logger.warn("Expired refresh token", e);
          await logout(); // We can't recover from this
          showToast({ message: `${t("LOGIN_SESSION_EXPIRED")}` });
        } else if (e && e.error === "invalid_request") {
          logger.error("Token is malformed, expired or otherwise invalid", e);
          await logout();
        } else {
          logger.error("Silent login failed", e);
        }
      }
    }
    return authorization;
  };

  const refreshAccessToken = async (): Promise<Authorization> => {
    // Force refreshing only once, we can have one single token active at a time.
    if (!isRefreshing) {
      isRefreshing = storeRefreshToken().finally(() => {
        isRefreshing = undefined;
      });
    }
    return isRefreshing;
  };

  const logout = async (): Promise<void> => {
    const idToken = authorization?.idToken;
    localStorage.removeItem("authorization");
    setAuthorization({});
    setCustomerId(undefined);
    setCustomerName(undefined);
    setCustomers(undefined);
    destroy && (await destroy());
    if (!Capacitor.isNativePlatform()) {
      await userManager.removeUser();
      await userManager.signoutRedirect({ id_token_hint: authorization.idToken });
    } else {
      await userManager.removeUser();
      await GenericOAuth2.logout(oauth2Options);
      if (idToken) {
        try {
          // Same workaround as in old app, because the capacitor plugin doesn’t bother to kill our session
          await fetch(`${AUTH_BASE_URL}/oauth2/sessions/logout?id_token_hint=${idToken}`, {
            method: "GET",
            mode: "no-cors",
          });
        } catch (e) {
          logger.warn("Couldn't logout with URL", e);
        }
      }
    }
    posthog.reset();
  };

  return {
    loggingIn: loggingIn?.current,
    isLoading,
    customerId: directUrlAuth?.customerId || customerId,
    customerName,
    customers,
    setCustomers,
    authorization: directUrlAuth ?? authorization,
    setWorkspace,
    lastLogin,
    refreshAccessToken,
    login,
    logout,
    handleLoginCallback,
    authorizeDirectUrl,
    deauthorizeDirectUrl,
    hasCustomerAccess: !customers || customers.length !== 0,
    impersonatedUser,
    setImpersonatedUser,
    username: directUrlAuth?.username || authorization?.username,
    authorizePreview,
  };
};

const oauth2Options: OAuth2AuthenticateOptions = {
  authorizationBaseUrl: `${AUTH_BASE_URL}/oauth2/auth`,
  accessTokenEndpoint: `${AUTH_BASE_URL}/oauth2/token`,
  logoutUrl: `${AUTH_BASE_URL}/oauth2/logout`,
  scope: "openid offline",
  appId: AUTH_APP_ID,
  responseType: "code",
  pkceEnabled: true,
  additionalParameters: {
    audience: "moreapp-hasura",
    brandingKey: branding.key,
  },
  android: {
    redirectUrl: AUTH_REDIRECT_URL_ANDROID,
  },
  ios: {
    pkceEnabled: true, // workaround for https://github.com/moberwasserlechner/capacitor-oauth2/issues/111
    redirectUrl: AUTH_REDIRECT_URL_IOS,
  },
};

let isRefreshing: Promise<Authorization> | undefined;
export const userManager = new UserManager({
  authority: AUTH_BASE_URL,
  client_id: AUTH_APP_ID,
  redirect_uri: `${window.location.origin}/login/callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  response_type: "code",
  scope: "openid offline",
  automaticSilentRenew: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    audience: "moreapp-hasura",
    brandingKey: branding.key,
  },
  monitorSession: true, // Old default
});

export default useAuth;
