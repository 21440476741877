import useSubmissionCollection from "./useSubmissionCollection";
import { useEffect, useState } from "react";
import { isNil } from "lodash-es";
import type { SubmissionDocument } from "../utils/databaseUtil";

const useRxSubmission = (id: string | null): SubmissionDocument | undefined => {
  const [submission, setSubmission] = useState<SubmissionDocument>();
  const submissionCollection = useSubmissionCollection();
  useEffect(() => {
    if (isNil(id) || isNil(submissionCollection)) {
      return;
    }
    const subscription = submissionCollection.findOne(id).$.subscribe((submission) => {
      if (isNil(submission)) {
        setSubmission(undefined);
      } else {
        setSubmission(submission);
      }
    });
    return (): void => {
      subscription?.unsubscribe();
    };
  }, [submissionCollection, id]);

  return submission;
};

export default useRxSubmission;
