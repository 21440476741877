import type { FC } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import type { ObjectSchema } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Content from "../../components/Content";
import useUser from "../../hooks/useUser";
import BlockLoader from "../../components/BlockLoader";
import type { MoreAppError } from "../../context/MoreAppContext";
import FormSubmitErrorMessage from "../../components/FormSubmitErrorMessage";
import { setFieldErrors } from "../../utils/formUtil";
import type { UserProfileUpdate } from "../../hooks/useUserProfile";
import useUserProfile from "../../hooks/useUserProfile";
import Page from "../../components/Page";
import useDeviceOrientation from "../../hooks/useDeviceOrientation";
import { Text } from "../../storybook/components/Text/Text";
import { Header } from "../../storybook/components/Header/Header";
import { Message } from "../../storybook/components/Message/Message";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { TextButton } from "../../storybook/components/TextButton/TextButton";
import branding from "../../utils/brandingUtil";

const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const mutation = useUserProfile();
  const { landscapeIndent } = useDeviceOrientation(true);

  const schema = yup.object({
    firstName: yup.string().required(t("VALIDATION_REQUIRED")),
    lastName: yup.string().required(t("VALIDATION_REQUIRED")),
  }) as ObjectSchema<UserProfileUpdate>;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setError,
  } = useForm<UserProfileUpdate>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (user.data) {
      reset({ ...user.data.settings });
    }
  }, [user.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data: UserProfileUpdate): Promise<void> => {
    await mutation.mutateAsync({ ...data }).catch((e: MoreAppError) => {
      if (e.data?.fieldErrors) {
        setFieldErrors(e.data.fieldErrors, setError);
      }
    });
  };

  return (
    <Page>
      <Header
        className="mt-safe"
        title={t("SETTINGS_PROFILE")}
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings") }}
      />
      <Content className={landscapeIndent}>
        <BlockLoader obj={user}>
          {(userData) => (
            <>
              <Text>{t("SETTINGS_PROFILE_DESCRIPTION")}</Text>
              {userData.externallyManaged && (
                <Message
                  type="warning"
                  text={t("SETTINGS_PROFILE_EXTERNALLY_MANAGED", { name: branding.name })}
                  className="my-4"
                />
              )}

              <form className="mt-6 flex flex-col space-y-6">
                {mutation.isSuccess && <Message type="success" text={t("SETTINGS_PROFILE_SUBMIT_SUCCESS")} />}
                {mutation.isError && (
                  <FormSubmitErrorMessage title="SETTINGS_PROFILE_SUBMIT_FAILURE" moreAppError={mutation.error} />
                )}

                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      type="text"
                      label={t("SETTINGS_PROFILE_FIRSTNAME")}
                      disabled={userData.externallyManaged}
                      errorMessage={errors.firstName?.message}
                      inputRef={field.ref}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      type="text"
                      label={t("SETTINGS_PROFILE_LASTNAME")}
                      disabled={userData.externallyManaged}
                      errorMessage={errors.lastName?.message}
                      inputRef={field.ref}
                    />
                  )}
                />

                <TextButton
                  block
                  disabled={userData.externallyManaged || isSubmitting}
                  loading={isSubmitting}
                  label={t("SETTINGS_PROFILE_SUBMIT")}
                  onClick={handleSubmit(onSubmit)}
                />
              </form>
            </>
          )}
        </BlockLoader>
      </Content>
    </Page>
  );
};

export default ProfilePage;
