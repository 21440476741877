import { isEmpty, isNil } from "lodash-es";
import type { FieldState, UniqueFieldId } from "../types/SubmissionState";
import type { FormField, WidgetProperties } from "../types/FormVersion";
import type { RememberedField, WidgetResult } from "../types/Field";
import {
  getCalculatedFieldId,
  getInitialValue,
  getWidgetProperties,
  validateFieldState,
  WidgetComponents,
} from "./formUtil";
import type { SubmissionFormData } from "../components/Form";
import type { SubformEntry, WidgetSubformProperties } from "../components/widgets/WidgetSubform";
import logger from "./logger";
import { t } from "i18next";
import type { WidgetPinProperties } from "../components/widgets/WidgetPin";
import { getRememberedFieldId, removeWidgetVersionNumber } from "./stringUtil";
import { nowToISO } from "./dateUtil";
import type { RunOptions } from "./FormEngine";

export const WIDGET_KEY_CALC = "com.moreapps.plugins:calculation";
export const WIDGET_KEY_TIME_CALC = "com.moreapps.plugins:timecalculation";
export const WIDGET_KEYS_CALC = [WIDGET_KEY_CALC, WIDGET_KEY_TIME_CALC];

export type BaseFieldState = FieldState<WidgetProperties, WidgetResult<unknown>>;

export type ParentEntryHash = string; // String containing parentId:entryId

export type ParentsAndErrors = {
  parentsToValidate: Set<string>;
  updateErrorState: { field: BaseFieldState; error: string | undefined }[];
};

export const findField = (fields: BaseFieldState[], uniqueFieldId: UniqueFieldId): BaseFieldState | undefined =>
  fields.find((field) => field.uniqueFieldId === uniqueFieldId);

export const findFieldEntry = (parentField: BaseFieldState, entryId: string): SubformEntry<unknown> | undefined =>
  parentField.value.entries?.find((field) => field.id === entryId);

export const getAllCalculationFieldStates = (fields: BaseFieldState[]): BaseFieldState[] =>
  fields.filter((field) => WIDGET_KEYS_CALC.includes(removeWidgetVersionNumber(field.widget)));

export const validateParents = (
  parentId: UniqueFieldId,
  entryId: string,
  fields: BaseFieldState[],
  writeMutation: (uniqueFieldId: UniqueFieldId) => void,
): void => {
  const entryHasError = fields.some((field) => field.value.meta.entryId === entryId && !isEmpty(field.error));
  const parentField = findField(fields, parentId);
  if (isNil(parentField)) {
    logger.error("Can't validate parent field, because parent field can't be found", null, {
      extra: { parentId, entryId },
    });
    return;
  }

  const entry = findFieldEntry(parentField, entryId);
  if (isNil(entry)) {
    logger.error("Can't validate parent field, because entry can't be found", null, {
      extra: { parentId, entryId },
    });
    return;
  }

  if (entry.meta.error !== entryHasError) {
    entry.meta.error = entryHasError;
    writeMutation(parentId);
  }

  const parentFieldHasError = validateFieldState(parentField); // validates sub- or pin-field
  if (parentField.error !== parentFieldHasError) {
    parentField.error = parentFieldHasError;
    writeMutation(parentId);
  }

  // continue up the chain until no parent has been found
  if (isNestedField(parentField)) {
    validateParents(parentField.value.meta.parentId!, parentField.value.meta.entryId!, fields, writeMutation);
  }
};

export const validateFileStatus = (field: BaseFieldState, treatPendingUploadsAsInvalid: boolean): string | undefined =>
  treatPendingUploadsAsInvalid && !isNil(field.value.meta.uploadStatus) && field.value.meta.uploadStatus !== "uploaded"
    ? t("VALIDATION_UPLOAD_IN_PROGRESS")
    : undefined;

export const getEntryDescriptionPath = (parentField: BaseFieldState, entry: SubformEntry<any>): string | undefined => {
  if (parentField.value.meta.widget === "subform") {
    const properties = parentField.properties as WidgetSubformProperties;
    return properties.itemHtml;
  }
  if (parentField.value.meta.widget === "pin") {
    const properties = parentField.properties as WidgetPinProperties;
    const { target } = entry.meta.scope;
    return properties.pins?.find((pin) => target === pin.target_form_id || target === pin.form?.uid)?.itemMarkup;
  }
  return undefined;
};

export const isNestedField = (field: BaseFieldState): boolean =>
  !isNil(field.value.meta.entryId) && !isNil(field.value.meta.parentId);

export const mapVisibleFieldStatesToDataName = (fields: BaseFieldState[], dataNames: string[]): SubmissionFormData =>
  fields.reduce((acc, field) => {
    const dataName = field.properties.data_name!;
    if (dataNames.includes(dataName) && field.visible) {
      acc[dataName] = field.value;
    }
    return acc;
  }, {} as SubmissionFormData);

export const mapFieldStatesToEntryId = (fields: BaseFieldState[]): Record<string, BaseFieldState[]> =>
  fields.reduce(
    (acc, field) => {
      if (!isNil(field.value.meta.entryId) && !isNil(field.properties.data_name)) {
        if (!acc[field.value.meta.entryId]) {
          acc[field.value.meta.entryId] = [];
        }
        acc[field.value.meta.entryId].push(field);
      }
      return acc;
    },
    {} as Record<string, BaseFieldState[]>,
  );

export const determineNecessaryParentValidation = (
  fields: BaseFieldState[],
  options: RunOptions,
  mutatedFieldIds: Set<UniqueFieldId>,
  skipValidation: Set<UniqueFieldId>,
): ParentsAndErrors =>
  fields
    .filter(
      (field) =>
        options.validateAll || (mutatedFieldIds.has(field.uniqueFieldId) && !skipValidation.has(field.uniqueFieldId)),
    )
    .filter((field) => isNil(options.entryId) || field.value.meta?.entryId === options.entryId)
    .reduce(
      (acc, field) => {
        const error =
          validateFieldState(field, options.validateAll) ||
          validateFileStatus(field, options.treatPendingUploadsAsInvalid);

        if (field.error !== error) {
          acc.updateErrorState.push({ field, error });
          if (isNestedField(field)) {
            acc.parentsToValidate.add(`${field.value.meta.parentId!}:${field.value.meta.entryId!}`);
          }
        }

        return acc;
      },
      { parentsToValidate: new Set<ParentEntryHash>(), updateErrorState: [] } as ParentsAndErrors,
    );

export const createNewFieldStatesForParent = (
  fields: FormField<any>[],
  rememberedFields: RememberedField[],
  deviceId: string,
  submissionId: string,
  formId: string,
  entryId: string,
  parentId: UniqueFieldId,
): BaseFieldState[] =>
  fields
    .filter(({ widget }) => Object.keys(WidgetComponents).includes(removeWidgetVersionNumber(widget)))
    .map((formField, order) => {
      // Replace default value with remembered data, if available
      const rememberedField = formField.properties.remember_input
        ? rememberedFields.find((f) => f.id === getRememberedFieldId(formField.uid, formId))
        : undefined;

      const uniqueFieldId = getCalculatedFieldId(formField.uid, submissionId, entryId, parentId);
      const value = getInitialValue(uniqueFieldId, formField, submissionId, rememberedField, entryId, parentId);
      value.updatedAt = nowToISO();
      value.meta.order = order;
      return {
        uid: formField.uid,
        uniqueFieldId,
        deviceId,
        value,
        visible: true,
        widget: formField.widget,
        properties: getWidgetProperties(formField.widget, formField.properties),
        deleted: false,
      };
    });
