import type { FC, PropsWithChildren } from "react";
import { createContext, useState } from "react";

type WorkerEntry = {
  key: string;
  worker: Worker;
};

type WorkerState = {
  getWorker: (key: string) => Worker | undefined;
  setWorker: (key: string, createWorker: () => Worker) => void;
};

export const WorkerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [workers, setWorkers] = useState<WorkerEntry[]>([]);
  const getWorker = (key: string): Worker | undefined => workers.find(findByKey(key))?.worker;
  const setWorker = (key: string, createWorker: () => Worker): void =>
    setWorkers((prev) =>
      // Create new worker only if there doesn't exist a worker for this key
      prev.find(findByKey(key))
        ? prev
        : [...prev.filter((entry) => entry.key !== key), { key, worker: createWorker() }],
    );

  return <WorkerContext.Provider value={{ getWorker, setWorker }}>{children}</WorkerContext.Provider>;
};

const findByKey =
  (key: string) =>
  (worker: WorkerEntry): boolean =>
    worker.key === key;

export const WorkerContext = createContext<WorkerState>({} as WorkerState);
