import { isNil, round } from "lodash-es";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import type { Widget } from "../../types/Widget";
import type { WidgetResult } from "../../types/Field";
import type { FormField } from "../../types/FormVersion";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { Label } from "../../storybook/components/Label/Label";
import { Message } from "../../storybook/components/Message/Message";
import WidgetContainer from "../WidgetContainer";
import { getFormVersionForField } from "../../utils/formUtil";
import useStateFormVersions from "../../state/useStateFormVersions";

export type WidgetTimeDifferenceProperties = {
  label_text: string;
  start: string;
  end: string;
};

const WidgetTimeDifference: Widget<WidgetTimeDifferenceProperties, WidgetResult<number>> = ({ fieldState }) => {
  const { t } = useTranslation();
  const formVersions = useStateFormVersions();
  const formVersion = getFormVersionForField(formVersions, fieldState.uid);
  const startField = findField(formVersion?.fields || [], fieldState.properties.start);
  const endField = findField(formVersion?.fields || [], fieldState.properties.end);

  if (!fieldState.properties.start || !fieldState.properties.end) {
    return (
      <div>
        <Label id={fieldState.uniqueFieldId} label={fieldState.properties.label_text} />
        <Message type="info" text={t("START_END_CONFIGURATION_REQUIRED")} />
      </div>
    );
  }

  return (
    <WidgetContainer fieldState={fieldState} name="TIME_DIFFERENCE_FIELD">
      {!isNil(fieldState.value.rawValue) ? (
        <TextInput
          name={fieldState.uniqueFieldId}
          type="text"
          label={fieldState.properties.label_text}
          value={formatAsString(fieldState.value.rawValue, t)}
          disabled
        />
      ) : (
        <div>
          <Label id={fieldState.uniqueFieldId} label={fieldState.properties.label_text} />
          <Message
            type="info"
            text={t("START_END_REQUIRED", {
              start: startField?.properties.label_text,
              end: endField?.properties.label_text,
            })}
          />
        </div>
      )}
    </WidgetContainer>
  );
};

WidgetTimeDifference.defaultValue = (_properties, defaultMeta): WidgetResult<number> => ({
  type: "number",
  meta: {
    widget: "timeDifference",
    ...defaultMeta,
  },
});

WidgetTimeDifference.validate = (): undefined => undefined;

export default WidgetTimeDifference;

const formatAsString = (inputHours: number, t: TFunction): string => {
  const milliseconds = round(inputHours * 60 * 60 * 1000, 0);
  const absMilliseconds = Math.abs(milliseconds);
  const minutes = Math.round((absMilliseconds / 6e4) % 60);
  const hours = Math.floor((absMilliseconds / 36e5) % 24);
  const days = Math.floor(absMilliseconds / 864e5);

  const array = [];
  days > 0 && array.push(days + t("DAY_ABBREVIATION"));
  hours > 0 && array.push(hours + t("HOUR_ABBREVIATION"));
  minutes > 0 && array.push(minutes + t("MINUTE_ABBREVIATION"));
  return round(milliseconds / 36e5, 2) + t("HOUR_ABBREVIATION") + (array.length !== 0 ? ` (${array.join(" ")})` : "");
};

const findField = (fields: FormField<any>[], uid: string): FormField<any> | undefined =>
  fields.find((field) => field.uid === uid);
