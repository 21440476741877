import type { FC, MutableRefObject } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import type { Submission } from "../types/Submission";
import { toHumanReadableDate, toUTCDate } from "../utils/dateUtil";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";

type SubmissionDetailProps = {
  submission: Submission;
};

const SubmissionDetail: FC<SubmissionDetailProps> = ({ submission }) => {
  const { t } = useTranslation();
  const initialFocus: MutableRefObject<any> = useRef(null);

  const getStatus = (): string => {
    if (submission.status === "draft") {
      return submission.viewedAt ? t("SUBMISSION_STATUS_IN_PROGRESS") : t("SUBMISSION_STATUS_OPEN");
    }
    return t("SUBMISSION_STATUS_DONE");
  };

  return (
    <div className="overflow-y-scroll px-6" ref={initialFocus}>
      <dl>
        <div className="my-6 flex justify-between">
          <dt>
            <Text color="medium">{t("TYPE")}</Text>
          </dt>
          <dd>
            <Text>{t("SUBMISSION_TYPE_DRAFT")}</Text>
          </dd>
        </div>
        <div className="my-6 flex justify-between">
          <dt>
            <Text color="medium">{t("STATUS")}</Text>
          </dt>
          <dd>
            <Text>{getStatus()}</Text>
          </dd>
        </div>
        {submission.task?.dueDate && (
          <div className="my-6 flex justify-between">
            <dt>
              <Text color="medium">{t("DUE_DATE")}</Text>
            </dt>
            <dd>
              <Text>{toHumanReadableDate(toUTCDate(submission.task.dueDate), true)}</Text>
            </dd>
          </div>
        )}
        {submission.task?.message && (
          <>
            <hr className="border-brand-100" />
            <div className="my-6">
              <Title as="h2" size="lg">
                {t("TASKS_MESSAGE")}
              </Title>
              <Text color="medium" className="mt-2">
                {submission.task?.message}
              </Text>
            </div>
          </>
        )}
        {submission.form.description && (
          <>
            <hr className="border-brand-100" />
            <div className="my-6">
              <Title as="h2" size="lg">
                {t("DESCRIPTION")}
              </Title>
              <Text color="medium" className="mt-2">
                {submission.form.description}
              </Text>
            </div>
          </>
        )}
      </dl>
    </div>
  );
};

export default SubmissionDetail;
