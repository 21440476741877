import { type FC, forwardRef, type MouseEventHandler, type ReactElement, useEffect } from "react";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { autoUpdate, flip, useFloating } from "@floating-ui/react-dom";
import { motion } from "framer-motion";
import { Icon, type IconName } from "../Icon/Icon";
import { isTouchCapable } from "../../../utils/deviceUtil";

export type MenuItemType = "default" | "destructive";

export type MenuItem = {
  label: string;
  icon?: IconName;
  iconAlign?: "left" | "right";
  type?: MenuItemType;
  disabled?: boolean;
  active?: boolean;
  hidden?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type DropdownMenuProps = {
  className?: string;
  menuButton: (open: boolean) => JSX.Element;
  menuAlignment?: "start" | "end";
  header?: ReactElement;
  items: MenuItem[];
  disabled?: boolean;
  hideOnScroll?: true | false | "auto";
};

const DropdownMenuItem: FC<MenuItem> = forwardRef<HTMLButtonElement, MenuItem>(
  ({ active, disabled, type = "default", label, icon, iconAlign = "left", onClick }, ref) => {
    const classes = classNames(
      "flex h-14 w-full select-none items-center p-4 text-left text-sm outline-none",
      icon && {
        "gap-x-2": iconAlign === "left",
        "flex-row-reverse justify-between": iconAlign === "right",
      },
      disabled && "cursor-not-allowed bg-gray-900/5 text-gray-900/20",
      !disabled && {
        "pointer:hover:bg-gray-50 active:text-white active:!bg-brand-900": true,
        "text-gray-700": type === "default",
        "text-red-700": type === "destructive",
        "bg-gray-50": active,
      },
    );

    // We added type="button" explicitly to suppress warning from Formik (who assumes every button is of type "submit")
    return (
      <button type="button" role="menuitem" className={classes} onClick={onClick} ref={ref}>
        {icon && <Icon name={icon} className="shrink-0" />}
        <span className="block truncate">{label}</span>
      </button>
    );
  },
);

const ScrollingClose: FC<{ open: boolean; close: () => void }> = ({ open, close }) => {
  useEffect(() => {
    if (open) {
      window.addEventListener("scroll", close, true);
    }

    return (): void => {
      window.removeEventListener("scroll", close, true);
    };
  }, [open]);

  return null;
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  header,
  menuButton,
  menuAlignment = "start",
  disabled = false,
  className,
  hideOnScroll = "auto",
}) => {
  const classes = classNames("relative inline-block", className);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: `bottom-${menuAlignment}`,
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const doHideOnScroll: boolean = hideOnScroll === "auto" ? isTouchCapable() : hideOnScroll;

  return disabled ? (
    menuButton(false)
  ) : (
    <Menu as="div" className={classes}>
      {({ open, close }) => (
        <>
          {doHideOnScroll && <ScrollingClose open={open} close={close} />}
          <Menu.Button ref={reference} as="div" className="appearance-none">
            {menuButton(open)}
          </Menu.Button>
          {open && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ ease: "easeOut" }}
              ref={floating}
              className="z-35 py-2"
              style={{
                position: strategy,
                top: y ?? "",
                left: x ?? "",
              }}
            >
              <Menu.Items className="w-48 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-lg outline-none ring-1 ring-gray-100">
                {header && (
                  <Menu.Item disabled>
                    <div className="w-full p-4">{header}</div>
                  </Menu.Item>
                )}
                {items
                  .filter((item) => !item.hidden)
                  .map((item, i) => (
                    <Menu.Item key={i} disabled={item.disabled}>
                      {({ active }) => <DropdownMenuItem {...item} active={active} />}
                    </Menu.Item>
                  ))}
              </Menu.Items>
            </motion.div>
          )}
        </>
      )}
    </Menu>
  );
};
