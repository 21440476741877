import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { LanguageCode, languageToISO } from "../utils/languageUtil";
import type { Form } from "../types/Folder";

export enum FormTag {
  generic = "GENERIC",
  construction = "CONSTRUCTION",
  hr = "HR",
  maintenance = "MAINTENANCE",
  logistics = "LOGISTICS",
  sales = "SALES",
  safety = "SAFETY",
  installation = "INSTALLATION",
  security = "SECURITY",
  real_estate = "REAL_ESTATE",
  healthcare = "HEALTHCARE",
  agriculture = "AGRICULTURE",
  industry = "INDUSTRY",
}

const useFormTemplates = (sector?: string, query?: string): UseQueryResult<Form[], Error> => {
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  const { i18n } = useTranslation();
  const language = languageToISO(i18n.resolvedLanguage ?? LanguageCode.EN);

  return useQuery<Form[], Error>({
    queryKey: ["formTemplates", customerId, language, sector, query],
    queryFn: async () => {
      const response = await client!.get(
        `/api/v1.0/forms/customer/${customerId}/templates?scope=GLOBAL&language=${language}&tags=${sector}&query=${query}`,
      );
      return response.data;
    },
    staleTime: 10000,
    enabled: !!customerId,
  });
};

export default useFormTemplates;
