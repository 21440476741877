import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import type { ActiveEntry, DeleteIntent } from "../../../types/Widget";
import Form from "../../Form";
import type { SubForm } from "../../../types/FormVersion";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { Modal } from "../../../storybook/components/Modal/Modal";
import type { UniqueFieldId } from "../../../types/SubmissionState";

type FormDrawerProps = {
  closeForm: (entryId: string, formVersion?: SubForm, discard?: boolean, enforceValidation?: boolean) => any;
  removeEntry: () => void;
  label: string;
  formVersion?: SubForm;
  parentId: UniqueFieldId;
  submissionId: string;
  activeEntry?: ActiveEntry;
  readOnly?: boolean;
};

const PinFormDrawer: FC<FormDrawerProps> = ({
  closeForm,
  removeEntry,
  label,
  formVersion,
  parentId,
  activeEntry,
  submissionId,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [removeEntryIntent, setRemoveEntryIntent] = useState<DeleteIntent>();

  return (
    <Drawer
      open={!isNil(activeEntry)}
      header={{
        kind: "extended",
        title: label,
        rightButton: !readOnly
          ? {
              kind: "textAndIcon",
              label: t("OPTIONS"),
              icon: "ChevronDownIcon",
              dropDownMenu: {
                items: [
                  {
                    label: t("SAVE"),
                    onClick: (): any => closeForm(activeEntry!.id, formVersion, false, true),
                  },
                  {
                    label: t("DELETE"),
                    type: "destructive",
                    onClick: (): void => setRemoveEntryIntent({ type: "DELETE_BUTTON", id: activeEntry!.id }),
                  },
                ],
              },
            }
          : undefined,
        leftButton: {
          kind: "text",
          label: activeEntry?.isHumanEdited ? t("SAVE_AND_CLOSE") : t("BACK"),
          onClick: () => {
            if (readOnly || activeEntry!.isHumanEdited) {
              closeForm(activeEntry!.id, formVersion);
              return;
            }

            // Remove pin when form is closed without touching anything
            removeEntry();
            closeForm(activeEntry!.id, formVersion, true);
          },
        },
      }}
      footer={
        !readOnly
          ? {
              kind: "default",
              primaryButton: {
                label: t("SAVE"),
                onClick: (): any => closeForm(activeEntry!.id, formVersion, false, true),
              },
            }
          : undefined
      }
      onClose={() => setRemoveEntryIntent(undefined)}
      className="flex flex-col gap-y-6 !px-4 py-6"
    >
      {formVersion && (
        <Form formVersion={formVersion} submissionId={submissionId} entryId={activeEntry!.id} parentId={parentId} />
      )}
      <Modal
        title={removeEntryIntent?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_TITLE") : t("PIN_DELETE_MODAL_TITLE")}
        content={{
          kind: "message",
          message:
            removeEntryIntent?.type === "BACK_BUTTON"
              ? t("DISCARD_MODAL_DESCRIPTION")
              : t("PIN_DELETE_MODAL_DESCRIPTION"),
        }}
        open={!isNil(removeEntryIntent)}
        onClose={() => setRemoveEntryIntent(undefined)}
        buttons={[
          { label: t("CANCEL"), onClick: (): void => setRemoveEntryIntent(undefined) },
          {
            label: t("DISCARD"),
            variant: "destructive",
            onClick: (): void => {
              removeEntry();
              closeForm(activeEntry!.id, formVersion, true);
              setRemoveEntryIntent(undefined);
            },
          },
        ]}
      />
    </Drawer>
  );
};

export default PinFormDrawer;
