import type { Widget } from "../../types/Widget";
import type { WidgetResult } from "../../types/Field";
import WidgetHidden from "./WidgetHidden";
import HtmlContent from "../HtmlContent";
import WidgetContainer from "../WidgetContainer";

export type WidgetRichTextProperties = {
  label_text: never;
  html_code?: string;
  markdown_code?: string;
};

const WidgetRichText: Widget<WidgetRichTextProperties, WidgetResult<void>> = ({ fieldState }) => {
  if (!fieldState.properties.html_code) {
    return <WidgetHidden />;
  }
  return (
    <WidgetContainer fieldState={fieldState} name="RICH_TEXT_FIELD">
      <HtmlContent html={fieldState.properties.html_code} />
    </WidgetContainer>
  );
};

WidgetRichText.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "richText",
    ...defaultMeta,
  },
});

WidgetRichText.validate = (): undefined => undefined;

export default WidgetRichText;
