import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { seconds } from "../utils/timeUtil";

const useDatasourceToken = (
  id: string,
  formId: string,
  formVersionId: string,
  allowedFields: string[],
): UseQueryResult<string, Error> => {
  const { customerId } = useAuth();
  const client = useMoreAppClient();

  return useQuery<string, Error>({
    queryKey: ["datasource", customerId, id, formId, formVersionId],
    queryFn: async () => {
      const { data } = await client!.post<string>(`/api/v1.0/customers/${customerId}/datasources/${id}/token`, {
        formId,
        formVersionId,
        allowedFields,
      });
      return data;
    },
    enabled: !!customerId && !!id,
    staleTime: seconds(0),
  });
};

export default useDatasourceToken;
