import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { Capacitor } from "@capacitor/core";
import type { AxiosError, AxiosInstance } from "axios";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { isNil } from "lodash-es";
import useAuth from "../hooks/useAuth";
import { getAuthHeader } from "../utils/authUtil";
import logger from "../utils/logger";
import useCurrentAppVersion from "../hooks/useCurrentAppVersion";
import type { TranslationKeys } from "../i18n";
import { seconds } from "../utils/timeUtil";

export type MoreAppError = {
  code?: TranslationKeys;
  message: string;
  status: number;
  data?: MoreAppErrorData;
  traceId?: string;
};

export type MoreAppSignupError = {
  response?: {
    data?: {
      details: {
        fieldErrors: Record<string, any>;
      };
    };
  };
};

export type MoreAppErrorData = {
  globalErrors?: GlobalError[];
  fieldErrors?: FieldError[];
  [key: string]: any;
};

export type GlobalError = {
  code: TranslationKeys;
  message: string;
};

export type FieldError = {
  code: TranslationKeys;
  message: string;
  path: string;
  value?: string;
};

const handleApiError = (e: AxiosError<any, MoreAppError>): { code: string; message: string; [key: string]: any } => {
  if (axios.isAxiosError(e)) {
    const { response } = e;
    if (!response) {
      return {
        code: "API_ERROR_NO_RESPONSE",
        message: "No response received from server",
      };
    }

    const { traceId } = response.data;

    return {
      code: response.data.code,
      status: response.status,
      data: response.data.data,
      message:
        response.data.message ||
        `Failed to fetch or mutate data. Please contact support if this problem persists. Mention the following ID: ${traceId}`,
      traceId,
    };
  }
  return {
    code: "API_ERROR_UNKNOWN_ERROR",
    message: "Unknown error",
  };
};

const MoreAppContext = createContext<AxiosInstance | undefined>(undefined);

const MoreAppClientProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const { refreshAccessToken, authorization, impersonatedUser } = useAuth();
  const { currentVersion } = useCurrentAppVersion();
  const versionOrCommitHash = Capacitor.isNativePlatform() ? currentVersion?.format() : process.env.VITE_COMMIT_SHA;

  const instance = useMemo(() => {
    const accessToken = authorization?.accessToken;
    const client = axios.create({
      baseURL: process.env.VITE_MOREAPP_API_BASE_URL,
      timeout: seconds(50),
      headers: {
        "X-More-Version": versionOrCommitHash || "unknown",
      },
    });

    createAuthRefreshInterceptor(
      client,
      async (failedRequest) => {
        const newToken = await refreshAccessToken();
        if (!newToken?.accessToken) {
          logger.error("Refreshing didn't result in new token");
          return;
        }

        failedRequest.response.config.headers.Authorization = `Bearer ${newToken.accessToken}`;
      },
      {
        pauseInstanceWhileRefreshing: true,
      },
    );

    client.interceptors.request.use(async (request) => {
      if (!request.headers) {
        return request;
      }
      if (authorization?.type === "token") {
        if (!isNil(accessToken)) {
          request.headers["X-More-Token"] = accessToken;
        }
        return request;
      }
      if (authorization?.type === "bearerToken") {
        const sessionToken = sessionStorage.getItem("token");
        if (!isNil(sessionToken)) {
          request.headers["X-More-Seal"] = `Bearer ${sessionToken}`;
        }
        return request;
      }
      request.headers.Authorization = await getAuthHeader();
      if (impersonatedUser) {
        request.headers["X-More-Consumer-Override"] = impersonatedUser;
      }
      return request;
    });

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        throw new Error(handleApiError(error).message);
      },
    );

    return client;
  }, [authorization?.type, impersonatedUser, currentVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  return <MoreAppContext.Provider value={instance}>{children}</MoreAppContext.Provider>;
};

const useMoreAppClient = (): AxiosInstance | undefined => useContext(MoreAppContext);

export { MoreAppClientProvider, useMoreAppClient };
