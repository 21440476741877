import type { FC, FocusEvent, Ref } from "react";
import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import type { NumberFormatValues, OnValueChange, SourceInfo } from "react-number-format";
import { NumericFormat } from "react-number-format";
import { isNil } from "lodash-es";
import { Label } from "../Label/Label";
import { Text } from "../Text/Text";
import { Feedback } from "../Feedback/Feedback";
import { Button } from "../Button/Button";
import { delimiter, thousandSeparator } from "../../../utils/formatter";
import { noop } from "../../../utils/noop";

export type NumberInputProps = {
  name: string;
  label?: string;
  ariaLabel?: string;
  inputMode?: "numeric" | "decimal";
  inputRef?: Ref<any>;
  value?: number | string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: OnValueChange;
  onBlur?: (e: FocusEvent<any>) => void;
  onFocus?: (e: FocusEvent<any>) => void;
  onPlusMinus?: () => void;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  clamp?: boolean;
  maxPrecision?: number;
  showThousandSeparator?: boolean;
  leftChild?: string;
  className?: string;
};

export const NumberInput: FC<NumberInputProps> = ({
  name,
  label,
  ariaLabel,
  inputMode,
  inputRef,
  value,
  description,
  placeholder,
  required = false,
  errorMessage,
  disabled = false,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  onClear = noop,
  onPlusMinus,
  showClearBtn = false,
  clearLabel,
  min,
  max,
  step = 1,
  clamp = false,
  maxPrecision,
  showThousandSeparator,
  leftChild,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);

  const wrapperClasses = classNames("group flex h-12 overflow-hidden rounded border", {
    "bg-white outline": focus,
    "bg-white": !errorMessage && !disabled,
    "border-gray-200": !errorMessage,
    "border-red-500 bg-red-50": errorMessage,
    "cursor-not-allowed bg-gray-100": disabled,
  });

  const inputClasses = classNames(
    "h-11.5 block w-full resize-none truncate bg-transparent p-3.5 text-sm text-gray-700 outline-none placeholder:text-gray-400",
    "border-none focus:ring-0", // TODO: DEV-6640 Remove after ring is not the default focus setting in tailwind config
  );

  const iconClasses = classNames(
    "flex w-12 shrink-0 items-center justify-center rounded-l border-r bg-gray-50 text-gray-500",
    leftChild && "overflow-hidden text-lg font-semibold tracking-wider select-none",
  );

  const handleBlur = (e: FocusEvent): void => {
    setFocus(false);
    onBlur(e);
  };
  const handleFocus = (e: FocusEvent): void => {
    setFocus(true);
    onFocus(e);
  };
  const isAllowed = ({ floatValue }: NumberFormatValues): boolean => {
    if (!floatValue) {
      return true;
    }

    // These checks will prevent users from typing more than the max or less than the min
    // Before, it would visually let you type bigger/smaller numbers while the effective value stayed capped
    if (max && floatValue > max) {
      onChange({ floatValue: max } as NumberFormatValues, {} as SourceInfo);
      return false;
    }

    if (min && floatValue < min) {
      onChange({ floatValue: min } as NumberFormatValues, {} as SourceInfo);
      return false;
    }

    return true;
  };

  return (
    <div className={classNames("relative", className)} {...props}>
      {label && (
        <Label
          htmlFor={name}
          label={label}
          required={required}
          showClearBtn={showClearBtn}
          onClear={onClear}
          clearLabel={clearLabel}
        />
      )}
      <div className={wrapperClasses}>
        {leftChild && (
          <div data-testid="number-left-child" className={iconClasses}>
            {leftChild}
          </div>
        )}
        <div className="flex h-12 w-full flex-row-reverse">
          <NumericFormat
            id={name}
            aria-label={ariaLabel}
            name={name}
            className={inputClasses}
            type="text"
            decimalScale={maxPrecision}
            fixedDecimalScale={!isNil(maxPrecision)}
            allowedDecimalSeparators={[".", ","]}
            inputMode={inputMode}
            disabled={disabled}
            decimalSeparator={delimiter}
            thousandSeparator={showThousandSeparator ? thousandSeparator : undefined}
            min={min}
            max={max}
            step={step}
            valueIsNumericString
            value={value}
            placeholder={placeholder}
            onValueChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            isAllowed={clamp ? isAllowed : undefined}
            autoComplete="off"
            getInputRef={inputRef}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          />
          {!disabled && onPlusMinus && (
            <Button
              onClick={onPlusMinus}
              aria-label={t("SWITCH_PLUS_MINUS")}
              size="md"
              className="m-1 min-w-10"
              icon="PlusMinusIcon"
            />
          )}
        </div>
      </div>
      {errorMessage && <Feedback status="error" message={errorMessage} />}
      {description && !errorMessage && (
        <Text className="mt-1" size="sm" color="medium">
          {description}
        </Text>
      )}
    </div>
  );
};
