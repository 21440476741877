import type { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import Img from "./Img";
import useResource from "../hooks/useResource";
import { Spinner } from "../storybook/components/Spinner/Spinner";

type ResourceImageProps = {
  className?: string;
  resourceId: string;
  style?: CSSProperties;
  onLoad?: () => void;
};

const ResourceImage: FC<ResourceImageProps> = ({ className, resourceId, style, onLoad }) => {
  const { resource, isLoadingResource, errorResource } = useResource(resourceId);
  const { t } = useTranslation();

  if (isLoadingResource) {
    return <Spinner className="mx-auto mt-4" />;
  }

  if (errorResource || isNil(resource) || resource.dataUrl?.length === 0) {
    return <Img className="mx-auto h-20" src="/assets/image_error.svg" alt={t("IMAGE_FAILED_TO_LOAD_ALT")} />;
  }

  return (
    <Img
      className={className}
      src={resource.dataUrl}
      fallbackSrc="/assets/image_error.svg"
      fallbackClassName="h-20"
      style={style}
      alt={t("USER_SUPPLIED_IMAGE")}
      onLoad={onLoad}
    />
  );
};
export default ResourceImage;
