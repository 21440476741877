import type { FC } from "react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { DrawerContext } from "../context/DrawerContext";
import logger from "../utils/logger";
import useBarcodeScanner from "../hooks/useBarcodeScanner";
import { noop } from "../utils/noop";

const closePath = "/folders";
const homePaths = ["/tasks", "/drafts", "/sent"];

const AndroidBackButtonListener: FC<any> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { closeLast } = useContext(DrawerContext);
  const [goNavigate, setGoNavigate] = useState<boolean>(false);
  const { isScanning, stopScan } = useBarcodeScanner();

  useEffect(() => {
    if (goNavigate) {
      setGoNavigate(false);
      if (isScanning) {
        stopScan().catch((e) => logger.error("Couldn't stop barcode scan", e));
      } else if (!closeLast()) {
        if (closePath === pathname) {
          App.exitApp().catch((e) => logger.error("Couldn't close application", e));
        } else if (homePaths.includes(pathname)) {
          navigate("/folders");
        } else {
          navigate(-1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goNavigate, isScanning]);

  useEffect(() => {
    if (Capacitor.getPlatform() !== "android") {
      return noop;
    }
    const listener = App.addListener("backButton", () => setGoNavigate(true));
    return (): void => {
      listener?.then((it) => it.remove().catch((e) => logger.error("Couldn't remove backlistener", e)));
    };
  }, []);

  return null;
};

export default AndroidBackButtonListener;
