import type { FC, PropsWithChildren } from "react";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";

type EmptyContentProps = {
  title: string;
  description: string;
  imgSrc?: string;
  imgAlt?: string;
};

const EmptyContent: FC<PropsWithChildren<EmptyContentProps>> = ({ title, description, imgSrc, imgAlt, children }) => (
  <div className="mx-auto mt-12 flex max-w-xs flex-col items-center justify-center gap-y-2 text-center">
    {imgSrc && <img className="mx-auto mb-2 rounded-2xl" src={imgSrc} alt={imgAlt} />}
    <Title as="h2" size="lg">
      {title}
    </Title>
    <Text color="medium">{description}</Text>
    {children}
  </div>
);

export default EmptyContent;
