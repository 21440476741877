import useFolders from "./useFolders";
import { useMemo } from "react";
import type { Form } from "../types/Folder";
import { isUsable } from "../utils/formUtil";

export const useLocalForms = (): Form[] => {
  const { data: folders } = useFolders();

  return useMemo(
    () =>
      (folders || [])
        .filter((folder) => folder.status !== "HIDDEN")
        .flatMap((folder) => folder.forms)
        .filter(isUsable),
    [folders],
  );
};
