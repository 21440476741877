import type { AuthenticationInfo, GrantPermission } from "../hooks/useAuthenticationInfo";

export const canCreateForms = (customerId: number, authInfo?: AuthenticationInfo): boolean =>
  !!authInfo?.permissions.find(
    (grant) => grant.resourceType === "CUSTOMER" && grant.customerId === customerId && isFormManager(grant),
  );

export const isAdmin = (authInfo?: AuthenticationInfo): boolean =>
  !!authInfo?.permissions.find(
    (grant) =>
      (grant.resourceType === "CUSTOMER" || grant.resourceType === "SYSTEM") && grant.permissions.includes("ADMIN"),
  );

const isFormManager = (grant: GrantPermission): boolean =>
  grant.permissions.includes("MANAGE_FORMS") ||
  (grant.permissions.includes("CREATE_FOLDER") && grant.permissions.includes("CREATE_FORM"));
