import { useEffect } from "react";
import type { Submission } from "../types/Submission";
import { noop } from "../utils/noop";

export const useSubmissionPageTitle = (submission?: Submission): void => {
  useEffect(() => {
    if (!submission) {
      return noop;
    }
    const appName = process.env.VITE_BRANDING_NAME;
    document.title = getSubmissionTitle(submission, appName);
    return (): void => {
      // Ensure we don't keep the submission title when the page is unloaded.
      document.title = appName ?? "";
    };
  }, [submission]);
};

const getSubmissionTitle = (submission: Submission, appName?: string): string =>
  appName ? `${submission.form.name} | ${appName}` : submission.form.name;
