import type { FC, PropsWithChildren } from "react";
import { createContext, useEffect, useMemo, useState } from "react";
import { useRxData } from "rxdb-hooks";
import { useTranslation } from "react-i18next";
import { cloneDeep, isNil, merge } from "lodash-es";
import type { DeepReadonlyObject } from "rxdb";
import { useDebounceValue } from "usehooks-ts";
import type { FileResult } from "../types/Widget";
import { validateUpload } from "../utils/validationUtil";
import useDeviceInfo from "../hooks/useDeviceInfo";
import type { UploadResult } from "../hooks/useFileHandler";
import useFileHandler from "../hooks/useFileHandler";
import type { Field, UploadStatus } from "../types/Field";
import logger from "../utils/logger";
import useDatabase from "../hooks/useDatabase";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { compress, decompress } from "../utils/compressUtil";
import useAuth from "../hooks/useAuth";
import { getWidgetOnUploadCompleteByWidgetKey } from "../utils/formUtil";
import useSubmissionCollection from "../hooks/useSubmissionCollection";
import { nowToISO } from "../utils/dateUtil";

export type UploadManagerState = {
  isUploading: boolean;
  isActive: boolean;
  current?: UploadAttempt;
};

export type UploadAttempt = {
  fieldId: string;
  submissionId: string;
  count: number;
  uploadPercentage: number;
  abortController: AbortController;
} & FileResult;

export const UploadManagerContext = createContext<UploadManagerState>({
  isUploading: false,
  isActive: false,
});

const MAX_NUMBER_OF_RETRIES = 10;
const BACKOFF_BASE_TIMEOUT = 10_000;

type UploadManagerProviderState = {
  maxNumberOfRetries?: number;
  backoffBaseTimeout?: number;
};

type HandleUploadResultReturn = {
  data: FileResult & unknown;
  uploadStatus: UploadStatus;
  error: string | undefined;
};

export const UploadManagerProvider: FC<PropsWithChildren<UploadManagerProviderState>> = ({
  children,
  maxNumberOfRetries = MAX_NUMBER_OF_RETRIES,
  backoffBaseTimeout = BACKOFF_BASE_TIMEOUT,
}) => {
  const { isOnline } = useOnlineStatus();
  const { isLoading: isLoadingAuth } = useAuth();
  const { isInitiallySynced } = useDatabase();
  // Debounce as lean way to prevent uploading straight away before fully synced
  const [isActive] = useDebounceValue(!isLoadingAuth && isOnline && isInitiallySynced, 5000);
  const canUpload = isActive && isOnline;
  const { id: deviceId } = useDeviceInfo();
  const { uploadLocalFile } = useFileHandler();
  const { t } = useTranslation();
  const [pending, setPending] = useState<UploadAttempt[]>([]);
  const [current, setCurrent] = useState<UploadAttempt>();
  const [backoffs, setBackoffs] = useState<string[]>([]);
  const submissionCollection = useSubmissionCollection();
  const { result: fields } = useRxData<Field>("fields", (fieldsCollection) =>
    fieldsCollection
      .find()
      .where("type")
      .equals("file")
      .where("deviceId")
      .equals(deviceId)
      .or([{ uploadStatus: "failed" }, { uploadStatus: "uploading" }])
      .where("data")
      .ne(null),
  );
  // TODO: verify that it will never allow uploading fields created on another device, if upload is pending.
  //    could that be a reason for some "stuck in queue" situations?

  useEffect(() => {
    if (!canUpload) {
      return;
    }

    fields
      .filter((field) => !backoffs.includes(field.id))
      .forEach(async (field) => {
        const fileResult = getFileResult(field);
        const attempt: UploadAttempt = {
          ...fileResult,
          fieldId: field.id,
          submissionId: field.submissionId,
          count: 0,
          uploadPercentage: 0,
          abortController: new AbortController(),
        };
        setPending((prev) => (prev.map((a) => a.id).includes(attempt.id) ? prev : [...prev, attempt]));
      });
  }, [canUpload, fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!current && pending.length > 0) {
      const next = pending[0];
      setCurrent(next);
      setPending((prev) => prev.splice(1));
    }
  }, [current?.id, pending]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!current || !canUpload || !submissionCollection) {
      return;
    }

    const upload = async (): Promise<void> => {
      const field = fields.find((f) => f.id === current.fieldId);

      if (!field) {
        setCurrent(undefined); // field has been cleared in the meantime
        return;
      }

      // Casting it to a JSON object as deep cloning the data later throws RxDB errors
      const fileResult = getFileResult(field.toJSON());

      if (fileResult.id !== current.id) {
        setCurrent(undefined); // field has been replaced by another file in the meantime (which is also queued)
        return;
      }

      if (current.uploadPercentage > 0) {
        return; // We're already uploading. This can happen if you have flaky internet, or go offline/online again
      }

      const uploadResult = await uploadLocalFile(
        current.id,
        current.submissionId,
        current.name,
        current.extension,
        ({ loaded, total = 1 }) => {
          setCurrent({ ...current, uploadPercentage: (loaded / total) * 100 });
        },
        current.abortController.signal,
      );

      // Every other status than failed won't be re-queued
      if (uploadResult.uploadStatus !== "failed") {
        const result = await handleUploadResult(field.widget, fileResult, uploadResult, field.submissionId);
        await field.incrementalPatch({
          updatedAt: nowToISO(),
          data: field.compressed ? compress(result.data) : result.data,
          deviceId,
          error: result.error,
          uploadStatus: result.uploadStatus,
        });
        setCurrent(undefined);
        return;
      }

      // If max number of retries is exceeded, don't re-queue
      if (current.count + 1 >= maxNumberOfRetries) {
        const data: FileResult = {
          id: current.id,
          name: current.name,
          extension: current.extension,
        };
        const mergedFileResult = merge({}, fileResult, data);
        await field.incrementalPatch({
          updatedAt: nowToISO(),
          data: field.compressed ? compress(mergedFileResult) : mergedFileResult,
          deviceId,
          error: t("VALIDATION_UPLOAD_ERROR"),
          uploadStatus: "error",
        });
        setCurrent(undefined);
        return;
      }

      // Requeue with backoff
      setBackoffs((prev) => [...prev, current.id]);
      setTimeout(
        () => {
          if (!field.deleted) {
            setPending((prev) => [...prev, { ...current, count: current.count + 1 }]);
          }
          setBackoffs((prev) => prev.filter((id) => id !== current.id));
        },
        backoffBaseTimeout * 2 ** current.count,
      );

      // Allow next upload
      setCurrent(undefined);
    };
    upload().catch((e) => logger.warn("Could not upload file", e)); // We will retry on failure
  }, [current?.id, canUpload]); // eslint-disable-line react-hooks/exhaustive-deps -- prevent re-run when current's content changes (like upload progress)

  const handleUploadResult = async (
    widgetKey: string,
    fileResult: FileResult,
    uploadResult: UploadResult,
    submissionId: string,
  ): Promise<HandleUploadResultReturn> => {
    const mergedFileResult = merge(cloneDeep(fileResult), uploadResult.fileResult);
    const { uploadStatus } = uploadResult;

    if (uploadStatus === "uploaded") {
      try {
        const finalWidgetData = await handleAfterUpload(widgetKey, mergedFileResult, submissionId);
        return {
          data: finalWidgetData,
          uploadStatus,
          error: undefined,
        };
      } catch (err) {
        logger.error("Handling after upload went wrong", err);
        return {
          data: mergedFileResult,
          uploadStatus: "error",
          error: validateUpload(mergedFileResult, "error", t),
        };
      }
    }

    return {
      data: mergedFileResult,
      uploadStatus,
      error: validateUpload(mergedFileResult, uploadStatus, t),
    };
  };

  const handleAfterUpload = async (
    widgetKey: string,
    fieldData: FileResult & unknown,
    submissionId: string,
  ): Promise<FileResult & unknown> => {
    const widgetAfterUpload = getWidgetOnUploadCompleteByWidgetKey(widgetKey);
    const submission = await submissionCollection?.findOne().where("id").eq(submissionId).exec();

    if (isNil(widgetAfterUpload) || isNil(submission?.submittedAt)) {
      return fieldData;
    }

    return widgetAfterUpload(fieldData);
  };

  const getFileResult = (field: DeepReadonlyObject<Field>): FileResult =>
    (field.compressed ? decompress(field.data as string) : field.data) as FileResult;

  const contextValue = useMemo(() => ({ isUploading: !!current, current, isActive }), [current, isActive]);
  return <UploadManagerContext.Provider value={contextValue}>{children}</UploadManagerContext.Provider>;
};
