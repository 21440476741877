import NearestColor from "nearest-color";
import { isNil } from "lodash-es";
import type { HexColor, Shade } from "./colors";
import { colors } from "./colors";

export type ThemeName =
  | "Default"
  | "Blue"
  | "Green"
  | "Purple"
  | "Red"
  | "Brown"
  | "Pink"
  | "Gray"
  // Legacy colors, can be removed when DB migration has been done
  | "Yellow"
  | "Teal"
  | "Gold"
  | "Dark"
  | "Mauve"
  | "White";
export type CustomThemeName = ThemeName | string;
type ThemeColors = "brand" | "blue" | "green" | "purple" | "red" | "orange" | "pink" | "gray";

export type Theme = {
  color: HexColor;
  bgColor: `bg-${string}`;
  fill: Record<Extract<Shade, 200 | 300 | 500>, string>;
};

const generateTheme = (tailwindColor: ThemeColors, shade: Shade = 700): Theme => ({
  color: colors[tailwindColor][shade],
  bgColor: `bg-${tailwindColor}-${shade}`,
  fill: {
    200: `fill-${tailwindColor}-200`,
    300: `fill-${tailwindColor}-300`,
    500: `fill-${tailwindColor}-500`,
  },
});

export const Themes: Record<ThemeName, Theme> = {
  Default: {
    // This is a dirty hack to fix loading brandingUtil too soon without certain env vars being present
    color: process.env.VITE_BRANDING_COLOR as HexColor,
    bgColor: "bg-branding",
    // These are the same for all brandings
    fill: {
      200: "fill-brand-200",
      300: "fill-brand-300",
      500: "fill-brand-500",
    },
  },
  Blue: generateTheme("blue"),
  Green: generateTheme("green"),
  Purple: generateTheme("purple"),
  Red: generateTheme("red"),
  Brown: generateTheme("orange", 800),
  Pink: generateTheme("pink"),
  Gray: generateTheme("gray"),
  // Legacy colors, can be removed when DB migration has been done
  Yellow: generateTheme("orange", 800),
  Teal: generateTheme("green"),
  Gold: generateTheme("orange", 800),
  Dark: generateTheme("blue"),
  Mauve: generateTheme("green"),
  White: generateTheme("orange", 800),
} as const;

export const THEMES: Record<string, Theme> = {
  "5ee1f55f54e4e995cc284b74": Themes.Default,
  "5ee1f55f54e4e995cc284b75": Themes.Green,
  "5ee1f55f54e4e995cc284b79": Themes.Purple,
  "5ee1f55f54e4e995cc284b77": Themes.Blue,
  "5ee1f55f54e4e995cc284b81": Themes.Brown,
  "5ee1f55f54e4e995cc284b83": Themes.Gray,
  "5ee1f55f54e4e995cc284b82": Themes.Pink,
  "5ee1f55f54e4e995cc284b78": Themes.Red,
};

const nearestColor = NearestColor.from(
  Object.entries(Themes)
    .map((theme) => ({ [theme[0]]: theme[1].color }))
    .reduce((prev, current) => ({ ...prev, ...current })),
);

export const resolveFormTheme = (id?: string, name?: CustomThemeName, hexColor?: string): Theme => {
  if (!isNil(id) && THEMES[id]) {
    return THEMES[id];
  }
  return resolveTheme(name, hexColor);
};

export const resolveTheme = (name?: CustomThemeName, hexColor?: string): Theme => {
  if (name === undefined) {
    return Themes.Default;
  }

  // Some form versions and submissions still have old custom theme names, so we need some fallback logic
  const isSystemTheme = Object.keys(Themes).includes(name);
  if (isSystemTheme) {
    return Themes[name as ThemeName];
  }

  if (hexColor !== undefined) {
    const color = nearestColor(hexColor);

    if (isNil(color)) {
      return Themes.Default;
    }

    return Themes[color.name as ThemeName];
  }

  return Themes.Default;
};
