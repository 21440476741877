import type { QueryConstructor } from "rxdb-hooks/dist/useRxData";
import type { AnyRxQuery, RxQueryResultDoc, UseRxQueryOptions } from "rxdb-hooks";
import { useRxData } from "rxdb-hooks";
import type { Override } from "rxdb/dist/types/types";
import type { Submission } from "../types/Submission";

const useLocalSubmissions = (
  queryConstructor: QueryConstructor<Submission> = (collection): AnyRxQuery<Submission> => collection.find(),
  options?: Override<UseRxQueryOptions, { json?: false }>,
): RxQueryResultDoc<Submission> => useRxData<Submission>("submissions", queryConstructor, options);

export default useLocalSubmissions;
