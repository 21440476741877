export type DataSourceEntry = {
  dataSourceId: string;
  id: string;
  data: Record<string, string>;
  version?: string; // Only relevant for Offline fallback
};

export type DataSource = {
  id: string;
  entries: DataSourceEntry[];
};

export type SearchType = "LIVE" | "LOCAL_FALLBACK" | "UNKNOWN";
export type DataSourceMeta = {
  id: string;
  customerId: number;
  version: string;
  updatedAt: string;
  searchType: SearchType;
};

export class DataSourceNotFoundError extends Error {}

export class DataSourceNoLocalFallbackError extends Error {}
