import { useEffect, useState } from "react";
import type { Submission } from "../types/Submission";
import useLocalSubmissions from "./useLocalSubmissions";

type WorkSpaceCounter = {
  hasNewTasks: boolean;
  draftCount: Record<number, number>;
  taskCount: Record<number, number>;
  newTaskCount: Record<number, number>;
  sendingCount: Record<number, number>;
};

const useWorkspaceCounter = (): WorkSpaceCounter => {
  const [hasNewTasks, setHasNewTasks] = useState(false);
  const [draftCount, setDraftCount] = useState<Record<number, number>>({});
  const [taskCount, setTaskCount] = useState<Record<number, number>>({});
  const [newTaskCount, setNewTaskCount] = useState<Record<number, number>>({});
  const [sendingCount, setSendingCount] = useState<Record<number, number>>({});
  const { result: submissions } = useLocalSubmissions();

  useEffect(() => {
    const tasks = submissions.filter((submission) => !!submission.task && submission.status === "draft");
    const newTasks = submissions.filter(
      (submission) => !!submission.task && !submission.viewedAt && submission.status === "draft",
    );
    const drafts = submissions.filter(
      (submission) => !submission.task && submission.status === "draft" && !submission.submittedAt,
    );
    const sending = submissions.filter((submission) => submission.submittedAt);

    setHasNewTasks(newTasks.length > 0);
    setTaskCount(getCount(tasks));
    setDraftCount(getCount(drafts));
    setNewTaskCount(getCount(newTasks));
    setSendingCount(getCount(sending));
  }, [submissions]);
  return {
    hasNewTasks,
    draftCount,
    taskCount,
    newTaskCount,
    sendingCount,
  };
};

const getCount = (documents: Submission[]): Record<number, number> =>
  documents.reduce(
    (acc, submission) => {
      if (!acc[submission.customerId]) {
        acc[submission.customerId] = 1;
      } else {
        acc[submission.customerId] += 1;
      }
      return acc;
    },
    {} as Record<number, number>,
  );

export default useWorkspaceCounter;
