import type { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import type { MoreAppError } from "../context/MoreAppContext";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Message } from "../storybook/components/Message/Message";

type BlockLoaderProps<T> = {
  obj: UseQueryResult<T, MoreAppError>;
  children: (data: T) => JSX.Element;
  emptyState?: JSX.Element;
  errorState?: JSX.Element;
};

// Currently only supports one object
const BlockLoader = <T,>({ obj, children, emptyState, errorState }: BlockLoaderProps<T>): JSX.Element => {
  const { t } = useTranslation();

  if (obj.isLoading) {
    return (
      <div>
        <Spinner className="m-auto mt-8" />
      </div>
    );
  }

  if (obj.isError) {
    return errorState || <Message type="error" text={obj.error.message} />;
  }

  if (!obj.data || (Array.isArray(obj.data) && obj.data.length === 0)) {
    return emptyState || <Message type="error" text={t("NO_ITEMS_FOUND")} />;
  }

  return <>{children(obj.data)}</>;
};

export default BlockLoader;
