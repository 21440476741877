import type { FC } from "react";
import { useRef } from "react";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import { useResizeObserver } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import type { Theme } from "../storybook/themes";
import { Themes } from "../storybook/themes";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Banner } from "../storybook/components/Banner/Banner";

type PageOutlineProps = {
  header: JSX.Element | undefined;
  content: JSX.Element | undefined;
  footer?: JSX.Element;
  hideBody?: boolean;
  theme?: Theme;
};

const PageOutline: FC<PageOutlineProps> = ({ header, content, footer, hideBody = false, theme = Themes.Default }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { height } = useResizeObserver({ ref });
  const { isOnline } = useOnlineStatus();
  const { t } = useTranslation();

  const showBanner = !isOnline;

  const roundedBannerCorners = (
    <div className="z-30 mx-auto -mt-36 flex h-8 w-full max-w-xl justify-between">
      <div
        className="size-6"
        style={{
          backgroundColor: theme.color,
          WebkitMaskImage: "radial-gradient(circle at bottom right, transparent 0, transparent 24px, black 24px)",
          maskImage: "radial-gradient(circle at bottom right, transparent 0, transparent 24px, black 24px)",
        }}
      />
      <div
        className="size-6"
        style={{
          backgroundColor: theme.color,
          WebkitMaskImage: "radial-gradient(circle at bottom left, transparent 0, transparent 24px, black 24px)",
          maskImage: "radial-gradient(circle at bottom left, transparent 0, transparent 24px, black 24xpx)",
        }}
      />
    </div>
  );

  const pageHeader = (
    <>
      <div ref={ref} className="sticky top-0 z-40 pt-safe" style={{ backgroundColor: theme.color }}>
        <div className="mx-auto flex max-w-xl items-center justify-center p-4">{header}</div>
      </div>
      {showBanner && (
        <div className="sticky z-30" style={{ top: `${height}px` }}>
          <Banner
            type="neutral"
            className="mx-auto h-8 max-w-xl"
            label={t("INTERNET_DISCONNECTED")}
            icon="StatusOfflineIcon"
          />
        </div>
      )}
    </>
  );

  const bodyClasses = classNames(
    "flex w-full max-w-xl grow flex-col rounded-t-3xl bg-white p-4 mobile:flex-none mobile:rounded-b-3xl mobile:border-2 mobile:border-gray-100 mobile:p-7",
    !footer && Capacitor.isNativePlatform() && "after:pb-safe",
    !showBanner && "-mt-36",
  );

  const pageBody = (
    <>
      {showBanner && roundedBannerCorners}
      <main className={bodyClasses}>{content}</main>
    </>
  );

  const footerClasses = classNames("max-w-xl", Capacitor.isNativePlatform() && "after:pb-safe");
  const pageFooter = footer && <div className={footerClasses}>{footer}</div>;

  return (
    <div className={hideBody ? "invisible" : "flex h-screen flex-col bg-gray-100"}>
      {pageHeader}
      <div className={classNames("flex grow flex-col items-center", !Capacitor.isNativePlatform() && "mobile:pb-2")}>
        <div className={classNames("h-36 w-full", showBanner && "-mt-8")} style={{ backgroundColor: theme.color }}>
          {showBanner && <div className="mx-auto h-full max-w-xl bg-gray-200" />}
        </div>
        {pageBody}
        {pageFooter}
      </div>
    </div>
  );
};

export default PageOutline;
