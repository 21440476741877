import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import useToasts from "../hooks/useToasts";
import { openAppListingInStore } from "../utils/versionUtil";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import useAppVersions from "../hooks/useAppVersions";
import { noopAsync } from "../utils/noop";

const VersionUpdateToast: FC = () => {
  const { showToast } = useToasts();
  const { isOutdated } = useAppVersions();
  const { t } = useTranslation();

  const isNative = Capacitor.isNativePlatform();

  useAsyncEffect(
    async () => {
      // Web doesn't have versions, so no need to show 'outdated' toast
      if (!isNative) {
        return;
      }

      if (isOutdated) {
        showToast({
          message: t("APP_VERSION_UPDATE_AVAILABLE"),
          button: {
            type: "text",
            label: t("APP_VERSION_UPDATE_ACTION"),
            onClick: async () => {
              await openAppListingInStore();
            },
          },
        });
      }
    },
    noopAsync,
    [isNative, isOutdated],
  );

  return null;
};

export default VersionUpdateToast;
