import type { FC } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import useOnlineStatus from "../hooks/useOnlineStatus";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import OfflinePage from "./errorpages/OfflinePage";
import LoadingPage from "./LoadingPage";
import CopySubmissionModal from "../components/CopySubmissionModal";
import useTheme from "../hooks/useTheme";
import useSentSubmission from "../hooks/useSentSubmission";
import FormContentWrapper from "../components/FormContentWrapper";
import StaticForm from "../components/StaticForm";
import useStatusBar from "../hooks/useStatusBar";
import { DropdownMenu } from "../storybook/components/DropdownMenu/DropdownMenu";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import { SubmissionStoreProvider } from "../context/SubmissionStoreContext";

const SentSubmissionPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, isNotFound, submission, fields, formData, formVersion } = useSentSubmission(id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme(formVersion);
  const { isOnline } = useOnlineStatus();
  const [showCopyModal, setCopyModal] = useState(false);
  useStatusBar(theme.color);
  useSubmissionPageTitle(submission);

  const goBack = (): void => {
    navigate.length > 1 ? navigate(-1) : navigate("/folders");
  };

  if (isLoading || !formData) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_SENT_SUBMISSION_FETCH", value: isLoading },
          { labelKey: "LOADING_ERROR_SENT_SUBMISSION_DEFAULT_VALUES", value: !formData },
        ]}
      />
    );
  }

  if (isNotFound || !submission || !fields || !formVersion) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  const header = (
    <div className="flex h-14 items-center justify-between">
      <IconAndTextButton
        icon="ChevronLeftIcon"
        variant="transparentWhite"
        size="md"
        onClick={goBack}
        label={t("BACK")}
      />
      <DropdownMenu
        menuButton={() => (
          <IconAndTextButton
            iconAlign="right"
            icon="ChevronDownIcon"
            variant="transparentWhite"
            label={t("OPTIONS")}
            size="md"
          />
        )}
        items={[{ label: t("COPY"), onClick: (): void => setCopyModal(true) }]}
      />
    </div>
  );

  return (
    <>
      <SubmissionStoreProvider>
        <FormContentWrapper submission={submission} theme={theme} header={header} isSubmitting={false}>
          <StaticForm submissionId={submission.id} formVersion={formVersion} data={formData} readOnly />
        </FormContentWrapper>
      </SubmissionStoreProvider>
      <CopySubmissionModal
        submission={submission}
        fields={fields}
        formVersion={formVersion}
        show={showCopyModal}
        setShow={setCopyModal}
      />
    </>
  );
};

export default SentSubmissionPage;
