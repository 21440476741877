import type { FC, PropsWithChildren } from "react";
import { createContext, useEffect, useState } from "react";
import type { ScreenOrientationResult } from "@capacitor/screen-orientation";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import logger from "../utils/logger";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { noop } from "../utils/noop";
import { has } from "lodash-es";

const IPHONE_SE_MODELS = [146, 128];

export const DeviceOrientationProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [shouldIndent, setShouldIndent] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const { platform, model } = useDeviceInfo();

  useEffect(() => {
    if (platform !== "ios") {
      return;
    }
    // Assumes every id newer than "iPhone10,6" has a notch (except se's but including iPhone x)
    // Ids: https://github.com/pluwen/apple-device-model-list
    const modelNumber = parseInt(model.replace("iPhone", "").replace(",", ""), 10);
    const hasNotch = (modelNumber >= 106 || modelNumber === 103) && !IPHONE_SE_MODELS.includes(modelNumber);
    setShouldIndent(hasNotch);
  }, [platform, model]);

  useEffectOnce(() => {
    const setLandscape = (value: string): void => {
      setIsLandscape(value.startsWith("landscape"));
    };

    // Don't attempt Capacitor functionality when unsupported
    if (isUnsupported) {
      return noop;
    }

    ScreenOrientation.orientation().then(({ type }) => setLandscape(type));
    const listener = ScreenOrientation.addListener("screenOrientationChange", ({ type }: ScreenOrientationResult) =>
      setLandscape(type),
    );

    return (): void => {
      listener.then((it) => it.remove().catch((e) => logger.error("Failed to remove screen orientation listener", e)));
    };
  });

  return (
    <DeviceOrientationContext.Provider value={shouldIndent && isLandscape}>
      {children}
    </DeviceOrientationContext.Provider>
  );
};

const isUnsupported = typeof screen === "undefined" || !has(screen, "orientation");

export const DeviceOrientationContext = createContext<boolean>({} as boolean);
