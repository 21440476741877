import type { ComponentType, FC, SVGProps } from "react";
import * as solid from "@heroicons/react/solid";
import * as outline from "@heroicons/react/outline";
import classNames from "classnames";
import * as customIcons from "./CustomIcons";
import { containsSizeOverride } from "../../utils/classHelper";

export type IconName = keyof typeof customIcons | keyof typeof solid | "SendIcon";
export type IconProps = {
  name: IconName;
  type?: "solid" | "outline";
  className?: string;
};

const defaultCustomProps = {
  "aria-hidden": true,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 24 24",
  stroke: "currentColor",
};

export const Icon: FC<IconProps> = ({ name, type = "solid", className }) => {
  const sizeOverride = containsSizeOverride(className);
  const classes = classNames({ "size-6": !sizeOverride }, className);

  if (name in customIcons) {
    const IconComponent = customIcons[name as keyof typeof customIcons] as ComponentType<SVGProps<SVGElement>>;
    return <IconComponent {...defaultCustomProps} className={classes} />;
  }

  if (name === "SendIcon") {
    const SendIcon = type === "solid" ? solid.PaperAirplaneIcon : outline.PaperAirplaneIcon;
    return <SendIcon className={`${classes} rotate-90`} />;
  }

  if (name in solid && type === "solid") {
    const HeroIcon = solid[name as keyof typeof solid];
    return <HeroIcon className={classes} />;
  }

  if (name in outline && type === "outline") {
    const HeroIcon = outline[name as keyof typeof outline];
    return <HeroIcon className={classes} />;
  }

  return null;
};
