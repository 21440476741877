import type { FC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Content from "../../components/Content";
import Page from "../../components/Page";
import useDeviceOrientation from "../../hooks/useDeviceOrientation";
import { changeLanguage, currentLanguage, SUPPORTED_LANGUAGES } from "../../utils/languageUtil";
import { Header } from "../../storybook/components/Header/Header";
import { Icon } from "../../storybook/components/Icon/Icon";

const LanguagePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { landscapeIndent } = useDeviceOrientation(true);

  return (
    <Page>
      <Header
        className="mt-safe"
        title={t("SETTINGS_LANGUAGE")}
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings") }}
      />
      <Content className={landscapeIndent}>
        <fieldset className="relative">
          <legend className="sr-only">{t("SETTINGS_LANGUAGE")}</legend>
          <div className="flex flex-col divide-y text-gray-700">
            {SUPPORTED_LANGUAGES.map(({ code, label }) => {
              const isSelected = code === currentLanguage();

              const labelClasses = classNames(
                "flex grow cursor-pointer justify-between py-3 peer-focus-visible:ring",
                isSelected && "font-semibold",
              );

              return (
                <div key={code} className="relative">
                  <input
                    id={code}
                    name="language"
                    type="radio"
                    checked={isSelected}
                    onChange={() => changeLanguage(code)}
                    className="peer absolute size-0 opacity-0"
                  />
                  <label className={labelClasses} htmlFor={code}>
                    {label}
                    {isSelected && <Icon name="CheckIcon" type="outline" className="text-brand-500" />}
                  </label>
                </div>
              );
            })}
          </div>
        </fieldset>
      </Content>
    </Page>
  );
};

export default LanguagePage;
