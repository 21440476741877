import { useEffect, useRef, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import type { FileResult, Widget } from "../../types/Widget";
import useClientRect from "../../hooks/useClientRect";
import type { UploadStatus, WidgetResult } from "../../types/Field";
import uuidv4 from "../../utils/uuid";
import { validateUpload } from "../../utils/validationUtil";
import useFileHandler from "../../hooks/useFileHandler";
import useDrawer from "../../hooks/useDrawer";
import type { FabricProps } from "../../utils/drawingUtil";
import { initializeEvents } from "../../utils/drawingUtil";
import logger from "../../utils/logger";
import { Label } from "../../storybook/components/Label/Label";
import { Text } from "../../storybook/components/Text/Text";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import WidgetContainer from "../WidgetContainer";
import useStateSubmissionId from "../../state/useStateSubmissionId";
import useFieldUploadState from "../../hooks/useFieldUploadState";
import { useFocusId } from "../../hooks/useFocusId";

export type WidgetSignatureProperties = {
  required: boolean;
  label_text: string;
  wide?: boolean;
  stroke_width?: number;
};

const WidgetSignature: Widget<WidgetSignatureProperties, WidgetResult<FileResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const submissionId = useStateSubmissionId();
  const [open, setOpen] = useDrawer(fieldState.uid);
  const [initialized, setInitialized] = useState(false);
  const [touched, setTouched] = useState(false);
  const { getFileUrl, storeFileUri } = useFileHandler();
  const [rect, wrapperRef] = useClientRect();
  const { editor, onReady } = useFabricJSEditor();
  const [signature, setSignature] = useState<string | undefined>(undefined);
  const { isUploading, uploadPercentage } = useFieldUploadState(fieldState);
  const onCloseFocusId = useFocusId(fieldState);

  const closeAndReset = (): void => {
    setOpen(false);
    setTouched(false);
  };

  const persistWithUploadStatus = (fileResult?: FileResult, uploadStatus?: UploadStatus): void => {
    setFieldState(fileResult, { uploadStatus });
  };

  const fabricProps = useRef<FabricProps>({
    lastPosX: 0,
    lastPosY: 0,
    isDragging: false,
    isDown: false,
    activeMode: "draw",
    color: "black",
    fabricObject: undefined,
  });

  useEffect(() => {
    if (editor && !initialized) {
      setInitialized(true);

      editor.canvas.discardActiveObject();
      editor.canvas.renderAll();
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush.width = fieldState.properties.stroke_width ?? 1;
      editor.canvas.on("mouse:up", () => setTouched(true));

      initializeEvents(editor.canvas, fabricProps.current, () => null);
    }
  }, [editor, initialized, fieldState.properties.stroke_width]);

  useEffect(() => {
    const loadSignature = async (): Promise<void> => {
      if (!fieldState.value.rawValue?.id) {
        setSignature(undefined);
        return;
      }
      const fileUrl = await getFileUrl(fieldState.value.rawValue, submissionId);
      setSignature(Capacitor.convertFileSrc(fileUrl!));
    };
    loadSignature().catch((e) => logger.error("Could not load signature", e));
  }, [fieldState.value.rawValue?.remoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const clear = async (): Promise<void> => {
    if (editor?.canvas) {
      editor.canvas.clear();
      setTouched(false);
      setSignature(undefined);
    }
  };

  const save = async (): Promise<void> => {
    if (!editor) {
      closeAndReset();
      return;
    }

    const dataURL = editor.canvas.toDataURL();
    const id = uuidv4();

    setSignature(dataURL); // render locally first

    const result = await storeFileUri(dataURL, submissionId, id, "signature.png");
    persistWithUploadStatus(result.fileResult, result.uploadStatus);

    closeAndReset();
  };

  const buttonLabel = !readOnly ? t("ADD_SIGNATURE") : t("NO_SIGNATURE_ADDED");

  const remove = async (): Promise<void> => {
    setSignature(undefined);
    persistWithUploadStatus(undefined, undefined);
  };

  return (
    <WidgetContainer fieldState={fieldState} name="SIGNATURE_FIELD">
      <Label
        buttonId={onCloseFocusId}
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        showClearBtn={!readOnly && !!fieldState.value.rawValue}
        clearLabel={t("CLEAR")}
        onClear={remove}
      />
      {fieldState.value.rawValue ? (
        <div className="relative rounded-lg border-2">
          <img src={signature} alt={t("SIGNATURE")} className="mx-auto h-40" />
          {isUploading && (
            <>
              <Text color="medium" size="2xs" weight="semibold" className="absolute left-6 top-6">
                {uploadPercentage.toFixed(0)}%
              </Text>
              <Spinner className="absolute left-4 top-4" />
            </>
          )}
        </div>
      ) : (
        <IconAndTextButton
          disabled={readOnly}
          label={buttonLabel}
          icon="PencilIcon"
          block
          onClick={() => setOpen(true)}
        />
      )}
      {fieldState.error && <Feedback status="error" message={fieldState.error} />}

      <Drawer
        onCloseFocusId={onCloseFocusId}
        open={open}
        header={{
          kind: "simple",
          title: t("SIGNATURE"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: closeAndReset,
          },
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("SAVE"),
            onClick: save,
            disabled: !touched,
            className: !touched ? "pointer-events-none" : "", // Necessary to not consume events necessary for FabricJS
          },
          secondaryButton: {
            label: t("CLEAR"),
            onClick: clear,
            disabled: !touched,
            className: !touched ? "pointer-events-none" : "", // Necessary to not consume events necessary for FabricJS
          },
        }}
        onClose={() => {
          try {
            editor?.canvas.clear();
          } catch (e) {
            logger.warn("Can't clear canvas, already gone or not initialized yet", e);
          } finally {
            closeAndReset();
          }
        }}
        className="bg-gray-100"
      >
        <div className="flex size-full items-center justify-center">
          <div
            className={
              fieldState.properties.wide
                ? "aspect-h-1 aspect-w-2 my-auto w-full rounded-lg border-2 bg-white"
                : "aspect-h-1 aspect-w-1 my-auto w-full rounded-lg border-2 bg-white"
            }
            ref={wrapperRef}
          >
            {rect && (
              <FabricJSCanvas
                onReady={(canvas) => {
                  onReady(canvas);
                  setInitialized(false);
                }}
              />
            )}
          </div>
        </div>
      </Drawer>
    </WidgetContainer>
  );
};

WidgetSignature.defaultValue = (_properties, defaultMeta): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "signature",
    ...defaultMeta,
  },
});

WidgetSignature.validate = (val, properties, t, meta): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

export default WidgetSignature;
