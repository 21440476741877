import type { FieldState, UniqueFieldId } from "../types/SubmissionState";
import { useSubmissionStore } from "../context/SubmissionStoreContext";
import type { WidgetProperties } from "../types/FormVersion";
import type { WidgetResult } from "../types/Field";

const useActionGetFieldStates = (): ((
  uniqueFieldIds: UniqueFieldId[],
) => FieldState<WidgetProperties, WidgetResult<unknown>>[]) => useSubmissionStore((store) => store.getFieldStates);

export default useActionGetFieldStates;
