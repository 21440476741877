import type { FC, MutableRefObject } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import type { SubmissionSummary } from "../types/Submission";
import { toHumanReadableDate } from "../utils/dateUtil";
import { locationToLatLng } from "../utils/locationUtil";
import Img from "./Img";
import { LOCATION_BASE_URL } from "../constants";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";

type SentSubmissionDetailProps = {
  submissionSummary?: SubmissionSummary;
};

const SentSubmissionDetail: FC<SentSubmissionDetailProps> = ({ submissionSummary }) => {
  const { t } = useTranslation();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const submitCoordinates = locationToLatLng(submissionSummary?.submitLocation);

  return (
    <div className="h-full overflow-y-scroll px-6" ref={initialFocus}>
      <dl>
        {submissionSummary?.formDescription && (
          <div className="my-6 flex justify-between">
            <dt className="flex-1">
              <Text color="medium">{t("FORM_DESCRIPTION")}</Text>
            </dt>
            <dd className="flex-1 text-right">
              <Text color="dark" className="text-ellipsis">
                {submissionSummary?.formDescription}
              </Text>
            </dd>
          </div>
        )}
        {submissionSummary?.description && (
          <div className="my-6 flex justify-between">
            <dt className="flex-1">
              <Text color="medium">{t("DESCRIPTION")}</Text>
            </dt>
            <dd className="flex-1 text-right">
              <Text color="dark" className="text-ellipsis">
                {submissionSummary.description}
              </Text>
            </dd>
          </div>
        )}
        <dt />
        <dd>
          <hr className="border-brand-100" />
        </dd>
        {submissionSummary?.registrationDate && (
          <div className="my-6 flex justify-between">
            <dt>
              <Text color="medium">{t("SENT_AT")}</Text>
            </dt>
            <dd>
              <Text>{toHumanReadableDate(new Date(submissionSummary.registrationDate), true)}</Text>
            </dd>
          </div>
        )}
        {submissionSummary?.submittedBy && (
          <div className="my-6 flex justify-between">
            <dt>
              <Text color="medium">{t("SENT_BY")}</Text>
            </dt>
            <dd>
              <Text color="dark">{submissionSummary.submittedBy}</Text>
            </dd>
          </div>
        )}
        {submissionSummary?.serialNumber && (
          <div className="my-6 flex justify-between">
            <dt>
              <Text color="medium">{t("SERIAL_NUMBER")}</Text>
            </dt>
            <dd>
              <Text color="dark">{submissionSummary?.serialNumber}</Text>
            </dd>
          </div>
        )}
        <div className="my-6 flex justify-between">
          <dt>
            <Text color="medium">{t("STATUS")}</Text>
          </dt>
          <dd>
            <Text color="dark">
              {submissionSummary?.status === "PROCESSED" ? t("SUBMISSION_STATUS_DONE") : t("UNPROCESSED_SUBMISSION")}
            </Text>
          </dd>
        </div>
        {submitCoordinates && (
          <div className="shrink-0">
            <dt>
              <hr className="border-brand-100" />
            </dt>
            <dd>
              <div className="my-6 h-40">
                <Title size="lg" as="h2">
                  {t("LOCATION")}
                </Title>
                <a href={`geo:${submitCoordinates.lat},${submitCoordinates.lng}`}>
                  <Img
                    className="my-6 cursor-pointer rounded"
                    src={`${LOCATION_BASE_URL}/v2/location/static?height=${256}&width=${544}&latitude=${submitCoordinates.lat}&longitude=${submitCoordinates.lng}`}
                    alt={t("LOCATION")}
                  />
                </a>
              </div>
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default SentSubmissionDetail;
