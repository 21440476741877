import { isEmpty, isFinite, isNil, round } from "lodash-es";
import type { CalculationOperator, CurrencyResult } from "../types/Widget";
import type { WidgetResult } from "../types/Field";
import type { FormField, WidgetProperties } from "../types/FormVersion";
import type { WidgetCalculationProperties } from "../components/widgets/WidgetCalculation";
import { getTermData, getTermFields } from "./termUtil";
import { getSafeNumberCalculation } from "./numberUtil";
import { toIsoCurrency } from "./currencyUtil";
import type { FieldState } from "../types/SubmissionState";

export type FormFieldTerm = {
  field: FieldState<WidgetProperties, WidgetResult<unknown>>;
  term: string;
};
export const operatorFunctions: Record<CalculationOperator, (numbers: number[]) => number> = {
  addition: (numbers) => numbers.reduce((a, b) => a + b),
  subtract: (numbers) => numbers.reduce((a, b) => a - b),
  multiply: (numbers) => numbers.reduce((a, b) => a * b),
  divide: (numbers) => numbers.reduce((a, b) => a / b),
  average: (numbers) => numbers.reduce((a, b) => a + b, 0) / numbers.length,
  minimum: (numbers) => Math.min(...numbers),
  maximum: (numbers) => Math.max(...numbers),
};

export const getCalculationResult = (
  formField: FormField<WidgetCalculationProperties>,
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  entryId?: string,
): CurrencyResult | undefined => {
  const {
    precision,
    initial_value: initialValue,
    operator,
    isPrice,
    no_value_on_missing_term: requiredToFillInTerms,
    skip_hidden_terms: skipHiddenTerms,
  } = formField.properties;
  // FIXME The old platform will clear the entire property instead of initializing with an empty array.
  // The new platform should always have an empty terms array, and validate on whether it's filled or not
  const terms = formField.properties.terms ?? [];

  const nestedTermFields = getTermFields(fields, terms, entryId);
  // Sort so we get the same order of the selected terms
  nestedTermFields.sort((a, b) => terms.indexOf(a.term) - terms.indexOf(b.term));

  // Filter invisible fields when configured to do so
  const termFields = skipHiddenTerms ? nestedTermFields?.filter((x) => x.field.visible) : nestedTermFields;

  if (requiredToFillInTerms && isValueInvalid(termFields)) {
    return undefined;
  }
  const numbers = termFields
    .filter((f) => formField.uid !== f.term)
    .flatMap((f) => getCalculationData(f))
    .filter((x) => x || x === 0) as number[];

  if (!isNil(initialValue)) {
    numbers.unshift(initialValue);
  }
  if (numbers.length === 0) {
    return undefined;
  }

  const calculated = operatorFunctions[operator](numbers.filter((x) => isFinite(x)));
  if (!isFinite(calculated)) {
    return undefined;
  }
  const finalPrecision = !isNil(precision) ? precision : 2;
  const finalValue = round(calculated, finalPrecision);
  return {
    currency: isPrice ? toIsoCurrency(formField.properties.currency) : undefined,
    value: finalValue,
    precision: finalPrecision,
  };
};

const isValueInvalid = (termFields: FormFieldTerm[]): boolean =>
  termFields
    ?.map((field) => getCalculationData(field))
    .some((result) => result.some((term) => term === undefined || Number.isNaN(term)));

const getCalculationData = (formFieldTerm: FormFieldTerm): (number | undefined)[] => {
  const termData = getTermData(formFieldTerm);

  if (isEmpty(termData)) {
    return [undefined];
  }
  return termData.map((data) => {
    if (typeof data === "string" && data.length > 0) {
      return getSafeNumberCalculation(data) ?? undefined;
    }
    if (typeof data === "number") {
      return Number(data);
    }
    return undefined;
  });
};
