import useForm from "./useForm";
import type { UseFormVersionResult } from "./useFormVersion";
import useFormVersion from "./useFormVersion";

const usePublishedFormVersion = (customerId?: number, id?: string): UseFormVersionResult => {
  const { data: form } = useForm(id);
  return useFormVersion(form?.publishedVersion.formVersion, customerId, form?.id);
};

export default usePublishedFormVersion;
