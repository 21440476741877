import { Title } from "../storybook/components/Title/Title";
import { t } from "i18next";
import { useStorageEstimates } from "../hooks/useStorageEstimates";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import type { FC } from "react";
import { Text } from "../storybook/components/Text/Text";
import InlineLoader from "./InlineLoader";
import type { UseQueryResult } from "@tanstack/react-query";
import type { MoreAppError } from "../context/MoreAppContext";
import { formatAsSize } from "../utils/stringUtil";

const StorageInformation: FC = () => {
  const {
    storageManagerEstimate,
    datasourceSizeEstimate,
    collectionSizeEstimates,
    isLoading: storageEstimatesLoading,
  } = useStorageEstimates();
  return (
    <div className="mb-11 mt-4">
      <div className="flex justify-between">
        <Title as="h2" size="lg" className={"my-1 block"}>
          {t("SETTINGS_SUPPORT_STORAGE_TITLE")}
        </Title>
        {storageEstimatesLoading && <Spinner className={"size-4 self-center"} />}
      </div>
      <dl>
        {collectionSizeEstimates.map((estimate, i) => {
          const a = ["FIELDS", "SUBMISSIONS", "FORMVERSIONS"];
          return (
            <div className="flex justify-between border-b py-3">
              <dt className="flex-1">
                <Text color="medium">{t(`SETTINGS_SUPPORT_STORAGE_${a[i]}`)}</Text>
              </dt>
              <dd className="flex-1 text-right">
                <Text>
                  <InlineLoader
                    obj={estimate}
                    field="total"
                    format={(() => formatAsSize(estimate.data?.total ?? 0)) as unknown as undefined}
                  />
                </Text>
              </dd>
            </div>
          );
        })}
        <div className="flex justify-between border-b py-3">
          <dt className="flex-1">
            <Text color="medium">{t("SETTINGS_SUPPORT_STORAGE_DATASOURCES")}</Text>
          </dt>
          <dd className="flex-1 text-right">
            <Text>
              <InlineLoader
                obj={datasourceSizeEstimate}
                field="total"
                format={(() => formatAsSize(datasourceSizeEstimate.data?.total ?? 0)) as unknown as undefined}
              />
            </Text>
          </dd>
        </div>
        {storageManagerEstimate.data?.quota && (
          <div className="flex justify-between border-b py-3">
            <dt className="flex-1">
              <Text color="medium">{t("SETTINGS_SUPPORT_STORAGE_AVAILABLE")}</Text>
            </dt>
            <dd className="flex-1 text-right">
              <Text>
                <InlineLoader
                  obj={storageManagerEstimate as UseQueryResult<Storage, MoreAppError>}
                  field="quota"
                  format={(() => formatAsSize(storageManagerEstimate.data?.quota ?? 0)) as unknown as undefined}
                />
              </Text>
            </dd>
          </div>
        )}{" "}
      </dl>
    </div>
  );
};

export default StorageInformation;
