import type { FC } from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { LocationResult } from "../../../types/Widget";
import { getLocation } from "../../../utils/locationUtil";
import usePermissions from "../../../hooks/usePermissions";
import logger from "../../../utils/logger";
import InsufficientPermissionsModal from "../../InsufficientPermissionsModal";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { Segment } from "../../../storybook/components/Segment/Segment";
import { TextInput } from "../../../storybook/components/TextInput/TextInput";
import { NumberInput } from "../../../storybook/components/NumberInput/NumberInput";
import { IconAndTextButton } from "../../../storybook/components/IconAndTextButton/IconAndTextButton";

export type AddressDrawerProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  onSubmit: (val: LocationResult) => Promise<void>;
  defaultValues?: LocationResult;
};

const AddressDrawer: FC<AddressDrawerProps> = ({ open, setOpen, onSubmit, defaultValues }) => {
  const { t } = useTranslation();
  const [inputMode, setInputMode] = useState<"address" | "coords">("address");
  const [isRetrievingLocation, setRetrievingLocation] = useState(false);
  const { checkLocationPermissions } = usePermissions();
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { handleSubmit, control, reset, setValue } = useForm<LocationResult>({
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues ?? {});
  }, [defaultValues, reset]);

  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: t("EDIT_LOCATION"),
        button: {
          kind: "icon",
          icon: "XIcon",
          onClick: () => setOpen(false),
        },
        content: (
          <Segment
            className="mx-5 mb-4"
            selected={inputMode}
            onSelect={() => setInputMode(inputMode === "address" ? "coords" : "address")}
            options={[
              { id: "address", label: t("ADDRESS") },
              { id: "coords", label: t("COORDINATES") },
            ]}
          />
        ),
      }}
      onClose={() => setOpen(false)}
      footer={{
        kind: "default",
        primaryButton: {
          label: t("SAVE"),
          onClick: handleSubmit(onSubmit, (errors) => {
            // If error occurs on the coordinates page, navigate to the coordinates page
            if (errors.coordinates?.latitude || errors.coordinates?.longitude) {
              setInputMode("coords");
            } else {
              setInputMode("address");
            }
          }),
        },
      }}
    >
      <form>
        <div className={classNames({ hidden: inputMode === "coords" }, "space-y-6 pb-6 pt-4")}>
          <Controller
            name="location.descriptiveName"
            control={control}
            render={({ field: f }) => (
              <TextInput {...f} name="descriptiveName" type="text" label={t("LOCATION_DESCRIPTIVE_NAME")} />
            )}
          />
          <Controller
            name="location.postcode"
            control={control}
            render={({ field: f }) => (
              <TextInput
                {...f}
                name="postcode"
                type="text"
                label={t("POSTCODE")}
                onChange={(e) => setValue("location.postcode", e.target.value.toUpperCase())}
              />
            )}
          />

          <Controller
            name="location.houseNumber"
            control={control}
            render={({ field: f }) => <TextInput {...f} type="text" label={t("HOUSE_NUMBER")} />}
          />
          <Controller
            name="location.road"
            control={control}
            render={({ field: f }) => <TextInput {...f} type="text" label={t("STREET")} />}
          />
          <Controller
            name="location.city"
            control={control}
            render={({ field: f }) => <TextInput {...f} type="text" label={t("CITY")} />}
          />
        </div>
        <div className={classNames({ hidden: inputMode === "address" }, "space-y-6 pb-6 pt-4")}>
          <Controller
            name="coordinates.latitude"
            control={control}
            rules={{ required: true, min: -180, max: 180 }}
            render={({ field: f, fieldState: { error } }) => (
              <NumberInput
                {...f}
                inputMode="decimal"
                label={t("LATITUDE")}
                required
                errorMessage={
                  error && (error.type === "required" ? t("VALIDATION_REQUIRED") : t("VALIDATION_COORDINATE"))
                }
                onChange={(e) => setValue("coordinates.latitude", e.floatValue ? e.floatValue : 0)}
              />
            )}
          />
          <Controller
            name="coordinates.longitude"
            control={control}
            rules={{ required: true, min: -180, max: 180 }}
            render={({ field: f, fieldState: { error } }) => (
              <NumberInput
                {...f}
                inputMode="decimal"
                label={t("LONGITUDE")}
                required
                onChange={(e) => setValue("coordinates.latitude", e.floatValue ? e.floatValue : 0)}
                errorMessage={
                  error && (error.type === "required" ? t("VALIDATION_REQUIRED") : t("VALIDATION_COORDINATE"))
                }
              />
            )}
          />
          <IconAndTextButton
            block
            icon="CurrentLocationIcon"
            loading={isRetrievingLocation}
            label={t("FETCH_LOCATION")}
            onClick={async () => {
              try {
                const permissions = await checkLocationPermissions();
                if (permissions.coarseLocation === "denied" && permissions.location === "denied") {
                  setPermissionsModal(true);
                  return;
                }
                setRetrievingLocation(true);
                const location = await getLocation();
                setValue("coordinates.longitude", location.longitude);
                setValue("coordinates.latitude", location.latitude);
              } catch (e) {
                logger.error("Could not retrieve location", e);
              } finally {
                setRetrievingLocation(false);
              }
            }}
          />
        </div>
      </form>
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </Drawer>
  );
};

export default AddressDrawer;
