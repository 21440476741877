import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as yup from "yup";
import type { ObjectSchema } from "yup";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { TFunction } from "i18next";
import useUser from "../../hooks/useUser";
import BlockLoader from "../../components/BlockLoader";
import Content from "../../components/Content";
import type { MoreAppError } from "../../context/MoreAppContext";
import { useMoreAppClient } from "../../context/MoreAppContext";
import Page from "../../components/Page";
import logger from "../../utils/logger";
import useDeviceOrientation from "../../hooks/useDeviceOrientation";
import { Text } from "../../storybook/components/Text/Text";
import { Header } from "../../storybook/components/Header/Header";
import { Message } from "../../storybook/components/Message/Message";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { TextButton } from "../../storybook/components/TextButton/TextButton";
import branding from "../../utils/brandingUtil";

type UserPasswordChange = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const passwordChangeSchema = (t: TFunction): ObjectSchema<UserPasswordChange> =>
  yup.object({
    currentPassword: yup.string().required(t("VALIDATION_REQUIRED")),
    newPassword: yup
      .string()
      .min(8, t("VALIDATION_TEXT_MIN", { min: 8 }))
      .max(255, t("VALIDATION_TEXT_MAX", { max: 255 }))
      .required(t("VALIDATION_REQUIRED")),
    confirmNewPassword: yup
      .string()
      .required(t("VALIDATION_REQUIRED"))
      .is([yup.ref("newPassword")], t("SETTINGS_PASSWORD_PASSWORDS_DO_NOT_MATCH")),
  }) as ObjectSchema<UserPasswordChange>;

const PasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useMoreAppClient();
  const user = useUser();
  const mutation = useMutation<any, MoreAppError, UserPasswordChange>({
    mutationFn: async (data) =>
      client!.post(`/api/v1.0/users/change-password`, {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      }),
  });
  const { landscapeIndent } = useDeviceOrientation(true);

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control,
    reset,
  } = useForm<UserPasswordChange>({
    resolver: yupResolver(passwordChangeSchema(t)),
    mode: "all",
  });

  const onSubmit = async (data: UserPasswordChange): Promise<void> => {
    try {
      await mutation.mutateAsync({ ...data });
      reset();
    } catch (e) {
      logger.log("Couldn't change password", e);
    }
  };

  return (
    <Page>
      <Header
        className="mt-safe"
        title={t("SETTINGS_PASSWORD")}
        backBtn={{ ariaLabel: t("BACK"), onClick: () => navigate("/settings") }}
      />
      <Content className={landscapeIndent}>
        <BlockLoader obj={user}>
          {(userData) => (
            <>
              <Text>{t("SETTINGS_PASSWORD_DESCRIPTION")}</Text>
              {userData.externallyManaged && (
                <Message
                  type="warning"
                  text={t("SETTINGS_PASSWORD_EXTERNALLY_MANAGED", { name: branding.name })}
                  className="my-4"
                />
              )}

              <form className="mt-6 flex flex-col space-y-6">
                {mutation.isSuccess && <Message type="success" text={t("SETTINGS_PASSWORD_SUBMIT_SUCCESS")} />}
                {mutation.isError && (
                  <Message type="error" text={t(mutation.error.code ?? "SETTINGS_PASSWORD_SUBMIT_FAILURE")} />
                )}
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      type="password"
                      label={t("SETTINGS_PASSWORD_CURRENT_PASSWORD")}
                      disabled={userData.externallyManaged}
                      autoComplete="current-password"
                      errorMessage={errors.currentPassword?.message}
                      inputRef={field.ref}
                    />
                  )}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      type="password"
                      label={t("SETTINGS_PASSWORD_NEW_PASSWORD")}
                      disabled={userData.externallyManaged}
                      autoComplete="new-password"
                      errorMessage={errors.newPassword?.message}
                      inputRef={field.ref}
                    />
                  )}
                />
                <Controller
                  name="confirmNewPassword"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      type="password"
                      label={t("SETTINGS_PASSWORD_CONFIRM_NEW_PASSWORD")}
                      disabled={userData.externallyManaged}
                      autoComplete="new-password"
                      errorMessage={errors.confirmNewPassword?.message}
                      inputRef={field.ref}
                    />
                  )}
                />
                <TextButton
                  block
                  disabled={userData.externallyManaged || !isValid || isSubmitting}
                  loading={isSubmitting}
                  label={t("SETTINGS_PASSWORD_SUBMIT")}
                  onClick={handleSubmit(onSubmit)}
                />
              </form>
            </>
          )}
        </BlockLoader>
      </Content>
    </Page>
  );
};

export default PasswordPage;
