import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import type { FormVersion } from "../types/FormVersion";

const useFormVersionTemplate = (id?: string): UseQueryResult<FormVersion, Error> => {
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  return useQuery<FormVersion, Error>({
    queryKey: ["formVersionTemplate", id],
    queryFn: async () => {
      const response = await client!.get(`/api/v1.0/forms/customer/${customerId}/forms/templates/${id}`);
      return response.data;
    },
    enabled: !!id && !!customerId,
    staleTime: Infinity,
  });
};

export default useFormVersionTemplate;
