import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { DataSourceMeta } from "../../../types/Datasource";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { toHumanReadableDate } from "../../../utils/dateUtil";
import { Banner } from "../../../storybook/components/Banner/Banner";
import useDataSourceCollection from "../../../hooks/useDataSourceCollection";

type DatasourceStatusBannerProps = {
  dataSourceId: string;
};
const DatasourceStatusBanner: FC<DatasourceStatusBannerProps> = ({ dataSourceId }) => {
  const { t } = useTranslation();
  const datasourceCollection = useDataSourceCollection();
  const onlineStatus = useOnlineStatus();

  const [datasourceMeta, setDatasourceMeta] = useState<DataSourceMeta | null>(null);
  useEffect(() => {
    if (!dataSourceId || !datasourceCollection) {
      return;
    }
    datasourceCollection
      .findOne()
      .where("id")
      .eq(dataSourceId)
      .exec()
      .then((x) => {
        setDatasourceMeta(x);
      });
  }, [dataSourceId, datasourceCollection]);

  if (onlineStatus.isOnline || !datasourceMeta?.updatedAt) {
    return null;
  }

  return (
    <Banner
      type="update"
      label={t("DATASOURCE_LAST_UPDATE", {
        date: toHumanReadableDate(datasourceMeta.updatedAt, true),
      })}
    />
  );
};

export default DatasourceStatusBanner;
