import type { FC } from "react";
import classNames from "classnames";
import type { IconName } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";

export type PillProps = {
  value: string;
  appearance?: "default" | "warning" | "error" | "neutral" | "retro" | "new" | "brand" | "clear";
  compact?: boolean;
  className?: string;
  icon?: IconName;
  iconAlign?: "left" | "right";
  avatar?: string;
};

export const Pill: FC<PillProps> = ({
  value,
  appearance = "default",
  compact = false,
  icon,
  iconAlign = "left",
  avatar,
  className,
}) => {
  const classes = classNames(
    "inline-flex max-w-full shrink-0 items-center rounded-full text-xs",
    compact ? "px-1" : "px-1.5 py-0.5",
    avatar && "gap-x-1.5",
    {
      "bg-green-50 text-green-500": appearance === "default",
      "bg-orange-100 text-orange-500": appearance === "warning",
      "bg-red-100 text-red-500": appearance === "error",
      "bg-gray-100 text-gray-500": appearance === "neutral",
      "bg-teal-100 text-teal-500": appearance === "retro",
      "bg-blue-100 text-blue-600": appearance === "new",
      "bg-brand-50 text-brand-500": appearance === "brand",
      "bg-transparent text-brand-500": appearance === "clear",
    },
    className,
  );

  const innerClasses = classNames(
    "inline-flex min-w-0 items-center",
    icon && {
      "gap-x-1.5": true,
      "flex-row-reverse space-x-reverse": iconAlign === "right" || avatar,
    },
  );

  return (
    <div className={classes}>
      {avatar && (
        <img src={avatar} alt="Avatar" className={classNames("rounded-full", compact ? "size-3" : "size-4")} />
      )}
      <div className={innerClasses}>
        {icon && <Icon className={classNames("shrink-0", compact ? "size-3" : "size-4")} name={icon} />}
        <span className="truncate">{value}</span>
      </div>
    </div>
  );
};
