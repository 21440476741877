import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import { isNil, startCase } from "lodash-es";
import { useTranslation } from "react-i18next";
import type { DataSourceEntry } from "../../../types/Datasource";
import useDrawer from "../../../hooks/useDrawer";
import type { DatasourceColor } from "../WidgetSearch";
import { getSearchEntryHumanText } from "../../../utils/stringUtil";
import SearchValue from "./SearchValue";
import EmptyContent from "../../EmptyContent";
import { Text } from "../../../storybook/components/Text/Text";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { Indicator } from "../../../storybook/components/Indicator/Indicator";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import useDatasourceToken from "../../../hooks/useDatasourceToken";
import useStateFormId from "../../../state/useStateFormId";
import useStateFormVersionId from "../../../state/useStateFormVersionId";
import useDatasourceSearch from "../../../hooks/useDatasourceSearch";
import classNames from "classnames";

type OnlineSearchContentProps = {
  dataSourceId: string;
  query: string;
  entryFields: string[];
  colors?: DatasourceColor[];
  filterQueries?: string[];
  isExactQuery: boolean;
  onChange: (value: DataSourceEntry) => void;
  onInit: (value: boolean) => void;
};

const OnlineSearchContent: FC<OnlineSearchContentProps> = ({
  dataSourceId,
  query,
  entryFields,
  colors,
  filterQueries,
  isExactQuery,
  onChange,
  onInit,
}) => {
  const { t } = useTranslation();
  const formId = useStateFormId();
  const formVersionId = useStateFormVersionId();
  const { data: token, isLoading, isError } = useDatasourceToken(dataSourceId, formId, formVersionId, entryFields);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [activeOpen, setActiveOpen] = useDrawer("search-active");
  const [activeItem, setActiveItem] = useState<DataSourceEntry | undefined>();
  const { data, isInitialLoading, error, fetchNextPage, hasNextPage } = useDatasourceSearch(
    dataSourceId,
    query,
    isExactQuery,
    filterQueries,
    token,
  );

  useEffect(() => virtuoso.current?.scrollToIndex(0), [query, isExactQuery]);

  useEffect(() => onInit(!isLoading && !isError), [isLoading, isError]);

  if (isError) {
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_TITLE")}
        description={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_DESCRIPTION")}
      />
    );
  }

  if (isLoading || isInitialLoading || !filterQueries) {
    return <Spinner className="mx-auto mt-4" />;
  }

  if (!isNil(error)) {
    if (error.response?.data.code === "index_not_ready") {
      return (
        <EmptyContent
          imgSrc="assets/errorpages/expired.svg"
          title={t("DATASOURCE_INDEX_NOT_READY_TITLE")}
          description={t("DATASOURCE_INDEX_NOT_READY_DESCRIPTION")}
        />
      );
    }
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_MALFUNCTION_TITLE")}
        description={t("DATASOURCE_MALFUNCTION_DESCRIPTION")}
      />
    );
  }

  return (
    <>
      <Virtuoso
        className="mx-4"
        ref={virtuoso}
        data={data?.pages.flatMap((page) => page.results)}
        endReached={() => fetchNextPage()}
        increaseViewportBy={100}
        itemContent={(_index, hit) => {
          const content = getSearchEntryHumanText(hit, entryFields);
          const indicator = colors?.find(
            (color) =>
              color.query &&
              color.color &&
              Object.values(hit).find(
                (column) => column?.toString()?.toLowerCase().trim() === color.query.toLowerCase().trim(),
              ),
          );
          return (
            <div key={hit.id} className="relative flex grow items-center border-b">
              <button
                className="flex w-full min-w-0 items-center rounded py-3 text-left outline-none ring-inset focus-visible:ring"
                onClick={() => onChange({ dataSourceId, id: hit.id, data: hit })}
              >
                <Text className="grow">{content}</Text>
                {indicator && (
                  <Indicator
                    size="lg"
                    color={indicator.color}
                    ariaLabel={t("HIGHLIGHTED_DATASOURCE_ENTRY_INDICATOR")}
                  />
                )}
              </button>
              <IconButton
                className="absolute shrink-0 ring-inset"
                aria-label={t("SEARCH_VIEW_INFO")}
                icon="InformationCircleIcon"
                iconType="outline"
                variant="transparentBrand"
                data-testid="detail-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveItem({ dataSourceId, id: hit.id, data: hit });
                  setActiveOpen(true);
                }}
              />
            </div>
          );
        }}
        components={{ Footer: () => <Spinner className={classNames("mx-auto my-2", { hidden: !hasNextPage })} /> }}
      />
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getSearchEntryHumanText(activeItem ? activeItem.data : {}, entryFields),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveOpen(false),
          },
        }}
        onClose={() => setActiveOpen(false)}
        footer={{
          kind: "default",
          primaryButton: { label: t("SEARCH_SELECT_ITEM"), onClick: () => onChange(activeItem!) },
        }}
      >
        {activeItem &&
          activeItem.data &&
          Object.entries(activeItem.data)
            .filter(([key]) => entryFields.includes(key))
            .map(([key, value]) => (
              <div key={key} className="flex items-center justify-between space-x-2 border-b border-gray-200 py-3">
                <Text color="medium">{startCase(key)}</Text>
                <Text>
                  <SearchValue value={value} />
                </Text>
              </div>
            ))}
      </Drawer>
    </>
  );
};

export default OnlineSearchContent;
