import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { createContext, useState } from "react";

export const BarcodeScannerProvider: FC<PropsWithChildren<void>> = ({ children }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [isScannerInstalling, setIsScannerInstalling] = useState<boolean>(false);
  return (
    <BarcodeScannerContext.Provider value={{ isScanning, setIsScanning, isScannerInstalling, setIsScannerInstalling }}>
      {children}
    </BarcodeScannerContext.Provider>
  );
};

type BarcodeScannerState = {
  isScanning: boolean;
  setIsScanning: Dispatch<SetStateAction<boolean>>;
  isScannerInstalling: boolean;
  setIsScannerInstalling: Dispatch<SetStateAction<boolean>>;
};

export const BarcodeScannerContext = createContext<BarcodeScannerState>({} as BarcodeScannerState);
