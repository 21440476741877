import { JSONPath } from "jsonpath-plus";
import { differenceBy, isEmpty, uniq } from "lodash-es";
import type { FormVersion } from "../types/FormVersion";
import type { Field } from "../types/Field";

export const filterUnpublishedFields = (formVersion: FormVersion, fields: Field[]): Field[] => {
  const formFields = JSONPath({ path: "$..fields", json: formVersion, flatten: true });
  return fields.filter((field) => formFields.find((f: { uid: string }) => f?.uid === field.formFieldId));
};

export const filterExcludedFields = (formVersion: FormVersion, fields: Field[]): Field[] => {
  const filterFields =
    formVersion.settings.searchSettings?.enabled &&
    formVersion.settings.searchSettings?.filteringEnabled &&
    formVersion.settings.searchSettings.filteredFields;

  if (!filterFields) {
    return fields;
  }

  const filterFieldsByIds = (unfilteredFields: Field[], filterIds: string[]): Field[] => {
    const toIncludeInCopy = unfilteredFields.filter((field) => {
      const inFilterList = filterIds.indexOf(field.formFieldId) !== -1;
      if (inFilterList) {
        return false;
      }
      if (!field.parentId) {
        return true;
      }

      const parentFieldId = findParentFieldId(field.parentId, fields);
      return parentFieldId && filterIds.indexOf(parentFieldId) === -1;
    });

    const toExcludeInCopy = differenceBy(unfilteredFields, toIncludeInCopy, (field) => field.id);
    const excludedParentIds = toExcludeInCopy
      .filter((field) => field.widget === "subform" || field.widget === "pin")
      .map((field) => field.formFieldId);

    if (isEmpty(excludedParentIds)) {
      return toIncludeInCopy;
    }

    return filterFieldsByIds(toIncludeInCopy, uniq(excludedParentIds));
  };

  return filterFieldsByIds(fields, formVersion.settings.searchSettings?.filteredFields!);
};

const findParentFieldId = (parentId: string, fields: Field[]): string | undefined =>
  fields.find((field) => field.id === parentId)?.formFieldId;
