import { useRef, useState } from "react";
import { isEmpty, isNil } from "lodash-es";
import { useAsyncEffect } from "./useAsyncEffect";
import { ensureFields, getSubmissionFormData } from "../utils/submissionUtil";
import { noopAsync } from "../utils/noop";
import type { FormVersion } from "../types/FormVersion";
import useDeviceInfo from "./useDeviceInfo";
import { findFormVersions } from "../utils/FormEngine";
import useUserId from "./useUserId";
import { buildFormState } from "../utils/fieldStateUtil";
import useFieldCollection from "./useFieldCollection";
import useRememberedFieldCollection from "./useRememberedFieldCollection";
import type { FormState } from "../context/SubmissionStoreContext";

const useInitialFormState = (
  submissionId?: string,
  formId?: string,
  formVersion?: FormVersion,
  isComplete = true,
  preview = false,
): FormState | undefined => {
  const [formState, setFormState] = useState<FormState>();
  const { id: deviceId } = useDeviceInfo();
  const userId = useUserId();
  const rememberedFieldCollection = useRememberedFieldCollection();
  const fieldCollection = useFieldCollection();
  const initialized = useRef<boolean>();

  useAsyncEffect(
    async () => {
      if (initialized.current && !preview) {
        return; // ensure we never run this more than once
      }
      if (
        !isComplete ||
        isNil(submissionId) || // HINT: could change with "save and new", creating new state
        isNil(formId) ||
        isNil(userId) ||
        isNil(formVersion) ||
        isNil(fieldCollection) ||
        isNil(rememberedFieldCollection)
      ) {
        return;
      }

      const rememberedFields = await rememberedFieldCollection.find().where("formId").eq(formId).exec();
      const formVersions = findFormVersions(formVersion);
      let fields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();

      // ensure all dynamic defaults are set, like date/time "now as default"
      const fieldsToUpsert = await ensureFields(
        submissionId,
        formId,
        formVersion,
        deviceId,
        userId,
        fields,
        rememberedFields,
      );
      if (!isEmpty(fieldsToUpsert)) {
        await fieldCollection?.bulkUpsert(fieldsToUpsert);
        fields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();
      }

      const formData = await getSubmissionFormData(fields);
      const formState = buildFormState(
        formVersion,
        formVersions,
        fields,
        submissionId,
        deviceId,
        formData,
        rememberedFields,
      );

      setFormState(formState);
      initialized.current = true;
    },
    noopAsync,
    [formVersion, submissionId, formId, userId, rememberedFieldCollection, isComplete],
  );

  return formState;
};

export default useInitialFormState;
