import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { createContext, useState } from "react";
import type { URLOpenListenerEvent } from "@capacitor/app";
import { App } from "@capacitor/app";
import { useEffectOnce } from "../hooks/useEffectOnce";

export const RedirectProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);

  useEffectOnce(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);
      /*
       * Check if protocol start with http, so we don't interfere with the login callback to the app
       * Login flow callback url contains package name as protocol.
       */
      if (url.protocol.startsWith("http")) {
        setRedirect(url.pathname);
      }
    });
  });

  return <RedirectContext.Provider value={{ redirect, setRedirect }}>{children}</RedirectContext.Provider>;
};

type RedirectState = {
  setRedirect: Dispatch<SetStateAction<string | undefined>>;
  redirect: string | undefined;
};

export const RedirectContext = createContext<RedirectState>({} as RedirectState);
