import type { FC } from "react";
import classNames from "classnames";
import type { Color } from "../Text/Text";
import { Text } from "../Text/Text";
import type { IconName } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";

const ICONS: Record<string, IconName> = {
  success: "CheckCircleIcon",
  warning: "ExclamationIcon",
  error: "ExclamationCircleIcon",
};

const COLORS: Record<string, Color> = {
  success: "success",
  warning: "warning",
  error: "danger",
};

export type FeedbackProps = {
  status?: "success" | "warning" | "error";
  message: string;
  className?: string;
};

export const Feedback: FC<FeedbackProps> = ({ status = "error", message, className }) => {
  const classes = classNames("mt-1 flex items-center gap-x-1", className);

  return (
    <Text className={classes} size="sm" weight="medium" color={COLORS[status]}>
      <Icon name={ICONS[status]} className="size-4 shrink-0" />
      <span className="min-w-0">{message}</span>
    </Text>
  );
};
