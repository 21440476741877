import type { FC } from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import objectHash from "object-hash";
import type { RemoteSubmission, SubmissionSummary } from "../../types/Submission";
import type { Paginated } from "../../types/Paginated";
import { useMoreAppClient } from "../../context/MoreAppContext";
import { fromPlatformIcon } from "../../utils/iconUtil";
import { toHumanReadableDate } from "../../utils/dateUtil";
import { seconds } from "../../utils/timeUtil";
import EmptyContent from "../EmptyContent";
import type { RemoteField } from "../../types/Field";
import { NavItem } from "../../storybook/components/NavItem/NavItem";
import { resolveTheme } from "../../storybook/themes";
import SpinnerWithMessage from "../../storybook/components/Spinner/SpinnerWithMessage";

export type SearchSubmissionPayload = {
  fields: RemoteField[];
} & Partial<RemoteSubmission>;

type SearchSubmissionListProps = {
  searchSubmissionPayload: SearchSubmissionPayload;
  onClick: (value: string) => void;
  onlyUser?: boolean;
};

const PAGE_SIZE = 20;
const SearchSubmissionList: FC<SearchSubmissionListProps> = ({ searchSubmissionPayload, onClick, onlyUser }) => {
  const { t } = useTranslation();
  const client = useMoreAppClient();
  const { ref: sentRef, inView: sentScrollerInView } = useInView();

  const getSentSubmissionSummaries = async (page = 0, size = 10): Promise<Paginated<SubmissionSummary> | undefined> => {
    const response = await client?.post<Paginated<SubmissionSummary>>(
      `/api/v1.0/client/customers/${searchSubmissionPayload.customerId}/submissions/filter`,
      searchSubmissionPayload,
      { params: { page, size, filter: onlyUser ? "user" : undefined } },
    );
    return response?.data;
  };

  const submissionHash = useMemo(
    () => objectHash(searchSubmissionPayload, { algorithm: "md5" }),
    [searchSubmissionPayload],
  );

  const { isFetching, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["submissions.sent", searchSubmissionPayload.customerId, searchSubmissionPayload.formId, submissionHash],
    queryFn: ({ pageParam }) => getSentSubmissionSummaries(pageParam, PAGE_SIZE),
    getNextPageParam: (lastPage) => (lastPage && !lastPage.last && lastPage.number + 1) || undefined,
    getPreviousPageParam: (firstPage) => (firstPage && !firstPage?.first && firstPage.number - 1) || undefined,

    initialPageParam: 0,
    enabled: !!searchSubmissionPayload.formId && !!submissionHash,
    staleTime: seconds(30),
    networkMode: "online",
  });

  useEffect(() => {
    if (sentScrollerInView) {
      fetchNextPage();
    }
  }, [sentScrollerInView, fetchNextPage]);

  const submissions = data?.pages.flatMap((d) => (d && d?.content) || []);
  const isEmptyState = !isFetching && data && submissions?.length === 0;

  if (isEmptyState) {
    return <EmptyContent title={t("NO_RESULTS")} description={t("NO_RESULTS_DESCRIPTION")} imgSrc="assets/empty.svg" />;
  }

  return (
    <div className="mt-3">
      {data &&
        submissions?.map((submission) => (
          <NavItem
            key={submission.id}
            icon={{
              name: fromPlatformIcon(submission.formIcon || "ios-paper-outline"),
              theme: resolveTheme(submission.formIconColor),
            }}
            label={submission.formName}
            description={submission.description || submission.formDescription}
            meta={[
              {
                icon: "CalendarIcon",
                label: toHumanReadableDate(new Date(submission.registrationDate)),
              },
              {
                icon: "UserIcon",
                label: submission.submittedBy,
              },
            ]}
            onClick={() => onClick(submission.id)}
          />
        ))}
      {((hasNextPage && isFetchingNextPage) || isFetching) && (
        <SpinnerWithMessage message={t("RETRIEVING_SENT_SUBMISSIONS")} bottomPadding />
      )}
      {hasNextPage && !isFetchingNextPage && <div ref={sentRef} />}
    </div>
  );
};

export default SearchSubmissionList;
