import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LoadingPage from "./LoadingPage";

const LoginCallbackPage: FC = () => {
  const { handleLoginCallback } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const loggingInWeb = useRef(false);

  useEffect(() => {
    // Only check on initial page load
    if (!loggingInWeb?.current) {
      loggingInWeb.current = true;
      handleLoginCallback().finally(() => {
        loggingInWeb.current = false;
        setLoading(false);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? (
    <LoadingPage conditions={[{ labelKey: "LOADING_ERROR_LOGIN_CALLBACK", value: isLoading }]} />
  ) : (
    <Navigate to="/folders" />
  );
};

export default LoginCallbackPage;
