import type { FC, PropsWithChildren } from "react";
import classNames from "classnames";

type PageProps = {
  className?: string;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ children, className }) => {
  const classes = classNames("flex size-full flex-col bg-white", className);
  return <div className={classes}>{children}</div>;
};

export default Page;
