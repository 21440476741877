import type { FC } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import pTimeout from "p-timeout";
import type { Submission } from "../types/Submission";
import type { Field } from "../types/Field";
import useSubmissionCopy from "../hooks/useSubmissionCopy";
import type { FormVersion } from "../types/FormVersion";
import useNavigateAsync from "../hooks/useNavigateAsync";
import { Modal } from "../storybook/components/Modal/Modal";
import { minutes } from "../utils/timeUtil";

type CopySubmissionModalProps = {
  submission: Submission;
  fields: Field[];
  formVersion: FormVersion;
  show: boolean;
  setShow: (show: boolean) => void;
};

const CopySubmissionModal: FC<CopySubmissionModalProps> = ({ submission, fields, formVersion, show, setShow }) => {
  const { t } = useTranslation();
  const navigate = useNavigateAsync();
  const { copy } = useSubmissionCopy(submission, fields, formVersion.formId);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const copySubmission = useCallback(async () => {
    if (isCopying) {
      return;
    }

    setIsCopying(true);

    await pTimeout(copy(), {
      milliseconds: minutes(1),
      message: "Copy operation timed out",
    })
      .then((copiedSubmissionId) => {
        navigate(`/submissions/${copiedSubmissionId}`, { replace: true });
      })
      .catch((err) => {
        setIsFailed(true);
        throw new Error("Can't copy submission", err);
      })
      .finally(() => {
        setIsCopying(false);
      });
  }, [isCopying, copy, navigate]);

  const onClose = (): void => {
    setShow(false);
    setIsFailed(false);
  };

  return (
    <Modal
      title={t("SUBMISSION_COPY_MODAL_TITLE")}
      content={{
        kind: "message",
        message: t(!isFailed ? "SUBMISSION_COPY_MODAL_DESCRIPTION" : "SUBMISSION_COPY_MODAL_DESCRIPTION_FAILED"),
      }}
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t(!isFailed ? "COPY" : "RETRY"),
          variant: "primary",
          loading: isCopying,
          onClick: copySubmission,
        },
      ]}
    />
  );
};

export default CopySubmissionModal;
