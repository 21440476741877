import { useMemo } from "react";
import useAuth from "./useAuth";
import useSubmission from "./useSubmission";
import useFormVersion from "./useFormVersion";
import type { Field } from "../types/Field";
import { remoteFieldToLocal } from "../types/Field";
import type { FieldDocument, SubmissionDocument } from "../utils/databaseUtil";
import type { SubmissionFormData } from "../components/Form";
import type { Submission } from "../types/Submission";
import { sentSubmissionToLocal } from "../types/Submission";
import type { FormVersion } from "../types/FormVersion";
import { fieldsToSubmissionFormData } from "../utils/submissionUtil";
import { evaluateRules } from "../utils/ruleEvaluationUtil";

type UseSentSubmissionResult = {
  isLoading: boolean;
  isNotFound: boolean;
  submission?: Submission;
  fields?: Field[];
  formData?: SubmissionFormData;
  formVersion?: FormVersion;
};

const useSentSubmission = (id?: string): UseSentSubmissionResult => {
  const { customerId, authorization } = useAuth();
  const { data: submission, isFetching } = useSubmission(customerId, id);
  const { formVersion, isFetchingFormVersion } = useFormVersion(
    submission?.formVersionId,
    submission?.customerId,
    submission?.formId,
  );

  const fieldsLocal = useMemo(() => {
    if (!submission || !formVersion) {
      return undefined;
    }
    const hiddenFields = evaluateRules(
      submission.id,
      submission.fields.map((field) => remoteFieldToLocal(field, submission.id)),
      formVersion,
      authorization.username!,
    ).filter((x) => x.hidden !== undefined);
    return submission?.fields.map((field) => {
      const hiddenField = hiddenFields.find((x) => x.uniqueFieldId === field.id);
      return {
        ...remoteFieldToLocal(field, submission.id),
        hidden: hiddenField?.hidden ?? false,
        evaluatedRules: hiddenField?.ruleResults ?? [],
      };
    }) as FieldDocument[];
  }, [submission, formVersion, authorization]);

  const formData: SubmissionFormData = useMemo(
    () => (fieldsLocal ? fieldsToSubmissionFormData(fieldsLocal) : {}),
    [fieldsLocal],
  );

  if (isFetchingFormVersion || isFetching) {
    return { isLoading: true, isNotFound: false };
  }

  if (submission == null) {
    return { isLoading: false, isNotFound: true };
  }
  const submissionLocal = sentSubmissionToLocal(submission) as SubmissionDocument;

  return {
    submission: submissionLocal,
    fields: fieldsLocal,
    formData,
    formVersion,
    isLoading: false,
    isNotFound: false,
  };
};

export default useSentSubmission;
