const emailAddressMaxLength = 254; // https://stackoverflow.com/a/574698
const emailAddressLocalPattern = /^.+$/;
const emailAddressDomainPattern =
  /^(?:[A-z0-9À-ž](?:[A-z0-9À-ž-]{0,61}[A-z0-9À-ž])?\.)+[A-z0-9À-ž][A-z0-9À-ž-]{0,61}[A-z0-9]$/;

// There are many regex patterns to validate email addresses, but they all have flaws and cannot 100% correctly verify
// every possible email address. For us, it's more important to avoid the majority of typo's instead of catching every
// invalid email address. We let our email provider handle and false positives (either by their validation or the fact
// that the email could not be delivered.
// ! This code is copied from 'common-nodejs' (including tests), make sure that changes are made there too (including tests).
export const isValidEmail = (email: string): boolean => {
  if (email.length > emailAddressMaxLength) {
    return false;
  }

  const [localAddr, domain, ...rest] = email.split("@");
  if (localAddr === undefined || domain === undefined || rest.length > 0) {
    return false;
  }

  return (
    !localAddr.startsWith(".") &&
    !localAddr.endsWith(".") &&
    !localAddr.includes(" ") &&
    emailAddressLocalPattern.test(localAddr) &&
    emailAddressDomainPattern.test(domain)
  );
};
