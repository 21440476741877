import { useStorageManagerEstimate } from "./useStorageManagerEstimate";
import { useDatasourceSizeEstimate } from "./useDatasourceSizeEstimate";
import { useCollectionSizeEstimates } from "./useCollectionSizeEstimates";
import type { UseQueryResult } from "@tanstack/react-query";
import type { MoreAppError } from "../context/MoreAppContext";

export const useStorageEstimates = (): {
  collectionSizeEstimates: UseQueryResult<{ total: number }, MoreAppError>[];
  datasourceSizeEstimate: UseQueryResult<{ total: number }, MoreAppError>;
  storageManagerEstimate: UseQueryResult<StorageEstimate | undefined, MoreAppError>;
  isLoading: boolean;
} => {
  const storageManagerEstimate = useStorageManagerEstimate();
  const datasourceSizeEstimate = useDatasourceSizeEstimate();
  const collectionSizeEstimates = useCollectionSizeEstimates();
  const estimates = { collectionSizeEstimates, datasourceSizeEstimate, storageManagerEstimate };

  return {
    ...estimates,
    isLoading: Object.values(estimates)
      .flat()
      .some((estimate) => estimate.isFetching && !estimate.isLoading),
  };
};
