import type { FC } from "react";
import { startCase } from "lodash-es";
import { useTranslation } from "react-i18next";
import Img from "../../Img";
import { getDatasourceImg } from "../../../utils/datasourceUtil";
import { Text } from "../../../storybook/components/Text/Text";
import { Title } from "../../../storybook/components/Title/Title";

type CatalogueItemContentProps = {
  item?: Record<string, string>;
  showPrice: boolean;
  showVat: boolean;
};

const CATALOGUE_KEYS = ["id", "name", "description", "priceExVat", "thumbnail", "photo", "vatRate"];
const CatalogueItemContent: FC<CatalogueItemContentProps> = ({ item, showPrice, showVat }) => {
  const { t } = useTranslation();

  return (
    <>
      {item?.photo && (
        <div className="border-b">
          <Img
            fallbackSrc="/assets/image-empty.svg"
            fallbackClassName="w-full bg-gray-100 p-24"
            className="mx-auto max-h-60"
            src={getDatasourceImg(item?.photo)}
            alt={item?.name}
          />
        </div>
      )}
      <div className="px-6 py-4">
        <div className="space-y-3 border-b pb-4">
          <Title as="h3" size="lg">
            {t("DESCRIPTION")}
          </Title>
          <Text>{item?.description}</Text>
        </div>
        <dl className="flex flex-col gap-y-6 pt-4">
          {showPrice && <MetaDataItem term={t("UNIT_PRICE")} definition={item?.priceExVat} />}
          {showVat && <MetaDataItem term={t("VAT")} definition={`${item?.vatRate}%`} />}
          {Object.entries(item ?? {})
            .filter(([key]) => !CATALOGUE_KEYS.includes(key))
            .map(([key, value]) => (
              <MetaDataItem key={key} term={startCase(key)} definition={value} />
            ))}
        </dl>
      </div>
    </>
  );
};

type MetaDataItemProps = {
  term: string;
  definition?: string;
};

const MetaDataItem: FC<MetaDataItemProps> = ({ term, definition }) => (
  <div className="grid max-w-full grid-cols-3 justify-between gap-x-6">
    <dt className="min-w-0 break-words text-gray-500">{term}</dt>
    <dd className="col-span-2 min-w-0 break-words text-right text-gray-700">{definition}</dd>
  </div>
);

export default CatalogueItemContent;
