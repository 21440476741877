import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import * as yup from "yup";
import type { ObjectSchema } from "yup";
import { useBeforeunload } from "react-beforeunload";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { TFunction } from "i18next";
import { isEmpty } from "lodash-es";
import { API_V2_URL } from "../constants";
import type { MoreAppSignupError } from "../context/MoreAppContext";
import useStorageItem from "../hooks/useStorageItem";
import useCountry from "../hooks/useCountry";
import useNavigateAsync from "../hooks/useNavigateAsync";
import logger from "../utils/logger";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { Title } from "../storybook/components/Title/Title";
import { TextInput } from "../storybook/components/TextInput/TextInput";
import { Radio } from "../storybook/components/Radio/Radio";
import { Checkbox } from "../storybook/components/Checkbox/Checkbox";
import { Message } from "../storybook/components/Message/Message";
import { TextButton } from "../storybook/components/TextButton/TextButton";

type SignupDrawerProps = {
  open: boolean;
  onClose: () => void;
};

const LICENSE_URL = "https://moreapp.com/assets/MoreApp-LA-EULA-DPA.pdf";
const PRIVACY_URL = "https://moreapp.com/en/privacy";

type SignupRequest = {
  customerName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  password: string;
  passwordConfirm: string;
  segment: string;
  agreeTerms: boolean;
  language: string;
  timeZone?: string;
  country: string;
  type: string;
};

const signupSchema = (t: TFunction): ObjectSchema<SignupRequest> =>
  yup.object({
    firstName: yup.string().required(t("VALIDATION_REQUIRED")),
    lastName: yup.string().required(t("VALIDATION_REQUIRED")),
    customerName: yup.string().required(t("VALIDATION_REQUIRED")),
    emailAddress: yup.string().email(t("VALIDATION_EMAIL_INVALID")).required(t("VALIDATION_REQUIRED")),
    password: yup
      .string()
      .min(8, t("VALIDATION_TEXT_MIN", { min: 8 }))
      .max(255, t("VALIDATION_TEXT_MAX", { max: 8 }))
      .required(t("VALIDATION_REQUIRED")),
    passwordConfirm: yup
      .string()
      .required(t("VALIDATION_REQUIRED"))
      .is([yup.ref("password")], t("SIGNUP_FORM_PASSWORD_CONFIRM_MISMATCH")),
    segment: yup.string().required(t("VALIDATION_REQUIRED")),
    agreeTerms: yup.boolean().is([true], t("SIGNUP_FORM_AGREE_TERMS_REQUIRED")),
    phoneNumber: yup.string(),
    timeZone: yup.string(),
    language: yup.string(),
    country: yup.string(),
    type: yup.string(),
  }) as ObjectSchema<SignupRequest>;

const SignupPage: FC<SignupDrawerProps> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigateAsync();
  const [, setUsername] = useStorageItem<string>("username");
  const [, setFirstName] = useStorageItem<string>("firstName");
  const { getCountryCode } = useCountry();
  const mutation = useMutation<any, MoreAppSignupError, SignupRequest>({
    mutationFn: async (data) => axios.post(`${API_V2_URL}/signup`, data),
  });

  // prevent refresh on signup page
  useBeforeunload((event) => {
    if (open) {
      event.preventDefault();
    }
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<SignupRequest>({
    defaultValues: {
      agreeTerms: false,
      language: i18n.language,
      type: "APP",
    },
    resolver: yupResolver(signupSchema(t)),
    mode: "all",
  });

  const onSubmit = async (data: any): Promise<void> => {
    try {
      setFirstName(data.firstName).catch((e) => logger.error("Could not set first name", e)); // to show in next step
      setUsername(data.emailAddress).catch((e) => logger.error("Could not set username", e)); // to verify email in next step
      await mutation.mutateAsync({ ...data, country: await getCountryCode() });
      navigate(`/verify-email`, { replace: true });
    } catch (e: any) {
      logger.error("Signup didn't succeed", e);
    }
  };
  // prevent refresh on signup page
  useBeforeunload((event) => {
    if (open) {
      event.preventDefault();
    }
  });

  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: t("SIGNUP_TITLE"),
        button: {
          "aria-label": t("SIGNUP_CANCEL"),
          kind: "icon",
          icon: "XIcon",
          onClick: onClose,
        },
      }}
      onClose={() => {
        // Don't close on backdrop click
      }}
    >
      <Title as="h2" size="3xl" className="my-6 whitespace-pre-line">
        {t("SIGNUP_FORM_TITLE")}
      </Title>
      <form className="mb-12 mt-6 flex flex-col space-y-6">
        <Controller
          name="customerName"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="text"
              label={t("SIGNUP_FORM_COMPANY")}
              errorMessage={errors.customerName?.message}
              inputRef={field.ref}
            />
          )}
        />
        <Controller
          name="segment"
          control={control}
          render={({ field }) => (
            <Radio
              required
              inputRef={field.ref}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              label={t("SIGNUP_FORM_SECTOR")}
              layout="horizontal"
              onClear={() => field.onChange(null)}
              options={[
                { value: "CONSTRUCTION", label: { kind: "text", value: "Construction" } },
                { value: "INSTALLATION", label: { kind: "text", value: "Installation" } },
                { value: "AUTOMOTIVE", label: { kind: "text", value: "Automotive" } },
                { value: "TRADE_AND_INDUSTRY", label: { kind: "text", value: "Trade & Industry" } },
                { value: "FACILITY", label: { kind: "text", value: "Facility" } },
                { value: "FINANCIAL", label: { kind: "text", value: "Financial" } },
                { value: "GOVERNMENT", label: { kind: "text", value: "Government" } },
                { value: "HEALTH_CARE", label: { kind: "text", value: "Health care" } },
                { value: "OTHER", label: { kind: "text", value: "Other" } },
              ]}
              errorMessage={errors.segment?.message}
            />
          )}
        />

        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="text"
              label={t("SIGNUP_FORM_FIRST_NAME")}
              errorMessage={errors.firstName?.message}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="text"
              label={t("SIGNUP_FORM_LAST_NAME")}
              errorMessage={errors.lastName?.type === "required" ? t("VALIDATION_REQUIRED") : undefined}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <TextInput
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="tel"
              label={t("SIGNUP_FORM_PHONE")}
              errorMessage={errors.phoneNumber?.message}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="emailAddress"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="email"
              label={t("SIGNUP_FORM_EMAIL")}
              errorMessage={errors.emailAddress?.message}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="password"
              label={t("SIGNUP_FORM_PASSWORD")}
              errorMessage={errors.password?.message}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="passwordConfirm"
          control={control}
          render={({ field }) => (
            <TextInput
              required
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              type="password"
              label={t("SIGNUP_FORM_PASSWORD_CONFIRM")}
              errorMessage={errors.passwordConfirm?.message}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="agreeTerms"
          control={control}
          render={({ field }) => (
            <Checkbox
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              selected={field.value}
              required={true}
              errorMessage={errors.agreeTerms?.message}
              children={
                <span>
                  {t("SIGNUP_FORM_AGREE_TERMS")}
                  <a
                    href={LICENSE_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="px-1 py-0.5 text-brand-500"
                    children={t("LICENSE_AGREEMENT")}
                  />
                  &
                  <a
                    href={PRIVACY_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="px-1 py-0.5 text-brand-500"
                    children={t("PRIVACY_POLICY")}
                  />
                </span>
              }
            />
          )}
        />

        {mutation.isError && <Message type="error" text={getError(mutation.error) ?? t("SIGNUP_SUBMIT_FAILURE")} />}

        <TextButton
          className="mt-12"
          label={t("SIGNUP_CREATE_ACCOUNT")}
          block
          variant="primary"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        />
      </form>
    </Drawer>
  );
};

const getError = (error: MoreAppSignupError): string | undefined => {
  const details = error.response?.data?.details;
  if (!details) {
    return undefined;
  }
  if (!isEmpty(details.fieldErrors)) {
    const firstFieldError = Object.keys(details.fieldErrors)[0];
    return details.fieldErrors[firstFieldError][0];
  }
  return undefined;
};

export default SignupPage;
