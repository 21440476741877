import type { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { seconds } from "../utils/timeUtil";
import type { AxiosError } from "axios";
import axios from "axios";
import type { MoreAppError } from "../context/MoreAppContext";
import { isNil } from "lodash-es";
import { useEffect } from "react";
import { SEARCH_URL } from "../constants";

export type SearchResult = {
  results: {
    id: string;
    [key: string]: string;
  }[];
};

const PAGE_SIZE = 50;

const useDatasourceSearch = (
  id: string,
  query: string,
  isExactQuery: boolean,
  filterQueries?: string[],
  token?: string,
): UseInfiniteQueryResult<InfiniteData<SearchResult, unknown>, AxiosError<MoreAppError>> => {
  const result = useInfiniteQuery<SearchResult, AxiosError<MoreAppError>>({
    queryKey: ["datasource-search", id, query, filterQueries, isExactQuery],
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.post<SearchResult>(
        `${SEARCH_URL}/search`,
        {
          query,
          filter: filterQueries ?? [],
          isExact: isExactQuery,
          dataSourceId: id,
          page: pageParam,
          pageSize: PAGE_SIZE,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      return data;
    },
    getNextPageParam: (lastPage, allPages) => {
      const lastPageResults = lastPage.results ?? [];
      return lastPageResults.length === PAGE_SIZE ? allPages.length + 1 : undefined;
    },
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    enabled: !isNil(token),
    retry: (count, error) => {
      if (error.response?.status === 409) {
        // Show error early
        return false;
      }
      return count < 3;
    },
    gcTime: seconds(0), // Enabling caching will refetch all the pages that were cached once the cache drops, so skip
    staleTime: seconds(0),
  });

  useEffect(() => {
    if (result.isError) {
      const timeout = setTimeout(() => {
        result.refetch();
      }, seconds(10));

      return (): void => clearTimeout(timeout);
    }
  }, [result.isError, result.refetch]);

  return result;
};

export default useDatasourceSearch;
