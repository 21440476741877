import type { Widget } from "../../types/Widget";
import type { WidgetResult } from "../../types/Field";
import { Checkbox } from "../../storybook/components/Checkbox/Checkbox";
import WidgetContainer from "../WidgetContainer";

export type WidgetCheckboxProperties = {
  required: boolean;
  label_text: string;
  checkbox_default_value?: boolean;
};

const WidgetCheckbox: Widget<WidgetCheckboxProperties, WidgetResult<boolean>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => (
  <WidgetContainer fieldState={fieldState} name="CHECKBOX_FIELD">
    <Checkbox
      name={fieldState.uniqueFieldId}
      selected={fieldState.value.rawValue}
      disabled={readOnly}
      onChange={(e) => setFieldState(e.target.checked)}
      errorMessage={fieldState.error}
      required={fieldState.properties.required}
      className="text-gray-900"
    >
      {fieldState.properties.label_text}
    </Checkbox>
  </WidgetContainer>
);

WidgetCheckbox.defaultValue = (properties, defaultMeta): WidgetResult<boolean> => ({
  type: "boolean",
  rawValue: properties.checkbox_default_value ?? false,
  meta: {
    widget: "checkbox",
    ...defaultMeta,
  },
});

WidgetCheckbox.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetCheckbox;
