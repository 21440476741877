import type { FC, MouseEventHandler } from "react";
import { Fragment } from "react";
import classNames from "classnames";
import type { IconName } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import HorizontalListScroll from "../HorizontalListScroll/HorizontalListScroll";
import { noop } from "../../../utils/noop";

export type IconColor = "default" | "green" | "blue" | "red" | "orange" | "purple";

type BaseChipOption = {
  value: string;
  label: string;
};

type LabelChipOption = {
  kind: "label";
} & BaseChipOption;

type IconChipOption = {
  kind: "icon";
  icon?: IconName;
  iconColor?: IconColor;
} & BaseChipOption;

type ImageChipOption = {
  kind: "image";
  value: string;
  label: string;
  src: string;
} & BaseChipOption;
export type ChipOption = LabelChipOption | IconChipOption | ImageChipOption;

export type ChipsProps = {
  onSelect: (value: string) => void;
  selected?: string;
  options: ChipOption[];
};

export const Chips: FC<ChipsProps> = ({ options = [], onSelect = noop, selected = "" }) => (
  <HorizontalListScroll>
    {options.map((o, index) => (
      <Fragment key={index}>
        {o.kind === "image" ? (
          <ImageChip
            className="shrink-0"
            src={o.src}
            onClick={() => onSelect(o.value)}
            selected={o.value === selected}
            label={o.label}
          />
        ) : (
          <IconChip
            className="shrink-0"
            icon={o.kind === "icon" ? o.icon : undefined}
            iconColor={o.kind === "icon" ? o.iconColor : undefined}
            onClick={() => onSelect(o.value)}
            selected={o.value === selected}
            label={o.label}
          />
        )}
      </Fragment>
    ))}
  </HorizontalListScroll>
);

type ChipProps = {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  label: string;
};

type IconChipProps = {
  icon?: IconName;
  iconColor?: IconColor;
} & ChipProps;

type ImageChipProps = {
  src: string;
} & ChipProps;
const IconChip: FC<IconChipProps> = ({
  className,
  onClick = noop,
  selected = false,
  icon,
  iconColor = "default",
  label,
}) => {
  const classes = classNames(
    "flex select-none items-center justify-center space-x-2 rounded-full px-4 py-2 outline-none ring-inset focus-visible:ring",
    selected
      ? "bg-gray-700 active:!bg-gray-900 pointer:hover:bg-gray-800"
      : "bg-gray-100 active:!bg-gray-300 pointer:hover:bg-gray-200",
    className,
  );

  const iconClasses = classNames(
    "size-5 shrink-0",
    selected
      ? "text-white"
      : {
          "text-gray-600": iconColor === "default",
          "text-green-500": iconColor === "green",
          "text-blue-500": iconColor === "blue",
          "text-red-500": iconColor === "red",
          "text-orange-500": iconColor === "orange",
          "text-purple-500": iconColor === "purple",
        },
  );

  return (
    <button type="button" className={classes} onClick={onClick}>
      {icon && <Icon name={icon} className={iconClasses} />}
      <Text className="shrink-0" color={selected ? "white" : "dark"}>
        {label}
      </Text>
    </button>
  );
};

const ImageChip: FC<ImageChipProps> = ({ className, onClick = noop, selected = false, label, src }) => {
  const classes = classNames(
    "flex select-none items-center justify-center space-x-2 rounded-full px-4 py-2 outline-none ring-inset focus-visible:ring",
    selected
      ? "bg-gray-700 active:!bg-gray-900 pointer:hover:bg-gray-800"
      : "bg-gray-100 active:!bg-gray-300 pointer:hover:bg-gray-200",
    className,
  );

  return (
    <button type="button" className={classes} onClick={onClick}>
      <img alt={label} className="h-5 max-w-10 shrink-0 object-contain" src={src} />
      <Text className="shrink-0" color={selected ? "white" : "dark"}>
        {label}
      </Text>
    </button>
  );
};
