import { isPlainObject } from "lodash-es";
import type { FC } from "react";
import { isEmail, isPhoneNumber, isUrl } from "../../../utils/stringUtil";
import { Text } from "../../../storybook/components/Text/Text";

type SearchValueProps = {
  value: unknown;
  renderComplexData?: boolean;
};
const SearchValue: FC<SearchValueProps> = ({ value, renderComplexData = true }) => {
  if (typeof value === "string") {
    if (isUrl(value)) {
      return (
        <a target="_blank" rel="noreferrer noopener" href={value} className="text-blue-500 underline">
          {value}
        </a>
      );
    }
    if (isEmail(value)) {
      return (
        <a href={`mailto:${value}`} className="text-blue-500 underline">
          {value}
        </a>
      );
    }

    if (isPhoneNumber(value)) {
      return (
        <a href={`tel:${value}`} className="text-blue-500 underline">
          {value}
        </a>
      );
    }

    return <Text>{value}</Text>;
  }

  if (typeof value === "boolean" || typeof value === "number" || typeof value === "bigint") {
    return <Text>{value.toString()}</Text>;
  }

  if (renderComplexData && typeof value === "object") {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              <Text>
                <SearchValue value={item} renderComplexData={false} />
              </Text>
            </li>
          ))}
        </ul>
      );
    }

    if (isPlainObject(value) && value) {
      return (
        <ul>
          {Object.entries(value).map(([mapKey, mapValue]) => (
            <li key={mapKey}>
              <Text>
                {mapKey} - <SearchValue value={mapValue} renderComplexData={false} />
              </Text>
            </li>
          ))}
        </ul>
      );
    }
  }

  // Probably undefined or not rendering complex data
  return null;
};

export default SearchValue;
