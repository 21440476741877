import { type UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import { useMemo } from "react";
import useAuth from "./useAuth";
import type { MoreAppError } from "../context/MoreAppContext";
import { useMoreAppClient } from "../context/MoreAppContext";
import { seconds } from "../utils/timeUtil";
import type { Folder } from "../types/Folder";
import { FoldersNotFoundError } from "../types/Folder";
import { readFileAsText, writeFile } from "../utils/fileSystemUtil";
import logger from "../utils/logger";

const useFolders = (): UseQueryResult<Folder[], MoreAppError> => {
  const { customerId, authorization } = useAuth();
  const client = useMoreAppClient();
  return useQuery<Folder[], MoreAppError>({
    queryKey: ["folders", customerId],
    queryFn: async () => getFolders(customerId!, client!, authorization.username!),
    enabled: !!customerId && !!client && !!authorization.username,
    staleTime: seconds(5),
    gcTime: Infinity,
    networkMode: "offlineFirst",
  });
};

type UseAllFoldersResult = {
  data: Folder[];
  isFetching: boolean;
};
export const useAllFolders = (): UseAllFoldersResult => {
  const { customers, authorization } = useAuth();
  const client = useMoreAppClient();

  const queries = useMemo(
    () =>
      customers?.map((customer) => ({
        queryKey: ["folders", customer.customerId],
        queryFn: async (): Promise<Folder[]> => getFolders(customer.customerId, client!, authorization.username!),
        enabled: !!customer.customerId && !!client && !!authorization.username,
        staleTime: seconds(60),
        cacheTime: Infinity,
      })) ?? [],
    [authorization.username, client, customers],
  );
  const result = useQueries({ queries });

  return {
    data: result.filter((x) => x.data).flatMap((x) => x.data) as Folder[],
    isFetching: result.some((query) => query.isFetching),
  };
};
const getFolders = async (customerId: number, client: AxiosInstance, userName: string): Promise<Folder[]> => {
  try {
    const folders = await client.get<Folder[]>(
      `/api/v1.0/forms/customer/${customerId}/folders?expand=forms&filter=usable`,
    );
    const allFolders = folders.data.map((folder) => ({ ...folder, customerId }));

    // Don't block returning response on the file write
    writeFile({
      path: `${userName}/${customerId}/folders.json`,
      data: JSON.stringify(allFolders),
    }).catch((e) => logger.error("Couldn't write folders.json for customer", e, { extra: { customerId } }));
    return allFolders;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return fetchFromFilesystem(customerId, userName);
  }
};

export const fetchFromFilesystem = async (customerId: number, userName: string): Promise<Folder[]> => {
  try {
    const data = await readFileAsText({ path: `${userName}/${customerId}/folders.json` });
    return (await JSON.parse(data)) as Folder[];
  } catch (e: any) {
    throw new FoldersNotFoundError(e.message);
  }
};

export default useFolders;
