import type { ComponentPropsWithoutRef, FC, ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { omit } from "lodash-es";
import type { IconProps, IconName } from "../Icon/Icon";
import { Icon } from "../Icon/Icon";
import type { MenuItem } from "../DropdownMenu/DropdownMenu";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import type { FreestyleComponentProps } from "../FreestyleComponent";
import FreestyleComponent from "../FreestyleComponent";
import { Title } from "../Title/Title";
import { Text } from "../Text/Text";
import { IconButton } from "../IconButton/IconButton";
import { TextButton } from "../TextButton/TextButton";
import { IconAndTextButton } from "../IconAndTextButton/IconAndTextButton";

export type DrawerHeaderProps = SimpleDrawerHeaderProps | ExtendedDrawerHeaderProps | FreestyleComponentProps;

type SimpleDrawerHeaderProps = {
  kind: "simple";
  className?: string;
  title: string;
  subtitle?: string;
  button: DrawerHeaderButtonProps;
  content?: ReactNode;
};

type ExtendedDrawerHeaderProps = {
  kind: "extended";
  className?: string;
  title: string;
  icon?: IconProps;
  subtitle?: string;
  rightButton?: DrawerHeaderButtonProps;
  leftButton?: DrawerHeaderButtonProps;
  content?: ReactNode;
};

type DrawerHeaderButtonDropdownMenuProps = {
  className?: string;
  items: MenuItem[];
};

type DrawerHeaderButtonProps =
  | DrawerHeaderTextButtonProps
  | DrawerHeaderTextIconButtonProps
  | DrawerHeaderIconButtonProps;

type DrawerHeaderIconButtonProps = {
  kind: "icon";
  icon: IconName;
  dropDownMenu?: DrawerHeaderButtonDropdownMenuProps;
} & ComponentPropsWithoutRef<"button">;

type DrawerHeaderTextIconButtonProps = {
  kind: "textAndIcon";
  label: string;
  icon: IconName;
  iconAlign?: "left" | "right";
  dropDownMenu?: DrawerHeaderButtonDropdownMenuProps;
} & ComponentPropsWithoutRef<"button">;

type DrawerHeaderTextButtonProps = {
  kind: "text";
  label: string;
  dropDownMenu?: DrawerHeaderButtonDropdownMenuProps;
} & ComponentPropsWithoutRef<"button">;

const DrawerHeaderButton: FC<DrawerHeaderButtonProps> = ({ dropDownMenu, ...props }) => {
  const button =
    props.kind === "icon" ? (
      <IconButton variant="transparentMedium" iconColor="medium" {...omit(props, ["kind"])} />
    ) : props.kind === "text" ? (
      <TextButton variant="transparentBrand" {...omit(props, ["kind"])} />
    ) : (
      <IconAndTextButton variant="transparentBrand" iconAlign="right" {...omit(props, ["kind"])} />
    );

  return dropDownMenu ? (
    <DropdownMenu className={dropDownMenu.className} menuButton={() => button} items={dropDownMenu.items} />
  ) : (
    button
  );
};

const SimpleDrawerHeader: FC<SimpleDrawerHeaderProps> = ({ title, button, subtitle, content, className }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className={classNames("flex min-w-0 grow flex-col gap-1 px-6 py-5.5", className)}>
          <Title as="h2" size="xl" className="line-clamp-2 break-words">
            {title}
          </Title>
          {subtitle && (
            <Text size="base" color="medium" className="break-words leading-5.5">
              {subtitle}
            </Text>
          )}
        </div>
        <div className={button.kind === "icon" ? "mr-2 mt-2" : "mr-3 mt-2"}>
          <DrawerHeaderButton aria-label={t("CLOSE")} {...button} />
        </div>
      </div>
      {content}
    </div>
  );
};

const ExtendedDrawerHeader: FC<ExtendedDrawerHeaderProps> = ({
  title,
  subtitle,
  leftButton,
  rightButton,
  icon,
  content,
  className,
}) => {
  const titleClasses = classNames("px-6 text-center", { "-mt-8": icon });
  const buttonDivClasses = classNames("flex", {
    "gap-x-14": icon,
    "justify-between": leftButton && rightButton,
    "justify-end": !leftButton && rightButton,
    "pl-2 pt-2": leftButton?.kind === "icon",
    "pl-1 pt-1": leftButton?.kind !== "icon",
    "pr-2 pt-2": rightButton?.kind === "icon",
    "pr-1 pt-1": rightButton?.kind !== "icon",
  });

  return (
    <div className="flex flex-col">
      <div className={classNames("pb-6", className)}>
        <div className={buttonDivClasses}>
          {leftButton && <DrawerHeaderButton {...leftButton} />}
          {rightButton && <DrawerHeaderButton {...rightButton} />}
        </div>
        <div className={titleClasses}>
          {icon && (
            <div className="mx-auto mb-6 flex size-12 items-center justify-center rounded-full bg-brand-500 text-white">
              <Icon {...icon} />
            </div>
          )}
          <Title as="h2" size="xl" className="line-clamp-2">
            {title}
          </Title>
          {subtitle && (
            <Text size="base" color="medium" className="mt-2 leading-5.5">
              {subtitle}
            </Text>
          )}
        </div>
      </div>
      {content}
    </div>
  );
};

const DrawerHeader: FC<DrawerHeaderProps> = (props) => {
  switch (props.kind) {
    case "simple":
      return <SimpleDrawerHeader {...props} />;
    case "extended":
      return <ExtendedDrawerHeader {...props} />;
    case "freestyle":
      return <FreestyleComponent {...props} />;
  }
};

export default DrawerHeader;
