import type { FC, PropsWithChildren } from "react";
import { useEffect } from "react";
import classNames from "classnames";
import { useOutletContext } from "react-router";
import type { NavigationPageOutletContext } from "../pages/NavigationPage";

type PageProps = {
  className?: string;
  showTabBar?: boolean;
};

const MenuPage: FC<PropsWithChildren<PageProps>> = ({ children, className, showTabBar = false }) => {
  const { setShowTabBar } = useOutletContext<NavigationPageOutletContext>();

  useEffect(() => {
    setShowTabBar(showTabBar);
  }, [setShowTabBar, showTabBar]);

  const classes = classNames("flex size-full flex-col bg-white", className);
  return <div className={classes}>{children}</div>;
};

export default MenuPage;
