import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { Submission } from "../types/Submission";
import { toHumanReadableDate, toUTCDate } from "../utils/dateUtil";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";
import { Icon } from "../storybook/components/Icon/Icon";

type SubmissionContentHeaderProps = {
  submission: Submission;
};

const SubmissionContentHeader: FC<SubmissionContentHeaderProps> = ({ submission: { form, task } }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full" key="form-header">
      <Title as="h1" size="3xl">
        {form.name}
      </Title>
      {form.description && (
        <Text className="mt-2" color="medium">
          {form.description}
        </Text>
      )}
      {task?.message && (
        <div className="mt-4 flex flex-col gap-2 rounded-lg bg-gray-100 p-4">
          <Title as="h3" size="lg" className="line-clamp-2">
            {t("TASKS_MESSAGE")}
          </Title>
          <Text>{task.message}</Text>
          {task.dueDate && (
            <div className="flex items-center gap-x-2 text-gray-700">
              <Icon name="CalendarIcon" type="outline" className="size-5" />
              <Text size="sm">{toHumanReadableDate(toUTCDate(task.dueDate), true)}</Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmissionContentHeader;
