import type { FC } from "react";
import classNames from "classnames";

type SwiperProgressProps = {
  className?: string;
  index: number;
  size: number;
};

const SwiperProgress: FC<SwiperProgressProps> = ({ className, index, size }) => (
  <div className={classNames("flex space-x-1", className)}>
    {[...Array(size)].map((el, i) => (
      <div
        key={i}
        className={classNames({
          "h-2 rounded-full": true,
          "w-2 bg-gray-400": i !== index,
          "w-8 bg-gray-800": i === index,
        })}
      />
    ))}
  </div>
);

export default SwiperProgress;
