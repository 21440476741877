import type { Widget } from "../../types/Widget";
import type { WidgetResult } from "../../types/Field";
import type { WidgetHeaderProperties } from "./WidgetHeader";
import WidgetHeader from "./WidgetHeader";

export type WidgetBorderlessHeaderProperties = {
  label_text: never;
  header: string;
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const WidgetBorderlessHeader: Widget<WidgetBorderlessHeaderProperties, WidgetResult<void>> = (props) => (
  <WidgetHeader {...props} />
);

WidgetBorderlessHeader.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "borderlessHeader",
    ...defaultMeta,
  },
});

WidgetBorderlessHeader.validate = (): undefined => undefined;

WidgetBorderlessHeader.mapProperties = (input: WidgetBorderlessHeaderProperties): WidgetHeaderProperties => ({
  ...input,
  label_text: input.header,
});

export default WidgetBorderlessHeader;
