import type { FC } from "react";
import { useTranslation } from "react-i18next";
import SubmissionListPage from "./SubmissionListPage";
import EmptyContent from "../components/EmptyContent";
import useAuth from "../hooks/useAuth";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";

const DraftsPage: FC = () => {
  const { customerId } = useAuth();
  const { t } = useTranslation();

  return (
    <SubmissionListPage
      ariaLabel={t("DRAFTS")}
      translationPrefix="DRAFT"
      type="DRAFTS"
      query={(collection) =>
        collection
          .find()
          .where("customerId")
          .eq(customerId)
          .where("task")
          .eq(null)
          .where("status")
          .eq("draft")
          .where("submittedAt")
          .eq(null)
          .sort({ viewedAt: "desc", createdAt: "desc" })
      }
      emptyState={
        <EmptyContent
          title={t("DRAFTS_EMPTY_TITLE")}
          description={t("DRAFTS_EMPTY_DESCRIPTION")}
          imgAlt={NON_DESCRIPTIVE_IMAGE}
          imgSrc="assets/empty.svg"
        />
      }
    />
  );
};

export default DraftsPage;
