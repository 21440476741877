import { useState } from "react";
import { isArray, sortBy, toArray, toString } from "lodash-es";
import { useTranslation } from "react-i18next";
import type { Widget } from "../../types/Widget";
import { normalize } from "../../utils/stringUtil";
import type { WidgetResult } from "../../types/Field";
import { RadioList } from "../../storybook/components/RadioList/RadioList";
import { CheckboxList } from "../../storybook/components/CheckboxList/CheckboxList";
import WidgetContainer from "../WidgetContainer";

type WidgetLookupOption = {
  id: string; // Persisted value
  name: string; // Display value
};

export type WidgetLookupProperties = {
  required: boolean;
  label_text: string;
  options: WidgetLookupOption[];
  is_multiple?: boolean;
  default_value?: string | string[];
  expand?: boolean;
  sort_alphabetically?: boolean;
};

const WidgetLookup: Widget<WidgetLookupProperties, WidgetResult<string[] | string>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const isMultiple = fieldState.properties.is_multiple;
  const isSorted = fieldState.properties.sort_alphabetically;
  const items = fieldState.properties.options
    .filter((x) => normalize(x.name))
    .map((x) => ({
      value: x.id,
      label: x.name,
    }));

  const lookupOptions = isSorted ? sortBy(items, [(item): string => item.label.toLowerCase()]) : items;
  const [expanded, setExpanded] = useState<boolean>(!!fieldState.properties.expand);

  const clearValue = (): void => {
    const clearedValue = fieldState.properties.is_multiple ? [] : undefined;
    setFieldState(clearedValue);
  };

  const buttonLabel = !readOnly ? t("SEARCH_SELECT_ITEM") : t("NO_ITEM_SELECTED");

  const componentProps = {
    name: fieldState.uniqueFieldId,
    label: fieldState.properties.label_text,
    errorMessage: fieldState.error,
    ariaLabel: expanded ? t("COLLAPSE") : t("EXPAND"),
    placeholder: buttonLabel,
    disabled: readOnly,
    options: lookupOptions,
    expanded,
    onBtnClick: (): void => setExpanded((prevState) => !prevState),
    required: fieldState.properties.required,
    clearLabel: t("CLEAR"),
    onClear: clearValue,
  };

  return (
    <WidgetContainer fieldState={fieldState} name="LOOKUP_FIELD">
      {isMultiple ? (
        <CheckboxList
          {...componentProps}
          onChange={(value) => setFieldState(value)}
          value={
            typeof fieldState.value.rawValue === "string" // remembered input could have single value
              ? [fieldState.value.rawValue]
              : toArray(fieldState.value.rawValue)
          }
        />
      ) : (
        <RadioList
          {...componentProps}
          onChange={(value) => {
            setFieldState(value);
            setExpanded(false);
          }}
          value={
            isArray(fieldState.value.rawValue) // remembered input could have multiple value
              ? fieldState.value.rawValue[0]
              : toString(fieldState.value.rawValue)
          }
        />
      )}
    </WidgetContainer>
  );
};

WidgetLookup.defaultValue = (properties, defaultMeta: any): WidgetResult<string[] | string> => {
  let rawValue: string | string[] | undefined;
  if (!properties.default_value) {
    rawValue = properties.is_multiple ? [] : undefined;
  } else if (!isArray(properties.default_value)) {
    rawValue = properties.is_multiple ? [properties.default_value] : properties.default_value;
  } else if (!properties.is_multiple) {
    rawValue = properties.default_value.length > 0 ? properties.default_value[0] : undefined;
  } else {
    rawValue = properties.default_value;
  }

  return {
    type: "string",
    rawValue,
    meta: {
      widget: "lookup",
      ...defaultMeta,
    },
  };
};

WidgetLookup.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && (!val || val.length === 0)) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};
export default WidgetLookup;
