import type { FC } from "react";
import type { GenericButtonProps } from "../Button/Button";
import { Button } from "../Button/Button";

export type TextButtonProps = {
  label: string;
  block?: boolean;
  size?: "lg" | "md";
} & GenericButtonProps;

export const TextButton: FC<TextButtonProps> = (props) => <Button block={false} {...props} />;
