import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../storybook/components/Modal/Modal";

type FileUploadFailedModalProps = {
  show: boolean;
  onClose: () => void;
  onRetry?: () => void;
};

const FileUploadFailedModal: FC<FileUploadFailedModalProps> = ({ show, onClose, onRetry }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("UPLOAD_FAILED_MODAL_TITLE")}
      content={{
        kind: "message",
        message: t("UPLOAD_FAILED_MODAL_MESSAGE"),
      }}
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t("RETRY"),
          variant: "primary",
          icon: "RefreshIcon",
          onClick: async (): Promise<void> => {
            try {
              onRetry?.();
            } finally {
              onClose();
            }
          },
        },
      ]}
    />
  );
};

export default FileUploadFailedModal;
