import type { UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { estimateCollectionSize } from "../utils/databaseUtil";
import useAuth from "./useAuth";
import type { MoreAppError } from "../context/MoreAppContext";
import useFieldCollection from "./useFieldCollection";
import useSubmissionCollection from "./useSubmissionCollection";
import useFormVersionCollection from "./useFormVersionCollection";

export const useCollectionSizeEstimates = (): UseQueryResult<
  {
    total: number;
  },
  MoreAppError
>[] => {
  const fieldCollection = useFieldCollection();
  const submissionCollection = useSubmissionCollection();
  const formVersionCollection = useFormVersionCollection();
  const collections = [fieldCollection, submissionCollection, formVersionCollection];

  const { customerId, username } = useAuth();
  return useQueries({
    queries: collections.map((collection) => ({
      queryKey: ["collectionSize", collection?.name, customerId, username],
      queryFn: (): Promise<{ total: number }> =>
        collection ? estimateCollectionSize(collection) : Promise.resolve({ total: 0 }),
      gcTime: 0,
      enabled: !!collection,
    })),
  });
};
