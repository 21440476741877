import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { type MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import { minutes } from "../utils/timeUtil";

export type User = {
  username: string;
  emailAddress: string;
  settings: {
    firstName: string;
    lastName: string;
  };
  externallyManaged: boolean;
};

const useUser = (): UseQueryResult<User, MoreAppError> => {
  const client = useMoreAppClient();
  return useQuery<User, MoreAppError>({
    queryKey: ["user"],
    queryFn: async () => {
      const { data } = await client!.get<User>("/api/v1.0/users/current");
      return data;
    },
    staleTime: minutes(5), // This can still be modified on another device or SSO, but impact is limited
    gcTime: Infinity,
  });
};

export default useUser;
