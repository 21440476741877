import type { RxDatabase, RxDocumentData } from "rxdb";
import { isEmpty, isNil } from "lodash-es";
import type { Entry, Field, HasuraField } from "../types/Field";
import type { DBCollections } from "./databaseUtil";
import { getSortedFields } from "./submissionUtil";
import type { UniqueFieldId } from "../types/SubmissionState";
import { getPinFormVersions, getSubformVersion, hasKnownWidget } from "./formUtil";
import type { FieldProperties, FormField, FormVersion, SubForm, WidgetProperties } from "../types/FormVersion";
import logger from "./logger";
import { findFormVersions } from "./FormEngine";
import { findDuplicates } from "./stringUtil";

export const getSortedFieldsWithParentsFirst = async (
  fieldsWithUpdate: Field[],
  db: RxDatabase<DBCollections>,
): Promise<Field[]> => {
  // When a field hasn't been synced to the remote before, there is a chance that the parent field/entries hasn't yet been set up correctly.
  // To mitigate this, we will include the parent fields for the fields whose parent haven't been synced yet.
  // If the parent field was synced before, this shouldn't be necessary, so skip it.
  const nestedFields = fieldsWithUpdate.filter((field) => !isNil(field.parentId));
  const parentFieldIds = nestedFields.map((field) => field.parentId);
  if (isEmpty(parentFieldIds)) {
    return getSortedFields(fieldsWithUpdate);
  }

  // local means: not synced to the remote
  const localParents = await db.unsyncedparentfields.find().where("id").in(parentFieldIds).exec();
  const localParentIds = localParents.map((field) => field.id as UniqueFieldId);
  const localFieldsWithoutParent = fieldsWithUpdate.filter((field) =>
    localParentIds.includes(field.parentId as UniqueFieldId),
  );

  // Filter out local fields that have a parent who is synced
  const localFields = fieldsWithUpdate.filter((field) => !localParentIds.includes(field.parentId as UniqueFieldId));

  // Filter out orphaned local fields
  const localFieldsWithParents = await getValidFieldsWithParents(localFieldsWithoutParent, db);

  // Combine and sort
  const fields = [...localFields, ...localFieldsWithParents];
  const uniqueFields = deduplicateFields(fields);
  return getSortedFields(uniqueFields);
};

export const removeOrphanedFields = (fields: Field[]): Field[] => {
  const subformFields = fields.filter((field) => field.entries?.length > 0);
  const allEntryIds = subformFields.flatMap((field) => field.entries.map((entry) => entry.id));
  const orphanFieldIds = fields
    .filter((field) => field.entryId && !allEntryIds.includes(field.entryId))
    .map((field) => field.id);

  if (!isEmpty(orphanFieldIds)) {
    // Recursively removing orphans from the updated set of fields
    return removeOrphanedFields(fields.filter((field) => !orphanFieldIds.includes(field.id)));
  }

  return fields;
};

export const getValidFieldsWithParents = async (fields: Field[], db: RxDatabase<DBCollections>): Promise<Field[]> => {
  const fieldParentIds = getParentFieldIds(fields);
  const fieldParents = await getParentFields(db, fieldParentIds);
  const allParents = await getRecursiveParents(db, fieldParents);
  const allParentsMap = new Map(allParents.map((field) => [field.id, field]));

  const updateFields: Field[] = [];
  fields.forEach((field) => updateFields.push(...getFieldWithParents(field, allParentsMap)));

  return updateFields;
};

const getParentFields = async (db: RxDatabase<DBCollections>, parentIds: string[]): Promise<RxDocumentData<Field>[]> =>
  [...(await db.fields.findByIds(parentIds).exec()).values()].map((field) => field._data);

const getParentFieldIds = (fields: Field[]): string[] => fields.map((x) => x.parentId).filter((x) => x) as string[];

const getRecursiveParents = async (
  db: RxDatabase<DBCollections>,
  parentFields: RxDocumentData<Field>[],
): Promise<RxDocumentData<Field>[]> => {
  const parentIds = getParentFieldIds(parentFields);
  const newParents = await getParentFields(db, parentIds);
  if (!isEmpty(newParents)) {
    return [...(await getRecursiveParents(db, newParents)), ...parentFields];
  }
  return parentFields;
};

export const deduplicateFields = (fields: Field[]): Field[] =>
  fields.reduce((prev, curr) => {
    if (prev.some((i) => i.id === curr.id)) {
      return prev;
    }
    return [...prev, curr];
  }, [] as Field[]);

export const getFieldsForSubmissionIds = (fields: Field[], submissionIds: string[]): Field[] =>
  fields.filter((field) => submissionIds.includes(field.submissionId));

const getFieldWithParents = (field: Field, parentMap: Map<string, Field>): Field[] => {
  if (field.parentId) {
    const parent = parentMap.get(field.parentId);
    if (parent?.entries.find((entry) => entry.id === field.entryId)) {
      const parents = getFieldWithParents(parent, parentMap);
      // Only return the entire chain if all parents in the chain are valid
      if (!isEmpty(parents)) {
        return [...parents, field];
      }
    }
    // If the parent is not found or the entry is not in the parent, return an empty array
    return [];
  }
  // If there is no parentId, return an array with only the current field, we're at the root
  return [field];
};

export const scrubHasuraFields = (fields: HasuraField[]): HasuraField[] =>
  fields.map((field) => ({
    ...field,
    data: undefined,
    meta: { ...field.meta, deviceId: undefined, evaluatedRules: undefined },
  }));

export const isFieldsComplete = (
  fields: Field[],
  formVersion: FormVersion,
  submissionId: string,
): { isComplete: boolean; missingFields: FormField<WidgetProperties>[] } => {
  const subForms = findFormVersions(formVersion) as SubForm[];
  const duplicateSubFormIds = findDuplicates(subForms.map((subForm) => subForm.uid));

  if (!isEmpty(duplicateSubFormIds)) {
    logger.error("Found duplicate FormVersionIds, so skip completion check as this is unreliable", {
      extra: { duplicateSubFormIds, submissionId, formVersionId: formVersion.id },
    });
  }

  return isFieldsCompleteRecursive(
    fields,
    formVersion.fields.filter(hasKnownWidget),
    formVersion.fieldProperties,
    duplicateSubFormIds,
  );
};

const isFieldsCompleteRecursive = (
  fields: Field[],
  expected: FormField<any>[],
  fieldProperties: FieldProperties,
  duplicateSubFormIds: string[] = [],
  noOfEntries = 1,
  missingFields: FormField<WidgetProperties>[] = [],
): { isComplete: boolean; missingFields: FormField<WidgetProperties>[] } => {
  const rootFieldsComplete = expected
    .map((field) => {
      const fieldComplete = fields.filter((i) => field.uid === i.formFieldId).length >= noOfEntries;
      if (!fieldComplete) {
        missingFields.push(field);
      }
      return fieldComplete;
    })
    .every((complete) => complete);

  const subformFieldsComplete = expected
    .filter((field) => field.properties.form || field.properties.target_form_id || field.properties.pins)
    .map((field) => {
      if (field.properties.pins) {
        // pin-field can have multiple subforms
        const pinForms = getPinFormVersions(field, fieldProperties);

        return pinForms.every((pinForm) => {
          if (duplicateSubFormIds.includes(pinForm.uid)) {
            // Historically, you could "corrupt" the FormVersion by switching from "linked" to "unlinked" (embedded), which wouldn't re-create unique ids
            // TODO DEV-6431: Remove this hack when all FormVersions are sanitized and the following error no longer occurs
            logger.error("Skip completion-check for PinForm, as it points to duplicated FormVersionId", {
              extra: { fieldUid: field.uid, pinFormUid: pinForm.uid },
            });
            return true;
          }

          const entries = findEntriesForField(field, fields).filter((e) => e.meta.scope?.target === pinForm.uid).length;
          return entries === 0
            ? true
            : isFieldsCompleteRecursive(
                fields,
                pinForm.fields.filter(hasKnownWidget),
                fieldProperties,
                duplicateSubFormIds,
                entries,
                missingFields,
              ).isComplete;
        });
      } else {
        // subform-field only has 1 specific subform
        const subForm = getSubformVersion(field, fieldProperties);

        if (duplicateSubFormIds.includes(subForm.uid)) {
          // Historically, you could "corrupt" the FormVersion by switching from "linked" to "unlinked" (embedded), which wouldn't re-create unique ids
          // TODO DEV-6431: Remove this hack when all FormVersions are sanitized and the following error no longer occurs
          logger.error("Skip completion-check for SubForm, as it points to duplicated FormVersionId", {
            extra: { fieldUid: field.uid, subFormUid: subForm.uid },
          });
          return true;
        }

        const entries = findEntriesForField(field, fields).length;
        return entries === 0
          ? true
          : isFieldsCompleteRecursive(
              fields,
              subForm.fields.filter(hasKnownWidget),
              fieldProperties,
              duplicateSubFormIds,
              entries,
              missingFields,
            ).isComplete;
      }
    })
    .every((complete) => complete);

  return { isComplete: rootFieldsComplete && subformFieldsComplete, missingFields };
};

const findEntriesForField = (formField: FormField<WidgetProperties>, fields: Field[]): Entry[] =>
  fields.find((i) => formField.uid === i.formFieldId)?.entries?.filter((e) => !e.deleted) ?? [];
