import type { ChangeEvent, FocusEvent } from "react";
import { isFinite, isNil } from "lodash-es";
import { stripNonNumeric, stripNonNumericWithSeparators } from "./stringUtil";
import { delimiter, thousandSeparator } from "./formatter";

export const isNumber = (value: number | undefined | null): boolean => !isNil(value) && !Number.isNaN(value);

export const getSafeNumber = (input: string): number | undefined => {
  const value = stripNonNumeric(input);
  return value !== "" ? Number(value) : undefined;
};

/**
 * @description Get value based on the term/widget input
 * @returns
 * - NaN - The term/widget has value, but un-parsable to a number
 * - Undefined - The term/widget has no value
 * - Number - the parsed string to a number
 */
export const getSafeNumberCalculation = (input: string): number | undefined => {
  const value = stripNonNumeric(input);

  if (!Number.isNaN(value)) {
    return value !== "" ? Number(value) : NaN;
  }
  return undefined;
};

export const getFormValue = (e: FocusEvent<any> | ChangeEvent<number>): number | undefined => {
  const number = Number(stripNonNumericWithSeparators(e.target.value, delimiter, thousandSeparator));

  const shouldSet = isFinite(number) && e.target.value !== "";
  return shouldSet ? number : undefined;
};
