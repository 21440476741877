import type { FC, MutableRefObject } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import type { RxDocument } from "rxdb";
import type { Submission } from "../types/Submission";
import SubmissionDetail from "./SubmissionDetail";
import useFileHandler from "../hooks/useFileHandler";
import { Modal } from "../storybook/components/Modal/Modal";
import { Drawer } from "../storybook/components/Drawer/Drawer";

type SubmissionDetailDrawerProps = {
  submission: RxDocument<Submission>;
  translationPrefix: "DRAFT" | "TASK";
  onClose: () => void;
  showUnreadButton?: boolean;
  open: boolean;
};

const SubmissionDetailDrawer: FC<SubmissionDetailDrawerProps> = ({
  submission,
  translationPrefix,
  onClose,
  showUnreadButton,
  open,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { removeLocalFiles } = useFileHandler();

  return (
    <>
      <Modal
        title={t("SUBMISSION_DELETE_MODAL_TITLE")}
        content={{
          kind: "message",
          message: t("SUBMISSION_DELETE_MODAL_DESCRIPTION"),
        }}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        buttons={[
          {
            label: t("CANCEL"),
            onClick: () => setShowDeleteModal(false),
          },
          {
            label: t("DELETE"),
            variant: "destructive",
            onClick: async (): Promise<void> => {
              await submission.remove();
              await removeLocalFiles(submission.id);
              setShowDeleteModal(false);
              onClose();
            },
          },
        ]}
      />
      <Drawer
        open={open}
        header={{
          kind: "extended",
          title: submission?.form.name ?? "",
          leftButton: {
            kind: "icon",
            icon: "DotsHorizontalIcon",
            dropDownMenu: {
              items: [
                ...(showUnreadButton
                  ? [
                      {
                        label: t(`MARK_UNREAD_BUTTON`),
                        onClick: async (): Promise<void> => {
                          await submission?.incrementalPatch({ viewedAt: undefined });
                          onClose();
                        },
                      },
                    ]
                  : []),
                {
                  label: t(`${translationPrefix}_DELETE_BUTTON`),
                  onClick: () => setShowDeleteModal(true),
                  type: "destructive",
                },
              ],
            },
          },
          rightButton: { kind: "icon", icon: "XIcon", onClick: onClose },
        }}
        initialFocus={initialFocus}
        onClose={() => showDeleteModal || onClose()}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("OPEN_FORM"),
            onClick: () => navigate(`/submissions/${submission?.id}`),
          },
        }}
        contentPadding={false}
      >
        {submission && <SubmissionDetail submission={submission} />}
      </Drawer>
    </>
  );
};

export default SubmissionDetailDrawer;
