import { StrictMode } from "react";
import "./index.css";
import { QueryClient } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import App from "./App";
import "./i18n";
import { BarcodeScannerProvider } from "./context/BarcodeScannerContext";
import { ToastsProvider } from "./context/ToastsContext";
import { AuthProvider } from "./context/AuthContext";
import Compose from "./components/Compose";
import { MoreAppClientProvider } from "./context/MoreAppContext";
import "./sentry";
import { DatabaseProvider } from "./context/DatabaseContext";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import { DeviceInfoProvider } from "./context/DeviceInfoContext";
import { DrawerProvider } from "./context/DrawerContext";
import { PermissionsProvider } from "./context/PermissionsContext";
import { DeviceOrientationProvider } from "./context/DeviceOrientationContext";
import { WorkerProvider } from "./context/WorkerContext";
import { POST_HOG_API_HOST, POST_HOG_TOKEN, POST_HOG_UI_HOST, PROD_ENV } from "./constants";
import { PostHogProvider } from "posthog-js/react";
import type { PostHogConfig } from "posthog-js";
import branding from "./utils/brandingUtil";
import { removeSensitiveParams } from "./utils/posthogUtil";

defineCustomElements(window);
const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!);
const POST_HOG_OPTIONS: Partial<PostHogConfig> = {
  api_host: POST_HOG_API_HOST,
  ui_host: POST_HOG_UI_HOST,
  autocapture: false,
  loaded: (ph) => {
    ph.setPersonProperties({
      environment: import.meta.env.MODE,
      is_branding: branding.isBranded(),
      branding: branding.key,
    });

    // Disable all event capturing and session recording when developing locally
    if (!PROD_ENV) {
      ph.opt_out_capturing(); // opts a user out of event capture
      ph.set_config({ disable_session_recording: true });
    }
  },
  before_send: removeSensitiveParams,
};

root.render(
  <PostHogProvider apiKey={POST_HOG_TOKEN} options={POST_HOG_OPTIONS}>
    <Compose
      components={[
        ToastsProvider,
        AuthProvider,
        OnlineStatusProvider,
        DatabaseProvider,
        MoreAppClientProvider,
        BarcodeScannerProvider,
        DeviceInfoProvider,
        DrawerProvider,
        PermissionsProvider,
        DeviceOrientationProvider,
        WorkerProvider,
        StrictMode,
      ]}
    >
      <App queryClient={queryClient} />
    </Compose>
  </PostHogProvider>,
);
