import * as Sentry from "@sentry/react";
import type { CaptureContext } from "@sentry/types";
import { LOG_COLLECTOR_URL } from "../constants";

const logger = {
  log: console.log,
  error: (message: string, error?: unknown, captureContext?: CaptureContext): void => {
    console.error(message, error);
    Sentry.withScope((scope) => {
      // All errors with the same message should be grouped together under one issue in Sentry
      // https://docs.sentry.io/platforms/javascript/guides/capacitor/enriching-events/fingerprinting/#group-errors-more-aggressively
      scope.setFingerprint([message]);
      Sentry.captureException(new Error(message, { cause: error }), captureContext);
    });
  },
  debug: console.debug,
  warn: console.warn,
  dir: console.dir,
  sendToLogCollector: (message: string, data: Record<string, unknown>): void => {
    const body = {
      severity: "INFO",
      source: "app",
      message,
      data,
    };

    logger.log(message, data); // Also log in the browser, so it is added to the Sentry context
    fetch(`${LOG_COLLECTOR_URL}/log`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).catch((err) => {
      logger.error("Failed to send log to log collector", err);
    });
  },
};

export default logger;
