import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import type { DataSource } from "../types/Datasource";
import { DataSourceNoLocalFallbackError, DataSourceNotFoundError } from "../types/Datasource";
import { fetchDataSource } from "../utils/datasourceUtil";
import { hours, minutes } from "../utils/timeUtil";
import useDataSourceCollection from "./useDataSourceCollection";

const MAX_RETRIES = 3;

const useDatasource = (id: string, disableRetry = false): UseQueryResult<DataSource, Error> => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const datasourceCollection = useDataSourceCollection();

  const query = useQuery<DataSource, Error, DataSource>({
    queryKey: ["datasources", customerId, id],
    queryFn: () => fetchDataSource(username!, customerId!, id, client!, datasourceCollection!),
    enabled: !!customerId && !!id && !!datasourceCollection && !!username,
    gcTime: minutes(15),
    staleTime: hours(1),
    retry: (failureCount, error) => {
      if (error instanceof DataSourceNoLocalFallbackError) {
        return false;
      }
      if (error instanceof DataSourceNotFoundError) {
        return false;
      }
      return failureCount < MAX_RETRIES;
    },
    ...(disableRetry && { retry: false }),
    networkMode: "always",
  });

  return query;
};

export default useDatasource;
