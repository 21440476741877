import { totalFieldsRequest, totalSubmissionRequest } from "./syncStatusUtil";
import type { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";

export type Collection = "fields" | "submissions";

export type Progress = {
  total: number;
  lastTotal: number;
  current: number;
};

type PullState = {
  progress: Progress;
  countIsCalled: boolean;
  isPulling: boolean;
};

type PushState = {
  isPushing: boolean;
};

type SyncCollectionState = {
  pullState: PullState;
  pushState: PushState;
};

export class SyncStatus {
  public fields: SyncCollectionState;
  public submissions: SyncCollectionState;

  constructor() {
    this.fields = this.getDefaultCollectionState();
    this.submissions = this.getDefaultCollectionState();
  }

  private getDefaultCollectionState(): SyncCollectionState {
    return {
      pullState: {
        progress: { total: 0, current: 0, lastTotal: 0 },
        countIsCalled: false,
        isPulling: false,
      },
      pushState: {
        isPushing: false,
      },
    };
  }

  addFieldCount(count: number): void {
    this.fields.pullState.progress.current += count;
  }

  addSubmissionCount(count: number): void {
    this.submissions.pullState.progress.current += count;
  }

  reset(): void {
    this.fields = this.getDefaultCollectionState();
    this.submissions = this.getDefaultCollectionState();
  }

  resetPull(collection: Collection): void {
    if (collection === "fields") {
      this.fields.pullState = { ...this.getDefaultCollectionState().pullState };
    }
    if (collection === "submissions") {
      this.submissions.pullState = { ...this.getDefaultCollectionState().pullState };
    }
  }

  isPulling(): boolean {
    return this.submissions.pullState.isPulling || this.fields.pullState.isPulling;
  }

  isPushing(): boolean {
    return this.submissions.pushState.isPushing || this.fields.pushState.isPushing;
  }

  totalPullPercent(): number {
    if (this.submissions.pullState.progress.total + this.fields.pullState.progress.total === 0) {
      return 100; // When both totals are 0, there is no data to pull, which means everything has been pulled
    }
    const roundedPullPercentage = Math.round(
      ((this.submissions.pullState.progress.current + this.fields.pullState.progress.current) /
        (this.submissions.pullState.progress.total + this.fields.pullState.progress.total)) *
        100,
    );
    // This prevents the visual % from going over 99
    if (roundedPullPercentage > 99) {
      return 99;
    }
    return roundedPullPercentage;
  }

  async setTotals(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    await this.setSubmissionsTotal(authHeader, seq);
    await this.setFieldsTotal(authHeader, seq);
  }

  async setFieldsTotal(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    if (this.fields.pullState.progress.total === 0) {
      const { count } = (await totalFieldsRequest(authHeader, seq)).app_submission_fields_aggregate.aggregate;
      this.fields.pullState.progress = { ...this.fields.pullState.progress, total: count, lastTotal: count };
    }
  }

  async setSubmissionsTotal(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    if (this.submissions.pullState.progress.total === 0) {
      const { count } = (await totalSubmissionRequest(authHeader, seq)).app_submissions_aggregate.aggregate;
      this.submissions.pullState.progress = { ...this.submissions.pullState.progress, total: count, lastTotal: count };
    }
  }
}
