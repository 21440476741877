/* eslint-disable no-param-reassign */
import type { AxiosInstance } from "axios";
import PouchDB from "pouchdb-browser";
import { Capacitor } from "@capacitor/core";
import { Directory } from "@capacitor/filesystem";
import SQLitePlugin from "pouchdb-adapter-cordova-sqlite";
import { validate } from "uuid";
import { isEqual } from "lodash-es";
import logger from "../../../utils/logger";
import { legacySubmissionId } from "../../../utils/stringUtil";
import type LegacySubmission from "../../../types/LegacySubmission";
import type Migration from "../../../types/Migration";
import { getUri } from "../../../utils/fileSystemUtil";
import { uriToBlob } from "../../../utils/fileUtil";

export const MIGRATION_VERSION = 1;

type LegacySubmissionWrapper = {
  key: string;
  draft?: LegacySubmission;
  registration?: LegacySubmission;
};

type SubmissionFileAnswer = {
  key: string;
  value: {
    value: string;
  };
  parent: any;
};

type DatasourceEntryAnswer = {
  key: string;
  value: {
    dataSourceId: string;
    dataSourceEntryId: string;
    value: any;
  };
  parent: any;
};

type MigrateSubmissionParams = {
  doc?: PouchDB.Core.ExistingDocument<LegacySubmissionWrapper & PouchDB.Core.AllDocsMeta>;
  id: string;
  key: string;
  value: { rev: string; deleted?: boolean };
};

type SendSubmissionParams = {
  doc?: PouchDB.Core.ExistingDocument<LegacySubmission & PouchDB.Core.AllDocsMeta>;
  id: string;
  key: string;
  value: { rev: string; deleted?: boolean };
};

type Document<T> = {
  doc?: PouchDB.Core.ExistingDocument<T & PouchDB.Core.AllDocsMeta> | undefined;
  id: string;
  key: string;
  value: {
    rev: string;
    deleted?: boolean | undefined;
  };
};

const openDbs = new Map<string, PouchDB.Database>();

export const migrate = async (
  username: string,
  client: AxiosInstance,
  ids?: string[],
  onMigrationProgress?: (percentage: number) => void,
): Promise<(string | undefined)[]> => {
  PouchDB.plugin(SQLitePlugin);
  logger.log(`Starting migration for ${username}`);
  const drafts = getDatabase("drafts", username);
  const saved = getDatabase("registrations", username);
  const sent = getDatabase("queuedRegistrations", username);
  let fulfilled = 0;
  const process = (): void => {
    fulfilled += 1;
    const percentage = (fulfilled / allMigrations.length) * 100;
    onMigrationProgress && onMigrationProgress(percentage);
  };

  const allMigrations = [
    ...(await migrateDocs(client, username, drafts, ids)),
    ...(await migrateDocs(client, username, saved, ids)),
    ...(await migrateSent(client, username, sent, ids)),
  ].map(async (migration) =>
    migration.then((i) => {
      process();
      return i;
    }, process),
  );

  return (await Promise.allSettled(allMigrations))
    .filter((i) => i.status === "fulfilled" && i.value)
    .map((i) => (i as PromiseFulfilledResult<LegacySubmission>).value?.meta.oldGuid);
};

const migrateDocs = async (
  client: AxiosInstance,
  username: string,
  db: PouchDB.Database,
  ids?: string[],
): Promise<Promise<LegacySubmission | undefined>[]> =>
  (await getDocs<LegacySubmissionWrapper>(db))
    .filter((doc) => {
      const submission = doc.doc?.draft || doc.doc?.registration;
      return ids ? ids.includes(submission!.meta.guid) : true;
    })
    .map(async (doc) => migrateSubmission(doc, client, db, username));

const migrateSent = async (
  client: AxiosInstance,
  username: string,
  db: PouchDB.Database,
  ids?: string[],
): Promise<Promise<LegacySubmission | undefined>[]> =>
  (await getDocs<LegacySubmission>(db))
    .filter((doc: SendSubmissionParams) => (ids ? ids.includes((doc.doc as LegacySubmission).meta.guid) : true))
    .map(async (doc: SendSubmissionParams) => sendSubmission(doc, client, db, username));

const getDocs = async <T>(db: PouchDB.Database): Promise<Document<T>[]> => {
  const allDocs = await db.allDocs<T>({ include_docs: true });
  return allDocs.rows.filter((value: any) => value !== null && value !== undefined);
};

const migrateSubmission = async (
  doc: MigrateSubmissionParams,
  client: AxiosInstance,
  db: PouchDB.Database<object>,
  username: string,
): Promise<LegacySubmission | undefined> => {
  const registration = await toMigrate(doc);
  if (!registration) {
    return undefined;
  }

  // Didn't touch instruction yet
  const emptyInstruction = !!registration.instruction && isEqual(registration.instruction.data, registration.data);

  const { customerId } = registration;
  const submissionId = validate(registration.meta.guid)
    ? registration.meta.guid
    : legacySubmissionId(registration.meta.guid);
  registration.meta.oldGuid = registration.meta.guid;
  registration.meta.guid = submissionId;
  let docId = doc.id;

  await postProcessDatasources(registration, customerId, client);

  // Webclient uploads straight away
  if (Capacitor.isNativePlatform()) {
    await postProcessFiles(registration, customerId, submissionId, username, client);

    // Persist uploaded files
    doc = await db.get(docId);
    docId = (await db.put(buildNewDoc(doc, registration))).id;
  }

  // Migrate to Submission or submit like old App
  await client.post(
    `/api/v2/customers/${customerId}/submissions/migrate?empty=${emptyInstruction}`,
    buildSendObject(registration),
  );

  registration.meta.migrated = MIGRATION_VERSION;
  doc = await db.get(docId);
  await db.put(buildNewDoc(doc, registration));
  return registration;
};

const toMigrate = (doc: MigrateSubmissionParams): LegacySubmission | undefined => {
  if (!doc.doc || doc.value.deleted) {
    return undefined;
  }
  const registration = doc.doc.draft ?? doc.doc.registration;

  if (!registration) {
    logger.log(" - Didn't find document in Wrapper");
    return undefined;
  }

  if (registration.meta.migrated && registration.meta.migrated >= MIGRATION_VERSION) {
    logger.log(" - Skipping - Already migrated");
    return undefined;
  }
  return registration;
};

const toSend = (
  doc: SendSubmissionParams,
): PouchDB.Core.ExistingDocument<LegacySubmission & PouchDB.Core.AllDocsMeta> | undefined => {
  if (!doc.doc || doc.value.deleted) {
    return undefined;
  }
  const registration = doc.doc;
  if (!registration) {
    logger.log(" - Didn't find document");
    return undefined;
  }

  if (registration.meta.migrated && registration.meta.migrated >= MIGRATION_VERSION) {
    logger.log(" - Skipping - Already sent");
    return undefined;
  }
  return registration;
};

export const getToBeMigrated = async (username: string): Promise<Migration[]> => {
  PouchDB.plugin(SQLitePlugin);
  const draftsDb = getDatabase("drafts", username);
  const savedDb = getDatabase("registrations", username);
  const sentDb = getDatabase("queuedRegistrations", username);
  const drafts = (await getDocs<LegacySubmissionWrapper>(draftsDb))
    .map((doc: MigrateSubmissionParams) => toMigrate(doc))
    .map((doc) => ({ migrationType: "draft", submission: doc }) as Migration);
  const saved = (await getDocs<LegacySubmissionWrapper>(savedDb))
    .map((doc: MigrateSubmissionParams) => toMigrate(doc))
    .map((doc) => ({ migrationType: "saved", submission: doc }) as Migration);
  const sent = (await getDocs<LegacySubmission>(sentDb))
    .map((doc: SendSubmissionParams) => toSend(doc))
    .map((doc) => ({ migrationType: "sent", submission: doc }) as Migration);

  return [...drafts, ...saved, ...sent].filter((migration) => !!migration.submission);
};

const sendSubmission = async (
  doc: SendSubmissionParams,
  client: AxiosInstance,
  db: PouchDB.Database<object>,
  username: string,
): Promise<LegacySubmission | undefined> => {
  const registration = await toSend(doc);
  if (!registration) {
    return undefined;
  }
  const { customerId } = registration;
  const submissionId = validate(registration.meta.guid)
    ? registration.meta.guid
    : legacySubmissionId(registration.meta.guid);
  registration.meta.oldGuid = registration.meta.guid;
  registration.meta.guid = submissionId;

  await postProcessDatasources(registration, customerId, client);

  // Webclient uploads straight away
  if (Capacitor.isNativePlatform()) {
    await postProcessFiles(registration, customerId, submissionId, username, client);

    // Persist uploaded files
    doc = await db.get(doc.id);
    await db.put({
      ...registration,
      key: doc.key,
    });
  }

  // Submit like old App
  await client.post(`/api/v1.0/client/forms/upload`, buildSendObject(registration));

  registration.meta.migrated = MIGRATION_VERSION;
  doc = await db.get(doc.id);
  await db.put({
    ...registration,
    key: doc.key,
  });
  return registration;
};

const uploadFile = async (
  file: SubmissionFileAnswer,
  customerId: number,
  submissionId: string,
  userName: string,
  client: AxiosInstance,
): Promise<void> => {
  const prefix = Capacitor.getPlatform() === "ios" ? "/NoCloud" : "";
  const path = `${prefix}/registrationFiles/${userName}/${file.value.value}`;
  let fileResult: string;
  try {
    const result = await getUri({ directory: Directory.Library, path });
    fileResult = Capacitor.convertFileSrc(result.uri);
  } catch (e) {
    file.parent[file.key] = "Missing photo";
    logger.error("Couldn't find photo for path", e, { extra: { path } });
    return;
  }

  try {
    const blob = await uriToBlob(fileResult);

    const data = new FormData();
    data.append("file", blob, file.value.value);
    data.append("customerNumber", `${customerId}`);
    data.append("hasuraSubmissionUuid", submissionId);
    const response = await client.post("/api/v1.0/client/registrations/files", data, {
      headers: { "content-type": "multipart/form-data" },
    });
    file.parent[file.key] = response.data;
  } catch (e: any) {
    logger.error("Couldn't upload file", e);
    throw new Error("Couldn't complete file upload process", e);
  }
};

const processDatasourceEntry = async (
  entry: DatasourceEntryAnswer,
  customerId: number,
  client: AxiosInstance,
): Promise<void> => {
  const { dataSourceId } = entry.value;
  const { dataSourceEntryId } = entry.value;

  try {
    const response = await client.get(
      `/api/v1.0/client/customers/${customerId}/datasources/${dataSourceId}/entries/${dataSourceEntryId}`,
    );
    entry.parent[entry.key] = response.data;
  } catch (e) {
    logger.warn(
      `Couldn't retrieve datasource entry ${dataSourceEntryId} for datasource ${dataSourceId}, falling back to available data`,
      e,
    );
    // Use indexed data only as we couldn't get the complete entry
    entry.value.value.id = dataSourceEntryId;
    entry.parent[entry.key] = entry.value.value;
  }
};

const getDatabase = (prefix: string, username: string): PouchDB.Database => {
  const dbName = `${prefix}.${username}`;
  if (openDbs.has(dbName)) {
    return openDbs.get(dbName)!;
  }
  let pouchDb: PouchDB.Database;
  // @ts-expect-error pouchdb does not have types for cordova-sqlite
  if (window.sqlitePlugin) {
    pouchDb = new PouchDB(dbName, {
      auto_compaction: true,
      adapter: "cordova-sqlite",
      location: "default",
    });
  } else {
    pouchDb = new PouchDB(dbName, { auto_compaction: true });
  }
  openDbs.set(dbName, pouchDb);
  return pouchDb;
};

const postProcessFiles = async (
  registration: any,
  customerId: number,
  submissionId: string,
  username: string,
  client: AxiosInstance,
): Promise<void> => {
  await Promise.all(
    findFiles(registration).map(async (file) => uploadFile(file, customerId, submissionId, username, client)),
  );
};

const postProcessDatasources = async (registration: any, customerId: number, client: AxiosInstance): Promise<void> => {
  await Promise.all(
    findDatasourceEntries(registration).map(async (entry) => processDatasourceEntry(entry, customerId, client)),
  );
};

const findFiles = (submission: LegacySubmission): SubmissionFileAnswer[] => {
  const answer: SubmissionFileAnswer[] = [];
  findValuesWithTypeInObject("file", submission.data, answer);
  return answer;
};

const findDatasourceEntries = (submission: LegacySubmission): DatasourceEntryAnswer[] => {
  const answer: DatasourceEntryAnswer[] = [];
  findValuesWithTypeInObject("dataSourceEntry", submission.data, answer);
  return answer;
};

const findValuesInArray = (type: string, data: any, answer: any): void => {
  for (const element of data) {
    findValuesWithTypeInObject(type, element, answer);
  }
};

const findValuesWithTypeInObject = (type: string, data: Record<string, any>, answer: SubmissionFileAnswer[]): void => {
  const keys = Object.keys(data);
  keys.forEach((key) => {
    const dataChild = data[key];
    if (Array.isArray(dataChild)) {
      findValuesInArray(type, dataChild, answer);
    } else if (dataChild instanceof Object && dataChild._type === type && dataChild.value != null) {
      answer.push({ value: dataChild, parent: data, key });
    } else if (dataChild instanceof Object) {
      findValuesWithTypeInObject(type, dataChild, answer);
    }
  });
};

const buildSendObject = (registration: LegacySubmission): Omit<LegacySubmission, "formIcon" | "instruction"> => ({
  customerId: registration.customerId,
  meta: {
    ...registration.meta,
    instructionId: registration.instruction ? registration.instruction.instructionId : undefined,
  },
  data: registration.data,
  formId: registration.formId,
  formName: registration.formName,
  applicationArtifactVersion: registration.applicationArtifactVersion,
});

const buildNewDoc = (doc: any, registration: LegacySubmission): any => {
  const newDoc: any = {
    _id: doc._id,
    _rev: doc._rev,
    key: doc.key,
  };
  if (doc.draft) {
    newDoc.draft = registration;
  }

  if (doc.registration) {
    newDoc.registration = registration;
  }
  return newDoc;
};

export const destroyPouchDatabase = async (db: PouchDB.Database): Promise<void> => {
  await db.destroy();
  openDbs.delete(db.name);
};
