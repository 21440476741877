import type { WithDeleted } from "rxdb";
import type { SubformEntry } from "../components/widgets/WidgetSubform";
import type { RuleResult } from "./Rules";
import { decompress } from "../utils/compressUtil";
import type { WidgetProperties } from "./FormVersion";
import type { FieldState, UniqueFieldId } from "./SubmissionState";
import type { SubmissionFormData } from "../components/Form";
import { getWidgetProperties } from "../utils/formUtil";
import { isNil } from "lodash-es";

export type FormFieldId = string;
export type UploadStatus =
  | "uploading"
  | "failed"
  | "uploaded"
  | "aborted"
  | "error"
  | "size_exceeded"
  | "invalid_extension";
export const CLEARABLE_UPLOAD_STATUSES: UploadStatus[] = ["uploaded", "size_exceeded", "error", "invalid_extension"];
export const RETRYABLE_UPLOAD_STATUSES: UploadStatus[] = ["failed", "aborted", "error"];

export type RemoteField = {
  id: UniqueFieldId;
  submissionId: string;
  meta: {
    type: WidgetDataTypes;
    formFieldId: string;
    widget: string;
    dataName: string;
    deviceId?: string;
    error?: string;
    hidden?: boolean;
    compressed: boolean;
    evaluatedRules: RuleResult[];
    order: number;
    updatedBy?: string;
    uploadStatus?: UploadStatus;
  };
  data?: unknown;
  entry?: {
    id?: string;
    fieldId?: UniqueFieldId;
  };
  entryId?: string;
  entries: Entry[];
  updatedAt: string;
  _deleted: boolean;
  status: "draft" | "final";
};

export type Field = {
  id: UniqueFieldId;
  submissionId: string;
  data?: unknown;
  deviceId?: string;
  error?: string;
  updatedAt: string;
  formFieldId: string;
  dataName?: string;
  widget: string;
  type: WidgetDataTypes;
  status: "draft" | "final";
  entryId?: string;
  parentId?: UniqueFieldId;
  entries: Entry[];
  hidden: boolean;
  compressed: boolean;
  evaluatedRules: RuleResult[];
  order: number;
  updatedBy?: string;
  uploadStatus?: UploadStatus;
  _deleted: boolean;
};

export type HasuraField = {
  id: string;
  submissionId: string;
  status: string;
  data: unknown;
  meta: {
    type: WidgetDataTypes;
    formFieldId: string;
    widget: string;
    dataName?: string;
    deviceId?: string;
    error?: string;
    hidden?: boolean;
    compressed: boolean;
    evaluatedRules?: RuleResult[];
    order: number;
    updatedBy?: string;
    uploadStatus?: UploadStatus;
  };
  entries?: {
    data: unknown[];
    on_conflict: {
      update_columns: string[];
      constraint: string;
    };
  };
  entryId?: string;
  deleted: boolean;
};

// Legacy: remove with DEV-6207
export type FieldMeta = {
  id: string;
  submissionId: string;
  remote: boolean;
};

export type UnsyncedParentField = {
  id: string; // field id
  submissionId: string;
};

export type RememberedField = {
  id: string;
  formId: string;
  widget: string;
  type: WidgetDataTypes;
  data?: unknown;
  updatedAt?: string;
  dataName?: string;
};

export type RememberedSearchQuery = {
  id: string;
  formId: string;
  query?: string;
};

export type WidgetDataTypes =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "array"
  | "file"
  | "date"
  | "datetime"
  | "time"
  | "none"
  | "location"
  | "duration"
  | "currency";

export type Entry = {
  id: string;
  submissionId: string;
  meta: Record<string, any>;
  deleted: boolean;
};

export type WidgetResult<R> = {
  type: WidgetDataTypes;
  rawValue?: R;
  entries?: SubformEntry<any>[];
  formattedValue?: string;
  updatedAt?: string;
  updatedBy?: string;
  meta: WidgetResultMeta;
};
export type WidgetResultMeta = {
  widget: string;
  submissionId?: string; // The id of the submission this is linked to
  formFieldId: string; // The id of the form field
  fieldId: UniqueFieldId; // The id of this particular instance
  entryId?: string; // The id when in a subform
  parentId?: UniqueFieldId; // The id of the parent when in a subform
  dataName?: string; // Customer facing name for this configured field
  evaluatedRules: RuleResult[];
  hidden: boolean;
  compressed: boolean;
  order: number;
  uploadStatus?: UploadStatus;
  humanEdited: boolean;
};

// This must be calculated by hasura, never local
const stripFieldId = (entry: Entry): Entry => ({ ...entry, fieldId: undefined }) as Entry;

export const fieldToWidgetResult = (field: Field): WidgetResult<unknown> => ({
  rawValue: field.compressed ? decompress(field.data as string) : field.data,
  entries: field.entries,
  meta: {
    widget: field.widget,
    submissionId: field.submissionId,
    formFieldId: field.formFieldId,
    fieldId: field.id,
    entryId: field.entryId,
    parentId: field.parentId,
    dataName: field.dataName,
    hidden: field.hidden,
    compressed: field.compressed,
    evaluatedRules: field.evaluatedRules,
    order: field.order,
    uploadStatus: field.uploadStatus,
    humanEdited: !isNil(field.updatedBy),
  },
  updatedAt: field.updatedAt,
  updatedBy: field.updatedBy,
  type: field.type,
});

export const remoteFieldToLocal = (doc: RemoteField, submissionId?: string): WithDeleted<Field> => ({
  id: doc.id,
  submissionId: submissionId ?? doc.submissionId,
  data: doc.data,
  error: doc.meta.error,
  updatedAt: doc.updatedAt,
  formFieldId: doc.meta.formFieldId,
  widget: doc.meta.widget,
  dataName: doc.meta.dataName,
  type: doc.meta.type,
  entryId: doc.entry?.id,
  parentId: doc.entry?.fieldId,
  entries: doc.entries.map(stripFieldId),
  status: doc.status,
  hidden: doc.meta.hidden ?? false,
  compressed: doc.meta.compressed ?? false,
  deviceId: doc.meta.deviceId,
  evaluatedRules: doc.meta.evaluatedRules,
  order: doc.meta.order,
  updatedBy: doc.meta.updatedBy,
  uploadStatus: doc.meta.uploadStatus,
  _deleted: doc._deleted,
});

export const fieldToRemoteHasura = (doc: WithDeleted<Field>, status?: string): HasuraField => ({
  id: doc.id,
  submissionId: doc.submissionId,
  status: status || doc.status,
  meta: {
    type: doc.type,
    formFieldId: doc.formFieldId,
    widget: doc.widget,
    dataName: doc.dataName,
    error: doc.error,
    hidden: doc.hidden,
    compressed: doc.compressed,
    deviceId: doc.deviceId,
    evaluatedRules: doc.evaluatedRules,
    order: doc.order,
    updatedBy: doc.updatedBy,
    uploadStatus: doc.uploadStatus,
  },
  data: doc.data,
  entryId: doc.entryId,
  entries: doc.entries
    ? {
        data: doc.entries.map(stripFieldId),
        on_conflict: {
          constraint: "submission_field_entries_pkey",
          update_columns: ["deleted", "meta"],
        },
      }
    : undefined,
  deleted: doc._deleted,
});

export const fieldToRemote = (doc: Field): RemoteField => ({
  id: doc.id,
  submissionId: doc.submissionId,
  meta: {
    type: doc.type,
    formFieldId: doc.formFieldId,
    widget: doc.widget,
    dataName: doc.dataName || "",
    hidden: doc.hidden,
    compressed: doc.compressed,
    evaluatedRules: doc.evaluatedRules,
    order: doc.order,
    updatedBy: doc.updatedBy,
    uploadStatus: doc.uploadStatus,
  },
  data: doc.data,
  entries: doc.entries ?? [],
  entryId: doc.entryId,
  status: doc.status,
  updatedAt: doc.updatedAt,
  _deleted: false,
});

export const submissionFormDataToFields = (formData: SubmissionFormData): Field[] =>
  Object.values(formData).map((widgetResult) => {
    const field: Field = {
      id: widgetResult.meta.fieldId,
      submissionId: widgetResult.meta.submissionId ?? "",
      data: widgetResult.rawValue,
      updatedAt: widgetResult.updatedAt ?? "",
      formFieldId: widgetResult.meta.formFieldId,
      dataName: widgetResult.meta.dataName,
      widget: widgetResult.meta.widget,
      type: widgetResult.type,
      status: "final",
      entryId: widgetResult.meta.entryId,
      parentId: widgetResult.meta.parentId,
      entries: widgetResult.entries ?? [],
      hidden: widgetResult.meta.hidden,
      compressed: widgetResult.meta.compressed ?? false,
      evaluatedRules: widgetResult.meta.evaluatedRules,
      order: widgetResult.meta.order,
      _deleted: false,
    };
    return field;
  });

// This is used in a loop (4500+ times, sometimes) so it's important to keep it as efficient as possible.
// Unwrapping it like this is a bit ugly, but it is significantly faster when done over and over.
export const fieldStateToField = ({
  uniqueFieldId,
  deviceId,
  visible,
  error,
  deleted,
  value: {
    type,
    rawValue,
    updatedAt = "",
    updatedBy = "",
    entries = [],
    meta: {
      submissionId = "",
      formFieldId,
      dataName,
      widget,
      entryId,
      parentId,
      compressed = false,
      evaluatedRules,
      order,
      uploadStatus,
    },
  },
}: FieldState<WidgetProperties, WidgetResult<unknown>>): Field => ({
  id: uniqueFieldId,
  data: rawValue,
  submissionId,
  updatedAt,
  formFieldId,
  dataName,
  widget,
  type,
  status: "draft",
  deviceId,
  entryId,
  parentId,
  entries,
  hidden: !visible,
  compressed,
  evaluatedRules,
  order,
  error,
  updatedBy,
  uploadStatus,
  _deleted: deleted,
});

export const fieldToFieldState = (
  field: Field,
  properties: WidgetProperties,
): FieldState<WidgetProperties, WidgetResult<unknown>> => ({
  uid: field.formFieldId,
  uniqueFieldId: field.id,
  deviceId: field.deviceId || "unknown",
  value: fieldToWidgetResult(field),
  visible: !field.hidden,
  deleted: field._deleted,
  error: field.error,
  widget: field.widget,
  properties: getWidgetProperties(field.widget, properties),
});
