import type { FC, ReactElement } from "react";
import { useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { IconAndTextButton } from "../IconAndTextButton/IconAndTextButton";

export type Definition = {
  term: string;
  description: string | ReactElement;
};

export type DefinitionListProps = {
  definitions: Definition[];
  cutOffLength?: number;
  showMoreLabel: string;
  showLessLabel: string;
  className?: string;
};

export const DefinitionList: FC<DefinitionListProps> = ({
  definitions,
  showMoreLabel,
  showLessLabel,
  cutOffLength,
  className,
}) => {
  const isExpandable = cutOffLength ? definitions.length > cutOffLength : false;
  const [expanded, setExpanded] = useState<boolean>(false);

  const mainClasses = classNames("rounded-lg border border-gray-100 bg-gray-50 px-4", className);
  const rowClasses = classNames("flex border-b border-gray-200 py-2 gap-x-2", {
    "last:border-none": !isExpandable,
  });
  const slicedRows = cutOffLength && !expanded ? definitions.slice(0, cutOffLength) : definitions;
  return (
    <div className={mainClasses}>
      <dl className="w-full">
        <AnimatePresence initial={false}>
          {slicedRows.map((definition, index) => (
            <motion.div
              className={rowClasses}
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.dt className="basis-2/5 overflow-hidden break-words text-gray-700">{definition.term}</motion.dt>
              <motion.dd className="basis-3/5 overflow-hidden break-words text-right text-sm text-gray-700">
                {definition.description}
              </motion.dd>
            </motion.div>
          ))}
        </AnimatePresence>
      </dl>
      {isExpandable && (
        <IconAndTextButton
          block
          iconAlign="right"
          icon={expanded ? "ChevronUpIcon" : "ChevronDownIcon"}
          variant="transparentBrand"
          onClick={() => setExpanded((prev) => !prev)}
          label={expanded ? showLessLabel : showMoreLabel}
        />
      )}
    </div>
  );
};
