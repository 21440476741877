import { userManager } from "../hooks/useAuth";
import { isNil } from "lodash-es";

export const getAuthHeader = async (): Promise<string> => {
  const user = await userManager.getUser();

  if (isNil(user)) {
    throw new Error("Unable to get user");
  }

  return `Bearer ${user.access_token}`;
};
