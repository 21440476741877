import type { SemVer } from "semver";
import { parse } from "semver";
import semver from "semver/preload";
import { AppLauncher } from "@capacitor/app-launcher";
import { getCurrentAppInfo } from "./deviceUtil";
import logger from "./logger";

export const CURRENT_COMMIT: string | undefined = process.env.VITE_COMMIT_SHA?.slice(-5);

export const getCurrentVersion = async (): Promise<SemVer | null> => {
  const appInfo = await getCurrentAppInfo();
  return parse(appInfo.version ?? process.env.VITE_VERSION);
};

export const isOutdatedVersion = async (latestVersion: SemVer | string): Promise<boolean> => {
  const currentVersion = await getCurrentVersion();
  if (currentVersion === null) {
    logger.error("Current app version is absent");
    return false;
  }

  return semver.gt(latestVersion, currentVersion);
};

export const openAppListingInStore = async (): Promise<void> => {
  const appInfo = await getCurrentAppInfo();
  if (!appInfo.id) {
    logger.debug("Tried to open app store listing without app id", appInfo);
    return;
  }

  const updateUrl =
    appInfo.platform === "android"
      ? `market://details?id=${appInfo.id}`
      : `itms-apps://itunes.apple.com/app/id${appInfo.id}`;

  await AppLauncher.openUrl({ url: updateUrl });
};
