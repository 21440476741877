import type { FC, PropsWithChildren } from "react";
import { createContext, useEffect, useState } from "react";
import type { DeviceInfo } from "../utils/deviceUtil";
import { getCurrentDevice } from "../utils/deviceUtil";
import logger from "../utils/logger";

export const DeviceInfoProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({} as DeviceInfo);

  useEffect(() => {
    const setInfo = async (): Promise<void> => {
      const info = await getCurrentDevice();
      setDeviceInfo(info);
    };
    setInfo().catch((e) => logger.error("Could not set device info", e));
  }, []);

  return <DeviceInfoContext.Provider value={deviceInfo}>{children}</DeviceInfoContext.Provider>;
};

export const DeviceInfoContext = createContext<DeviceInfo>({} as DeviceInfo);
