import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useAuth from "../hooks/useAuth";
import EmptyContent from "../components/EmptyContent";
import SubmissionListPage from "./SubmissionListPage";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";
import { Text } from "../storybook/components/Text/Text";
import { TextButton } from "../storybook/components/TextButton/TextButton";

const TasksPage: FC = () => {
  const { customerId } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToFolders = (): void => navigate("/folders");

  return (
    <SubmissionListPage
      ariaLabel={t("TASKS")}
      translationPrefix="TASK"
      type="TASKS"
      query={(collection) =>
        collection
          .find()
          .where("customerId")
          .eq(customerId)
          .where("task")
          .ne(null)
          .where("status")
          .eq("draft")
          .where("submittedAt")
          .eq(null)
          .sort({ viewedAt: "desc", createdAt: "desc" })
      }
      emptyState={
        <EmptyContent
          title={t("TASKS_EMPTY_TITLE")}
          description={t("TASKS_EMPTY_DESCRIPTION")}
          imgAlt={NON_DESCRIPTIVE_IMAGE}
          imgSrc="assets/empty.svg"
        >
          <Text className="mt-2" color="medium">
            {t("TASKS_EMPTY_BUTTON_DESCRIPTION")}
          </Text>
          <TextButton onClick={goToFolders} label={t("TASKS_EMPTY_BUTTON_LABEL")} />
        </EmptyContent>
      }
      showListHeaders
    />
  );
};

export default TasksPage;
