import type { CaptureResult } from "posthog-js";
import qs from "qs";

export const FEATURE_FLAGS = {
  SEND_DIAGNOSTICS: "is_diagnostics_send_enabled",
};

export const removeSensitiveParams = (cr: CaptureResult | null): CaptureResult | null => {
  // short if no params are detected
  if (!cr) {
    return cr;
  }

  if (cr.properties.$current_url) {
    cr.properties.$current_url = sanitizeSensitiveUrl(cr.properties.$current_url);
  }
  if (cr.properties.$initial_current_url) {
    cr.properties.$initial_current_url = sanitizeSensitiveUrl(cr.properties.$initial_current_url);
  }
  if (cr.properties.$initial_person_info?.u) {
    cr.properties.$initial_person_info.u = sanitizeSensitiveUrl(cr.properties.$initial_person_info.u);
  }

  return cr;
};

const sanitizeSensitiveUrl = (currentUrl: string): string => {
  const url = new URL(currentUrl);
  const params = url.searchParams;
  // Sensitive parameters to be hidden
  const sensitiveParams = ["access_token", "refresh_token", "token", "id_token", "code", "otl", "scope", "state"];

  if (url.pathname.includes("/login/callback") || url.pathname.includes("/logout/callback")) {
    params.forEach((_value, key) => {
      params.set(key, "{hidden}");
    });
  } else {
    // Replace each sensitive parameter's value with '{hidden}'
    sensitiveParams.forEach((param) => {
      if (params.has(param)) {
        params.set(param, "{hidden}");
      }
    });
  }

  // Combine the base URL with the modified query string
  const queryString = qs.stringify(qs.parse(params.toString()), {
    encode: false,
    addQueryPrefix: true,
    indices: false,
  });
  return `${url.origin}${url.pathname}${queryString}`;
};
